import { AxiosRequestConfig } from 'axios';

import { Floorplan } from '../../../@types/api/v1/rest/Floorplan';
import { get } from '../../next';

export const FloorplanApi = {
  /**
   * Retrieve a floorplan instance by ID.
   * @param id Unique ID of the desired floorplan on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested floorplan.
   */
  getById: (id: number, requestConfig?: AxiosRequestConfig) =>
    get<Floorplan>(`/api/v1/rest/floorplans/${id}/`, requestConfig),
};
