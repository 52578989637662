import { HStack, Icon, Image, Link, Text, VStack } from '@chakra-ui/react';
import { MouseEvent, SyntheticEvent, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { ProjectDefaultIcon } from '../../components/Icon';
import { PendoTopic } from '../../constants/analytics';
import Routes from '../../routes';
import { formatAddress } from '../../utils/stringUtils';

import classes from './ProjectInfoWithThumbnail.module.scss';

interface Address {
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface ProjectInfoWithThumbnailProps {
  projectId: number;
  projectName: string;
  projectAddress: Address;
  thumbnail?: string;
}

/**
 * Renders the project image, project name and project address.
 */
const ProjectInfoWithThumbnail = (props: ProjectInfoWithThumbnailProps) => {
  const { projectId, projectName, projectAddress, thumbnail } = props;

  const [imageSource, setImageSource] = useState<string | undefined>(() =>
    thumbnail?.toLowerCase()?.match(/\.(jpg|jpeg|png|gif|svg)$/i) ? thumbnail : undefined
  );

  const history = useHistory();

  const progressTrackingPath = generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: projectId });

  // Using an <a> tag allows users to interact normally with the link (e.g. being able to right click and open in a new
  // tab). Preventing the default behavior allows us to perform a client-side navigation.
  const goToProgressTracking = (event: MouseEvent<HTMLAnchorElement>) => {
    if (event.ctrlKey || event.metaKey) {
      return;
    }

    event.preventDefault();
    history.push(progressTrackingPath);
  };

  const onImageLoadError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.onerror = null;
    // Alternatively, we could set currentTarget.src here, but it would not survive re-renders.
    setImageSource(undefined);
  };

  return (
    <HStack className={classes.projectNameRowContainer}>
      <Link
        data-pendo-topic={PendoTopic.PORTFOLIO}
        data-pendo-label="Go to Progress Tracking from project thumbnail"
        href={progressTrackingPath}
        onClick={goToProgressTracking}
      >
        {imageSource ? (
          <Image alt="Go to project" className={classes.projectImage} onError={onImageLoadError} src={imageSource} />
        ) : (
          <Icon aria-label="Go to project" as={ProjectDefaultIcon} className={classes.projectImage} />
        )}
      </Link>
      <VStack alignItems="flex-start" flex={1} spacing={0}>
        <Link
          data-pendo-topic={PendoTopic.PORTFOLIO}
          data-pendo-label="Go to Progress Tracking from project name"
          className={classes.projectName}
          href={progressTrackingPath}
          onClick={goToProgressTracking}
        >
          {projectName}
        </Link>
        <Text as="span" className={classes.projectAddress}>
          {formatAddress(projectAddress.street, projectAddress.city, projectAddress.state, projectAddress.zip)}
        </Text>
      </VStack>
    </HStack>
  );
};

export default ProjectInfoWithThumbnail;
