import { Button, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { ReactElement, useState } from 'react';
import * as Yup from 'yup';

import api from '../../api';
import { OnboardingStep } from '../../containers/Onboarding';
import { transformErrorResponse } from '../../utils/error';
import Caption from '../Caption';
import FormControl from '../FormControl';

import classes from './Onboarding.module.scss';

interface Props {
  onboardingToken?: string;
  nextAction: (next: OnboardingStep) => void;
  personaOptions: Array<{ id: number; persona: string }>;
  setData: (data: FormValues) => void;
}

export interface FormValues {
  first_name: string;
  last_name: string;
  persona_id: string;
  title: string;
  company_name: string;
  phone: string;
}

const validationSchema = Yup.object({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  persona_id: Yup.string().required('Role is required'),
  title: Yup.string().required('Title is required'),
  company_name: Yup.string().required('Company is required'),
  phone: Yup.string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number must match format XXX-XXX-XXXX'),
});

const GeneralInfoForm = ({ nextAction, onboardingToken, personaOptions, setData }: Props): ReactElement => {
  const [submitError, setSubmitError] = useState<string | undefined>();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    setSubmitError(undefined);
    try {
      await api.userToken.setProfile(onboardingToken, {
        ...values,
        persona_id: Number(values.persona_id),
        phone: isEmpty(values.phone) ? undefined : values.phone?.replaceAll('-', ''),
      });

      setData(values);
      nextAction(OnboardingStep.PASSWORD);
    } catch (error) {
      console.error('[Onboarding] Failed to set user account information!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to save your account information. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        persona_id: '',
        title: '',
        company_name: '',
        phone: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
        <Form className={classes.form}>
          <FormControl isInvalid={Boolean(errors.first_name) && touched.first_name} variant="external">
            <Caption as={FormLabel}>First Name</Caption>
            <Input
              name="first_name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="First name"
              value={values.first_name}
            />
            <ErrorMessage component={FormErrorMessage} name="first_name" />
          </FormControl>
          <FormControl isInvalid={Boolean(errors.last_name) && touched.last_name} variant="external">
            <Caption as={FormLabel}>Last Name</Caption>
            <Input
              name="last_name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Last name"
              value={values.last_name}
            />
            <ErrorMessage component={FormErrorMessage} name="last_name" />
          </FormControl>
          <FormControl isInvalid={Boolean(errors.persona_id) && touched.persona_id} variant="external">
            <Caption as={FormLabel}>Role</Caption>
            <Select
              data-defaultselected={!values.persona_id}
              name="persona_id"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Role"
              value={values.persona_id}
            >
              {personaOptions.map(({ id, persona }) => (
                <option key={`onboarding-persona-option-${id}`} value={id}>
                  {persona}
                </option>
              ))}
            </Select>
            <ErrorMessage component={FormErrorMessage} name="persona_id" />
          </FormControl>
          <FormControl isInvalid={Boolean(errors.title) && touched.title} variant="external">
            <Caption as={FormLabel}>Title</Caption>
            <Input name="title" onBlur={handleBlur} onChange={handleChange} placeholder="Title" value={values.title} />
            <ErrorMessage component={FormErrorMessage} name="title" />
          </FormControl>
          <FormControl isInvalid={Boolean(errors.company_name) && touched.company_name} variant="external">
            <Caption as={FormLabel}>Company</Caption>
            <Input
              name="company_name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Company"
              value={values.company_name}
            />
            <ErrorMessage component={FormErrorMessage} name="company_name" />
          </FormControl>
          <FormControl isInvalid={Boolean(errors.phone) && touched.phone} variant="external">
            <Caption as={FormLabel}>Phone Number</Caption>
            <Input
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Phone number (XXX-XXX-XXXX)"
              type="tel"
              value={values.phone}
            />
            <ErrorMessage component={FormErrorMessage} name="phone" />
          </FormControl>
          {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
          <Button isLoading={isSubmitting} marginBlockEnd="2.5rem" size="lg" type="submit" variant="primaryExternal">
            Continue
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default GeneralInfoForm;
