import { naturalSort } from '.';
import { Floorplan } from '../@types/api/v0/rest/Floorplan';
import { ProjectHierarchyNode, ProjectHierarchyNodeNormalized } from '../@types/api/v1/bespoke/ProjectHierarchy';

/** Given the flat list of hierarchy items provided by the API, build and return a nested/tree representation. */
export const denormalizeHierarchy = (
  hierarchyNodes: ProjectHierarchyNodeNormalized[],
  floorplans: Floorplan[]
): ProjectHierarchyNode[] => {
  if (hierarchyNodes.length === 0) {
    return [];
  }

  const tree: ProjectHierarchyNode[] = [];
  const childOf: Record<string, ProjectHierarchyNode[]> = {};

  // Initialize a map to track whether or not the hierarchy representation has data problems. We need to ensure that:
  // 1. All floorplans are actually used (check that all values are `true` by the end).
  // 2. There are only references to floorplans which exist within the project (check via key existence within loop).
  const floorplansUsedInHierarchy = new Map<number, boolean>();

  for (const floorplan of floorplans) {
    floorplansUsedInHierarchy.set(floorplan.id, false);
  }

  for (const hierarchyNode of hierarchyNodes) {
    const { id, parent_id: parentId, floorplans: floorplanIds, name, order } = hierarchyNode;

    childOf[id] = childOf[id] ?? [];

    const newHierarchyNode: ProjectHierarchyNode = {
      id,
      name,
      order,
      floorplans: [],
      children: childOf[id],
    };

    for (const floorplanId of floorplanIds) {
      if (!floorplansUsedInHierarchy.has(floorplanId)) {
        console.warn('[treeUtils] Floorplan referenced in hierarchy but not found in floorplan list', {
          floorplanId,
          hierarchyId: id,
        });
        continue;
      }

      // Non-null assertion here because `floorplansUsedInHierarchy`'s keys are derived from the list of floorplans.
      const floorplan = floorplans.find((floorplan) => floorplan.id === floorplanId)!;
      newHierarchyNode.floorplans.push(floorplan);

      floorplansUsedInHierarchy.set(floorplan.id, true);
    }

    newHierarchyNode.floorplans = naturalSort([...newHierarchyNode.floorplans], 'name');

    if (parentId) {
      childOf[parentId] = childOf[parentId] ?? [];
      childOf[parentId].push(newHierarchyNode);
    } else {
      tree.push(newHierarchyNode);
    }
  }

  const unusedFloorplanIds: number[] = [];
  for (const floorplan of floorplans) {
    if (!floorplansUsedInHierarchy.get(floorplan.id)) {
      unusedFloorplanIds.push(floorplan.id);
    }
  }

  if (unusedFloorplanIds.length > 0) {
    console.warn('[treeUtils] One or more floorplans missing from hierarchy!', unusedFloorplanIds);
    const newHierarchyNode: ProjectHierarchyNode = {
      id: 0,
      name: 'Default',
      order: -1,
      floorplans: naturalSort(
        floorplans.filter((floorplan) => unusedFloorplanIds.includes(floorplan.id)),
        'name'
      ),
      children: [],
    };
    tree.push(newHierarchyNode);
  }

  return tree;
};

/** Out of a set of nodes, find the node with the specified ID. If the node cannot be found, `undefined` is returned. */
export const findHierarchyNode = (
  hierarchyNodes: ProjectHierarchyNode[],
  nodeId: number
): ProjectHierarchyNode | undefined => {
  for (const node of hierarchyNodes) {
    if (node.id === nodeId) {
      return node;
    }
    if (node.children.length === 0) {
      continue;
    }

    const foundNode = findHierarchyNode(node.children, nodeId);
    if (foundNode) {
      return foundNode;
    }
  }

  return undefined;
};
