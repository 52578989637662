import { useQuery } from '@tanstack/react-query';

import { PortfolioProject } from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { PortfolioDashboardApi } from '../../api/v1/bespoke/PortfolioDashboardApi';
import { PortfolioDashboardQueryKeys, QueryTopics } from '../../constants/queries';
import { ProjectDrawer } from './ProjectDrawer';

export interface ProjectDrawerContainerProps {
  /** Whether or not the drawer is visible. */
  isOpen: boolean;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: PortfolioProject;
}

const ProjectDrawerContainer = (props: ProjectDrawerContainerProps) => {
  const { isOpen, onClose, project } = props;

  const progressHistoryQuery = useQuery({
    enabled: Boolean(project),
    queryKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.PROGRESS_HISTORY, project.id],
    queryFn: async ({ signal }) => (await PortfolioDashboardApi.getProgressHistory(project?.id, { signal })).data,
  });

  const milestoneProgressQuery = useQuery({
    enabled: Boolean(project),
    queryKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.MILESTONE_PROGRESS, project.id],
    queryFn: async ({ signal }) => (await PortfolioDashboardApi.getMilestoneProgress(project?.id, { signal })).data,
  });

  const isFetching = progressHistoryQuery.isFetching || milestoneProgressQuery.isFetching;
  const isError = progressHistoryQuery.isError || milestoneProgressQuery.isError;

  return (
    <ProjectDrawer
      isOpen={isOpen}
      onClose={onClose}
      project={project}
      progressHistory={progressHistoryQuery.data}
      milestoneProgress={milestoneProgressQuery.data}
      isFetching={isFetching}
      isError={isError}
    />
  );
};

export default ProjectDrawerContainer;
