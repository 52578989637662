import { ButtonGroup, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Node } from '../../@types/api/v0/rest/Node';
import { Project } from '../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../@types/api/v0/rest/Walkthrough';
import { CoachOverlay } from '../../components';
import PanographViewer, { PanographViewerInstance } from '../../components/PanographViewer/PanographViewer';
import { CameraConstants } from '../../components/PanographViewer/constants';
import BrighteningButton from '../../components/View360/BrighteningButton';
import BrighteningSlider from '../../components/View360/BrighteningSlider';
import Map from '../../components/View360/Map';
import MarkPointButton from '../../components/View360/MarkPointButton';
import SnapshotButton from '../../components/View360/SnapshotButton';
import { ZoomIn, ZoomOut } from '../../components/ZoomControls/ZoomControls';
import Routes from '../../routes';
import { isMobile } from '../../utils/device';
import AnnotationModalContainer from './AnnotationModal/AnnotationModalContainer';
import SnapshotModalContainer from './SnapshotModal/SnapshotModalContainer';

export interface View360PageProps {
  /** The list of all annotations/markups on any node in the current walkthrough. */
  annotations: Annotation[];
  /** Floorplan of the walkthrough currently being viewed in 360º. */
  floorplan: Floorplan;
  /**
   * Flag indicating whether or not the 360 viewer's scene is loading. This value may be `true` if either required data
   * or imagery is being loaded. For example, walkthrough/annotation data is loaded when the user time travels. Loading
   * events within the viewer itself may also trigger this flag to be `true`, for example when an image is loaded due to
   * the user clicking on a ground node.
   */
  isLoadingScene: boolean;
  /** Coordinates on the 360 viewer's sphere which the viewer should snap to. */
  lookTarget?: { x: number; y: number; z: number };
  /** The current walkthrough node. */
  node?: Node;
  /** Handler to call when the user creates a new annotation. */
  onAnnotationCreate: (annotation: Annotation) => void;
  /** Handler to call when the user clicks on an annotation in 360. */
  onAnnotationView: (annotation: Annotation) => void;
  /** The current project. */
  project: Project;
  /** Handler to call when the viewer starts or finishes loading imagery to render. */
  setIsLoadingSceneImage: (isLoadingSceneImage: boolean) => void;
  /** Handler to call when the `lookTarget` viewer sphere coordinates should change. */
  setLookTarget: (lookTarget?: { x: number; y: number; z: number }) => void;
  /** The current walkthrough being viewed in 360º. */
  walkthrough?: Walkthrough;
}

enum AnnotationWorkflowStatus {
  INACTIVE,
  MARKING_POINT,
  MODAL_VISIBLE,
}

const BRIGHTNESS_INCREMENTS = {
  1: 0.5,
  2: 1,
  3: 3,
  4: 5,
};

/** Default zoom increment used by the zoom buttons. Wheel events use a different increment. */
const ZOOM_DEFAULT_INCREMENT = (CameraConstants.MAX_FOV - CameraConstants.MIN_FOV) / 2;

const View360Page = (props: View360PageProps) => {
  const {
    annotations,
    floorplan,
    isLoadingScene,
    lookTarget,
    node,
    onAnnotationCreate,
    onAnnotationView,
    project,
    setIsLoadingSceneImage,
    setLookTarget,
    walkthrough,
  } = props;

  const history = useHistory();
  const location = useLocation();

  // 360 overlay state:
  const [annotationWorkflowStatus, setAnnotationWorkflowStatus] = useState<AnnotationWorkflowStatus>(
    AnnotationWorkflowStatus.INACTIVE
  );
  const [brightnessFilterValue, setBrightnessFilterValue] = useState<number>();
  const [isBrightening, setIsBrightening] = useState<boolean>(false);
  const [imageDataUrl, setImageDataUrl] = useState<string>();
  const [isSnapshotModalOpen, setIsSnapshotModalOpen] = useState<boolean>(false);

  // Hoisted viewer state:
  // When the user creates a new annotation, they begin by selecting a point. If present, these coordinates represent a
  // point on the 3D sphere which bounds the scene where the annotation should be placed. */
  const [annotationTarget, setAnnotationTarget] = useState<{ x: number; y: number; z: number }>();
  // The `node` prop corresponds to the URL node. Since we may update the current node without immediately updating the
  // URL, maintain a second reference. This is particularly useful during vertical arrow key nav.
  const [currentNode, setCurrentNode] = useState<Node | undefined>(node);
  // The view cone begins facing down the +z axis, which is the +y axis in 2D, so the default direction is <0, 1>.
  const [mapDirection, setMapDirection] = useState<{ x: number; y: number }>({ x: 0, y: 1 });
  // Field of view value used by the 360º viewer's three.js PerspectiveCamera. Increasing this value zooms the camera
  // out; decreasing it zooms the camera in.
  const [zoomFieldOfView, setZoomFieldOfView] = useState(CameraConstants.INITIAL_FOV);

  const panographViewerRef = useRef<PanographViewerInstance>(null);

  const setURL = useCallback(
    (nodeId: string) => {
      const destination = generatePath(Routes.VIEW_360, {
        projectId: project.id,
        floorplanId: floorplan.id,
        walkthroughId: walkthrough?.id,
        nodeId,
      });
      history.replace(destination);
    },
    [project.id, floorplan.id, history, walkthrough?.id]
  );

  const zoomIn = useCallback(
    (increment: number = ZOOM_DEFAULT_INCREMENT) => {
      setZoomFieldOfView(Math.max(zoomFieldOfView - increment, CameraConstants.MIN_FOV));
      panographViewerRef.current?.focus();
    },
    [zoomFieldOfView]
  );

  const zoomOut = useCallback(
    (increment: number = ZOOM_DEFAULT_INCREMENT) => {
      setZoomFieldOfView(Math.min(zoomFieldOfView + increment, CameraConstants.MAX_FOV));
      panographViewerRef.current?.focus();
    },
    [zoomFieldOfView]
  );

  useEffect(() => {
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);
    setImageDataUrl(undefined);
    setIsSnapshotModalOpen(false);
    removeBrighteningFilter();

    const lookTargetVector = getTargetLookVector();
    if (lookTargetVector) {
      setLookTarget(lookTargetVector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walkthrough]);

  // Minimap click, 360 ground node click, or arrow key nav has finished. Update the URL.
  useEffect(() => {
    if (
      !isLoadingScene &&
      currentNode &&
      node &&
      currentNode.walkthrough === node.walkthrough &&
      currentNode.id !== node.id
    ) {
      setURL(currentNode.id);
    }
  }, [currentNode, node, isLoadingScene, setURL]);

  // Time travel
  useEffect(() => {
    if (node && currentNode && currentNode.walkthrough !== node.walkthrough) {
      setCurrentNode(node);
    }
  }, [currentNode, node]);

  useEffect(() => {
    if (!lookTarget && annotationTarget) {
      panographViewerRef.current?.requestSnapshot();
    }
  }, [annotationTarget, lookTarget]);

  /** Stop the annotation creation workflow. */
  const cancelSelectPoint = () => {
    if (annotationTarget) {
      setAnnotationTarget(undefined);
    }
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);
    setImageDataUrl(undefined);
    panographViewerRef.current?.setMarking(false);
  };

  const closeSnapshotModal = () => {
    setBrightnessFilterValue(undefined);
    setImageDataUrl(undefined);
    setIsSnapshotModalOpen(false);
  };

  const getTargetLookVector = (): { x: number; y: number; z: number } | null => {
    if (!location.search) {
      return null;
    }

    let target = null;
    const params = new URLSearchParams(location.search);

    // This parameter is primarily set in email notifications prompting the user to view activity on the platform.
    if (params.has('an')) {
      const annotation = annotations?.find((a) => a.id === params.get('an'));
      if (annotation) {
        target = {
          x: annotation.x,
          y: annotation.y,
          z: annotation.z,
        };
      }
    }

    // These are set when clicking on annotations or detections on the floorplan page.
    if (params.has('lookX') && params.has('lookY') && params.has('lookZ')) {
      const parsedTarget = {
        x: Number.parseFloat(params.get('lookX') as string),
        y: Number.parseFloat(params.get('lookY') as string),
        z: Number.parseFloat(params.get('lookZ') as string),
      };

      if (Number.isFinite(parsedTarget.x) && Number.isFinite(parsedTarget.y) && Number.isFinite(parsedTarget.z)) {
        target = parsedTarget;
      }
    }

    return target;
  };

  const handleChangeBrighteningFilter = (value: 1 | 2 | 3 | 4) => {
    panographViewerRef.current?.setBrightness(BRIGHTNESS_INCREMENTS[value]);
    setBrightnessFilterValue(BRIGHTNESS_INCREMENTS[value]);
  };

  const onClickMinimapNode = (targetNode: Node) => {
    removeBrighteningFilter();
    setCurrentNode(targetNode);
    const annotation = annotations?.find((a) => a.node === targetNode.id);
    if (annotation) {
      setLookTarget({
        x: annotation.x,
        y: annotation.y,
        z: annotation.z,
      });
      onAnnotationView(annotation);
    }
  };

  /**
   * When the image data for a snapshot is available, decide which modal needs to be shown. Snapshots are created for
   * either the "Create a Markup" or "Take a Screenshot" modals.
   */
  const onSnapshotAvailable = (dataUrl: string) => {
    setImageDataUrl(dataUrl);
    if (annotationTarget && annotationWorkflowStatus === AnnotationWorkflowStatus.MARKING_POINT) {
      setAnnotationWorkflowStatus(AnnotationWorkflowStatus.MODAL_VISIBLE);
    } else {
      setIsSnapshotModalOpen(true);
    }
  };

  const removeBrighteningFilter = (keepFilter?: boolean) => {
    setIsBrightening(false);
    setBrightnessFilterValue((currentValue) => (keepFilter ? currentValue : undefined));
    panographViewerRef.current?.clearBrightness();
  };

  /**
   * Request that the 360º viewer generate a snapshot of its canvas on the next render loop (snapshots cannot be
   * reliably captured outside of it).
   */
  const requestSnapshot = () => {
    removeBrighteningFilter(true);
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);
    panographViewerRef.current?.setMarking(false);
    panographViewerRef.current?.requestSnapshot();
  };

  const toggleBrightening = () => {
    panographViewerRef.current?.setMarking(false);
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);

    if (isBrightening) {
      removeBrighteningFilter();
    } else {
      panographViewerRef.current?.setBrightness(3);
      setBrightnessFilterValue(3);
      setIsBrightening(true);
    }
  };

  /**
   * Toggle the annotation creation workflow. Begin by picking a point in 3D space. If the image brightness has been
   * modified, remove the filter.
   */
  const toggleSelectPoint = () => {
    if (annotationWorkflowStatus !== AnnotationWorkflowStatus.INACTIVE) {
      cancelSelectPoint();
      return;
    }

    if (isBrightening) {
      removeBrighteningFilter();
    }

    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.MARKING_POINT);
    panographViewerRef.current?.setMarking(true);
  };

  const isAnnotatingScene = Boolean(annotationWorkflowStatus);
  const isAnnotationModalOpen = annotationWorkflowStatus === AnnotationWorkflowStatus.MODAL_VISIBLE;

  return (
    <>
      {/* TODO: replace coach overlay with toast */}
      {annotationWorkflowStatus === AnnotationWorkflowStatus.MARKING_POINT && (
        <CoachOverlay>Click anywhere on the view to create a markup</CoachOverlay>
      )}
      <PanographViewer
        ref={panographViewerRef}
        annotations={annotations}
        floorplan={floorplan}
        isLoadingScene={isLoadingScene}
        lookTarget={lookTarget}
        node={currentNode}
        onAnnotationView={onAnnotationView}
        onSnapshotAvailable={onSnapshotAvailable}
        setAnnotationTarget={setAnnotationTarget}
        setIsLoadingSceneImage={setIsLoadingSceneImage}
        setLookTarget={setLookTarget}
        setMapDirection={setMapDirection}
        setNode={setCurrentNode}
        walkthrough={walkthrough}
        zoomFieldOfView={zoomFieldOfView}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
      />
      <Flex
        bottom="var(--gutter-360-control-vertical)"
        flexDirection="column"
        position="absolute"
        right="var(--gutter-360-control-horizontal)"
        zIndex="var(--z-index-view360-controls)"
      >
        {isBrightening && <BrighteningSlider onChange={handleChangeBrighteningFilter} />}
        <BrighteningButton
          buttonProps={{ marginBottom: '0.3125rem' }}
          isActive={isBrightening}
          isDisabled={isLoadingScene}
          onClick={toggleBrightening}
        />
        <ButtonGroup flexDirection="column" marginBottom="0.3125rem" spacing={0} variant="view360">
          <SnapshotButton
            buttonProps={{ borderBottomRadius: 0, marginBottom: '0.0625rem' }}
            isActive={isSnapshotModalOpen}
            isDisabled={isLoadingScene}
            onClick={requestSnapshot}
          />
          <MarkPointButton
            buttonProps={{ borderTopRadius: 0 }}
            isActive={isAnnotatingScene}
            isDisabled={isLoadingScene}
            onClick={toggleSelectPoint}
          />
        </ButtonGroup>
        <ButtonGroup flexDirection="column" spacing={0} variant="view360">
          <ZoomIn
            buttonProps={{
              borderBottomRadius: 0,
              'data-pendo-label': 'Zoom in',
              'data-pendo-topic': 'view360',
              marginBottom: '0.0625rem',
            }}
            isDisabled={isLoadingScene || zoomFieldOfView <= CameraConstants.MIN_FOV}
            onClick={zoomIn}
          />
          <ZoomOut
            buttonProps={{ borderTopRadius: 0, 'data-pendo-label': 'Zoom out', 'data-pendo-topic': 'view360' }}
            isDisabled={isLoadingScene || zoomFieldOfView >= CameraConstants.MAX_FOV}
            onClick={zoomOut}
          />
        </ButtonGroup>
      </Flex>
      <SnapshotModalContainer
        brightness={brightnessFilterValue}
        floorplan={floorplan}
        image={imageDataUrl}
        isOpen={isSnapshotModalOpen}
        node={node}
        onClose={closeSnapshotModal}
        project={project}
        walkthrough={walkthrough}
      />
      <AnnotationModalContainer
        annotationTarget={annotationTarget}
        floorplan={floorplan}
        image={imageDataUrl}
        isOpen={isAnnotationModalOpen}
        node={node}
        onAnnotationCreate={onAnnotationCreate}
        onClose={cancelSelectPoint}
        project={project}
      />
      {!isMobile() && (
        <Map
          annotations={annotations}
          currentNode={currentNode}
          floorplan={floorplan}
          mapDirection={mapDirection}
          nodes={walkthrough?.nodes ?? []}
          onClickNode={onClickMinimapNode}
        />
      )}
    </>
  );
};

export default View360Page;
