import { AxiosRequestConfig } from 'axios';

import { Project } from '../../../@types/api/v1/rest/Project';
import { get, patch } from '../../next';

/** Request shape used when updating a project's baseline completion date. */
export interface ProjectBaselineCompletionDateUpdateRequestBody {
  /**
   * The projected completion date for the project. If present, This is the date portion (i.e. everything up to the "T")
   * of a RFC 3339 timestamp.
   *
   * @example "2024-10-18"
   */
  baseline_completion_date: string | null;
}

/**
 * Response shape returned after updating a project's baseline completion date. Note that this is currently the same
 * shape as the request.
 */
export type ProjectBaselineCompletionDateUpdateResponseBody = ProjectBaselineCompletionDateUpdateRequestBody;

/**
 * Request shape used when updating milestone-related dates for a project. The only dates that are currently supported
 * are baseline completion dates, i.e. end dates.
 */
export interface ProjectMilestoneDateUpdateRequestBody {
  /** ID of the milestone. */
  milestone_id: number;
  /**
   * Projected date on which the given milestone/trade is expected to be completed for the project. This is the date
   * portion (i.e. everything up to the "T") of a RFC 3339 timestamp.
   *
   * @example "2024-10-18"
   */
  end_date: string;
}

/**
 * Response shape returned when a milestone's dates are successfully set for a project. Note that this is currently the
 * same shape as the request.
 */
export type ProjectMilestoneDateUpdateResponseBody = ProjectMilestoneDateUpdateRequestBody;

export const ProjectApi = {
  /**
   * Retrieve a project instance by ID.
   * @param id Unique ID of the desired project on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested project.
   */
  getById: (id: number, requestConfig?: AxiosRequestConfig) =>
    get<Project>(`api/v1/rest/projects/${id}/`, requestConfig),
  /**
   * Update a given project's baseline completion date. Users can make this request if they have the `SET_TRADE_DATES`
   * permission for the project's account.
   *
   * @param projectId The ID of the project.
   * @param newDate The date formatted as `YYYY-mm-dd`, e.g. `2023-12-20`.
   * @param requestConfig Optional Axios request configuration overrides.
   */
  updateBaselineCompletionDate: (projectId: number, newDate: string, requestConfig?: AxiosRequestConfig) =>
    patch<ProjectBaselineCompletionDateUpdateRequestBody, ProjectBaselineCompletionDateUpdateResponseBody>(
      `api/v1/rest/projects/${projectId}/baseline-completion-date/`,
      { baseline_completion_date: newDate },
      requestConfig
    ),
  /**
   * Update a milestone/trade date entry for a given project.
   * @param projectId The ID of the project.
   * @param requestBody The milestone ID and date data to set on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the updated project's milestone date entry.
   */
  updateMilestoneDate: (
    projectId: number,
    requestBody: ProjectMilestoneDateUpdateRequestBody,
    requestConfig?: AxiosRequestConfig
  ) =>
    patch<ProjectMilestoneDateUpdateRequestBody, ProjectMilestoneDateUpdateResponseBody>(
      `api/v1/rest/projects/${projectId}/milestone-dates/`,
      requestBody,
      requestConfig
    ),
};
