import { Button, Checkbox, Flex, Text, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Store } from '../../@types/redux/store';
import { login } from '../../actions/auth';
import api from '../../api';
import Toast from '../Toast';

interface FormValues {
  new_feature_announcement: boolean;
  new_walkthrough_notification: boolean;
}

const validationSchema = Yup.object({
  new_feature_announcement: Yup.boolean().required(),
  new_walkthrough_notification: Yup.boolean().required(),
});

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: Store) => state.auth.user);

  const toast = useToast();

  const handleSubmit = async (values: FormValues) => {
    try {
      const updatedProfile = await api.auth.setProfile({ ...values, update_source: 'notification_settings' });
      dispatch(login(updatedProfile));

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description="Notification preferences saved." status="success" />
        ),
      });
    } catch (error) {
      console.error('[NotificationSettings] Failed to save notification preferences!', error);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Error"
            description="Failed to save notification preferences. Please try again later."
            status="error"
          />
        ),
      });
    }
  };

  return (
    <Formik
      initialValues={{
        new_feature_announcement: userDetails?.new_feature_announcement ?? false,
        new_walkthrough_notification: userDetails?.new_walkthrough_notification ?? false,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, isSubmitting, values }) => (
        <Form>
          <Flex direction="column">
            <Text marginBlockEnd="1.5rem">
              Help us improve your experience with OnsiteIQ through better communication. Please adjust your email
              preferences below.
            </Text>
            <Checkbox
              isChecked={values.new_walkthrough_notification}
              marginBlockEnd="1rem"
              name="new_walkthrough_notification"
              onChange={handleChange}
            >
              New walkthrough updates
            </Checkbox>
            <Checkbox
              isChecked={values.new_feature_announcement}
              marginBlockEnd="1rem"
              name="new_feature_announcement"
              onChange={handleChange}
            >
              New feature announcements
            </Checkbox>
            <Flex alignItems="center" justifyContent="flex-end">
              <Button
                isLoading={isSubmitting}
                padding="0.5rem 1.25rem"
                size="md"
                type="submit"
                variant="highEmphasisV2"
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default NotificationSettings;
