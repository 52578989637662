import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ReactNode, useState } from 'react';
import * as Yup from 'yup';

export interface FormStep {
  /** Optional function to be called when this step is submitted */
  onSubmit?: <Type extends Record<string, any>>(values: Type) => void;
  /** Validation schema to be used to validate the form fields in this step */
  validationSchema?: Yup.ObjectSchema;
  /** Content to be rendered for this step */
  content: ReactNode;
}

export interface MultiStepFormProps<Type extends Record<string, any>> {
  /** An array of elements, each of which is a step of the form */
  steps: FormStep[];
  /** The initial values of the form */
  initialValues: Type;
  /** Function called when the Cancel button is clicked */
  onCancel: () => void;
  /** Function called when the form is submitted (when the last step is submitted) */
  onSubmit: (values: Type, helpers?: FormikHelpers<Type>) => void;
  /** Optional function to call when the step number changes. Takes the step number as a parameter. */
  onChangeStep?: (stepNumber: number) => void;
  /** Text to display on the Submit button. Defaults to 'Submit' */
  submitBtnText?: string;
}

export const MultiStepForm = <Type extends Record<string, any>>({
  steps,
  initialValues,
  onCancel,
  onSubmit,
  onChangeStep,
  submitBtnText = 'Submit',
}: MultiStepFormProps<Type>) => {
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);

  const currentStep = steps[currentStepNumber];
  const totalSteps = steps.length;
  const isLastStep = currentStepNumber === totalSteps - 1;

  /**
   * Function called when the 'Next' button is clicked (note: last step has no 'Next' button)
   * @param values The form values
   */
  const handleClickNextBtn = (values: Type) => {
    setFormValues(values);
    const newStepNumber = Math.min(currentStepNumber + 1, totalSteps - 1);
    setCurrentStepNumber(newStepNumber);
    if (onChangeStep instanceof Function) {
      onChangeStep(newStepNumber);
    }
  };

  /**
   * Function called when the 'Go back' button is clicked
   * @param values The form values
   */
  const handleClickBackBtn = (values: Type) => {
    setFormValues(values);
    const newStepNumber = Math.max(currentStepNumber - 1, 0);
    setCurrentStepNumber(newStepNumber);
    if (onChangeStep instanceof Function) {
      onChangeStep(newStepNumber);
    }
  };

  /** Function called when the Cancel button is clicked */
  const handleClickCancelBtn = () => {
    onCancel();
  };

  /**
   * Function called when the form is submitted
   * @param values The form values
   * @param helpers A bunch of Formik methods to interact with the form
   */
  const handleSubmit = async (values: Type, helpers: FormikHelpers<Type>) => {
    if (currentStep.onSubmit) {
      await currentStep.onSubmit(values);
    }
    if (isLastStep) {
      onSubmit(values, helpers);
    } else {
      // Reset the form `touched` object
      helpers.setTouched({});
      handleClickNextBtn(values);
    }
  };

  if (steps.length === 0) {
    return <Box>No steps provided</Box>;
  }

  return (
    <Formik initialValues={formValues} onSubmit={handleSubmit} validationSchema={currentStep.validationSchema}>
      {({ values, isSubmitting }) => (
        <Form role="form">
          {currentStep.content}
          <ButtonGroup display="flex" justifyContent="flex-end" marginTop="1rem">
            <Button onClick={handleClickCancelBtn} padding="0.5rem 1.25rem" size="md" variant="mediumEmphasisV2">
              Cancel
            </Button>
            {currentStepNumber > 0 && (
              <Button
                onClick={() => handleClickBackBtn(values)}
                padding="0.5rem 1.25rem"
                size="md"
                variant="mediumEmphasisV2"
              >
                Go back
              </Button>
            )}
            <Button disabled={isSubmitting} padding="0.5rem 1.25rem" size="md" variant="highEmphasisV2" type="submit">
              {isLastStep ? submitBtnText : 'Next'}
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};
