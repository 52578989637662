import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { ForwardedRef, forwardRef } from 'react';
import * as Yup from 'yup';

import { ClearIndicator, Control, DropdownIndicator } from '../../components/Select/ChakraReactSelectOverrides';
import { INVITE_LIMIT_USERS } from '../../components/UserManagement/InviteUserModal/InviteUserModalConstants';

export interface ShareProjectFormValues {
  users: string[];
}

export interface ShareProjectFormProps {
  onSubmit: (values: ShareProjectFormValues) => void;
}

const validationSchema = Yup.object({
  users: Yup.array()
    .of(Yup.string().email('All entries must be valid email addresses.'))
    .min(1, 'At least one email address is required.')
    .max(INVITE_LIMIT_USERS, `Up to ${INVITE_LIMIT_USERS} email addresses may be provided.`),
});

const ShareProjectForm = forwardRef(
  (props: ShareProjectFormProps, ref: ForwardedRef<FormikProps<ShareProjectFormValues>>) => {
    const { onSubmit } = props;

    return (
      <Formik
        innerRef={ref}
        initialValues={{ users: [] }}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={validationSchema}
      >
        <Form>
          <Field name="users">
            {({ field, form, meta }: FieldProps<string[], ShareProjectFormValues>) => (
              <FormControl isInvalid={Boolean(meta.error) && Boolean(meta.touched)}>
                <FormLabel>To:</FormLabel>
                <CreatableSelect
                  chakraStyles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? 'var(--primary-100)' : 'white',
                      color: 'var(--gray-800)',
                    }),
                  }}
                  classNamePrefix="chakra-react-select"
                  colorScheme="purple"
                  components={{ ClearIndicator, Control, DropdownIndicator }}
                  formatCreateLabel={(inputValue) => `Add ${inputValue}`}
                  isMulti
                  noOptionsMessage={() => 'Enter email addresses'}
                  name={field.name}
                  controlShouldRenderValue
                  onBlur={() => form.setFieldTouched(field.name, true)}
                  onChange={(multiValues) => {
                    form.setFieldTouched(field.name, true, false);
                    form.setFieldValue(
                      field.name,
                      multiValues.map(({ value }) => value),
                      true
                    );
                  }}
                  value={field.value.map((emailAddress) => ({ label: emailAddress, value: emailAddress }))}
                  placeholder="Enter email addresses"
                  selectedOptionColorScheme="purple"
                  selectedOptionStyle="color"
                  useBasicStyles
                />
                <ErrorMessage component={FormErrorMessage} name={field.name} />
              </FormControl>
            )}
          </Field>
        </Form>
      </Formik>
    );
  }
);

export default ShareProjectForm;
