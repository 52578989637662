import { Box, Button, Collapse, Icon, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { ReactNode, useEffect, useRef } from 'react';

import theme from '../../theme';
import { ChevronDownIcon, ChevronRightIcon } from '../Icon';

type ListItemBase = { id: number | string; name: string };

export interface CollapsibleListItemProps<T extends ListItemBase> {
  /** Children to be rendered inside this item. Perhaps another `CollapsibleListItem`! Perhaps a `Leaf`! */
  children?: ReactNode;
  /** Item depth. Used for indentation. */
  depth?: number;
  /** Flag indicating whether or not this item is active or not. Active changes the styling. */
  isActive?: boolean;
  /** Flag indicating whether or not this item should start open or closed. Defaults to open. */
  isInitiallyOpen?: boolean;
  /** The item being represented in the list. */
  item: T;
  /** Handler to call when this leaf is clicked. */
  onClick?: (item: T) => void;
}

const CollapsibleListItem = <T extends ListItemBase>(props: CollapsibleListItemProps<T>) => {
  const { children, depth = 0, isActive, isInitiallyOpen = true, item, onClick } = props;

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: isInitiallyOpen });

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current && isActive) {
      buttonRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }, [buttonRef, isActive]);

  return (
    <Box data-testid={item.name} marginInlineStart={`calc(1rem * ${depth})`}>
      <Box alignItems="center" display="flex" justifyContent="flex-start" padding="0.25rem 0">
        <IconButton
          aria-controls={`collapsible-list-item-toggle-${item.id}`}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-label={isOpen ? `Collapse ${item.name}` : `Expand ${item.name}`}
          icon={<Icon as={isOpen ? ChevronDownIcon : ChevronRightIcon} height="1.5rem" width="1.5rem" />}
          id={`collapsible-list-item-trigger-${item.id}`}
          marginRight="0.25rem"
          onClick={onToggle}
          size="sm"
          variant="ghost"
          _active={{
            backgroundColor: theme.colors.brand.gray[100],
            border: `2px solid ${theme.colors.brand.gray[500]}`,
          }}
        />
        {onClick && typeof onClick === 'function' ? (
          <Button
            border="2px solid transparent"
            fontWeight="bold"
            height="auto"
            isActive={isActive}
            justifyContent="flex-start"
            lineHeight={1.25}
            onClick={() => {
              if (isActive) {
                return;
              }

              onClick(item);
            }}
            padding="calc(0.75rem - 2px) 1rem"
            ref={buttonRef}
            textAlign="left"
            textTransform="uppercase"
            variant="ghost"
            whiteSpace="normal"
            width="100%"
            _active={{
              backgroundColor: theme.colors.brand.primary[100],
              border: `2px solid ${theme.colors.brand.primary[500]}`,
              color: theme.colors.brand.gray[900],
            }}
          >
            {item.name}
          </Button>
        ) : (
          <Text as="span" fontWeight="bold" lineHeight={1.25} padding="0.75rem 1rem" textTransform="uppercase">
            {item.name}
          </Text>
        )}
      </Box>
      <Collapse
        aria-labelledby={`collapsible-list-item-trigger-${item.id}`}
        id={`collapsible-list-item-toggle-${item.id}`}
        in={isOpen}
        animateOpacity
      >
        <Box marginInlineStart="2.25rem">{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapsibleListItem;
