import {
  Button,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Select,
  Spacer,
  Stack,
  Switch,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ColumnDef, FilterFn, Row } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/table-core';
import { ChangeEventHandler, useMemo, useState } from 'react';

import { Account } from '../../@types/OnSiteIQ';
import { AccountUser } from '../../@types/UserManagement';
import { DataTable } from '../../components/DataTable/DataTable';
import { TableCell } from '../../components/DataTable/TableCell';
import UserProfileDrawerContainer from '../../components/Drawers/UserManagement/UserProfileDrawer/UserProfileDrawerContainer';
import LoadingIndicator from '../../components/LoadingIndicator';
import SearchInput from '../../components/SearchInput/SearchInput';
import InviteUserModalContainer from '../../components/UserManagement/InviteUserModal/InviteUserModalContainer';
import theme from '../../theme';
import { formatIsoDate } from '../../utils/dateUtils';
import { maybePluralize } from '../../utils/maybePluralize';
import { InviteStatus } from './AccountUsersContainer';
import StatusCellContainer from './StatusCell/StatusCellContainer';

import classes from './AccountUsersPage.module.scss';

export interface AccountUsersPageProps {
  /** ID of the currently-selected account. */
  accountId: number | undefined;
  /** Accounts to render in the Account selector. */
  accounts: Account[];
  /** Users within the selected account. */
  accountUsers: AccountUser[];
  /** Whether or not the user can invite other users (including resending invitations) to the current account. */
  canInviteToAccount?: boolean;
  /** Whether or not the user can toggle the visibility of internal (i.e. OnsiteIQ) users on the table. */
  canToggleInternalUsers?: boolean;
  /** Whether or not to show internal (i.e. OnsiteIQ) users on the table. */
  showInternalUsers?: boolean;
  /** Whether or not the list of users had an error while fetching. */
  isError?: boolean;
  /** Whether or not the list of users is fetching. */
  isFetching?: boolean;
  /** Function to call when the user changes the current account selection. */
  onAccountChange?: (accountId: number) => void;
  /** Function to call when the user finishes inviting users. */
  onInviteComplete?: (values: Record<string, any>) => void;
  /** Function to call when the user toggles the visiblity of internal (i.e. OnsiteIQ) users. */
  onShowInternalUsersChange?: (showInternalUsers: boolean) => void;
}

export const EmailCell = ({ email, canManageUsers }: { email: string; canManageUsers: boolean }) => (
  <Stack direction="row">
    <div>{email}</div>
    {canManageUsers ? <Tag borderRadius="full">Account owner</Tag> : null}
  </Stack>
);

export const NameCell = ({ name, status }: { name?: string; status: InviteStatus }) => {
  if (status === InviteStatus.Pending) {
    return <Text color={theme.colors.brand.gray[400]}>Pending user</Text>;
  }
  return <>{name}</>;
};

const AccountUsersPage = (props: AccountUsersPageProps) => {
  const {
    accountId,
    accounts,
    accountUsers,
    canInviteToAccount,
    canToggleInternalUsers,
    showInternalUsers,
    isError,
    isFetching,
    onInviteComplete,
    onAccountChange,
    onShowInternalUsersChange,
  } = props;

  /** Controls the value of the 'search users' input */
  const [filterText, setFilterText] = useState('');

  /** Controls the currently selected user (a user is selected by clicking its row in the table) */
  const [selectedUser, setSelectedUser] = useState<AccountUser>();

  const {
    isOpen: isInviteUserModalOpen,
    onOpen: onOpenInviteUserModal,
    onClose: onCloseInviteUserModal,
  } = useDisclosure();
  const {
    isOpen: isUserProfileDrawerOpen,
    onOpen: onUserProfileDrawerOpen,
    onClose: onUserProfileDrawerClose,
  } = useDisclosure();

  /** Filter function to be used when globally filtering the table (i.e. not filtering on any specific column, but across all columns simultaneously) */
  const globalFilterFn: FilterFn<AccountUser> = (row, columnId, filterText: string) => {
    const { email, name, company_name: companyName, created, count, last_login: lastLogin } = row.original;

    if (
      email.toLowerCase().includes(filterText.toLowerCase()) ||
      name.toLowerCase().includes(filterText.toLowerCase()) ||
      companyName?.toLowerCase().includes(filterText.toLowerCase()) ||
      formatIsoDate(created)?.formattedDateWithoutDay.toLowerCase().includes(filterText.toLowerCase()) ||
      count.toString().includes(filterText) ||
      // Specific cases for filtering by status. Probably not necessary, but including so that each column is filterable via the input field.
      (lastLogin && filterText.toLowerCase() === 'registered') ||
      (!lastLogin && filterText.toLowerCase() === 'pending')
    ) {
      return true;
    }

    // If none of the conditions are met, return false for the current row
    return false;
  };

  const columnHelper = createColumnHelper<AccountUser>();

  /** Define columns (headers + cell renderers) for the table */
  const columns = useMemo<ColumnDef<AccountUser, any>[]>(
    () => [
      columnHelper.accessor('email', {
        header: 'Email',
        cell: (cell) => (
          <TableCell onClick={() => handleCellClick(cell.row.original)}>
            <EmailCell email={cell.getValue()} canManageUsers={cell.row.original.manage_users} />
          </TableCell>
        ),
        sortingFn: 'text',
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (cell) => (
          <TableCell onClick={() => handleCellClick(cell.row.original)}>
            <NameCell
              name={cell.getValue()}
              status={cell.row.getValue('last_login') ? InviteStatus.Registered : InviteStatus.Pending}
            />
          </TableCell>
        ),
        sortingFn: (rowA, rowB, columnId) => {
          const rowAValue: string = rowA.getValue(columnId);
          const rowBValue: string = rowB.getValue(columnId);

          if (rowAValue === '' && rowBValue !== '') {
            return 1;
          }
          if (rowAValue !== '' && rowBValue === '') {
            return -1;
          }

          return rowAValue.localeCompare(rowBValue);
        },
      }),
      columnHelper.accessor('company_name', {
        header: 'Company',
        cell: (cell) => (
          <TableCell onClick={() => handleCellClick(cell.row.original)}>
            <Text>{cell.getValue() ?? '-'}</Text>
          </TableCell>
        ),
        sortingFn: (rowA, rowB, columnId) => {
          const rowAValue: string = rowA.getValue(columnId) ?? '';
          const rowBValue: string = rowB.getValue(columnId) ?? '';

          if (rowAValue === '' && rowBValue !== '') {
            return 1;
          }
          if (rowAValue !== '' && rowBValue === '') {
            return -1;
          }

          return rowAValue.localeCompare(rowBValue);
        },
      }),
      columnHelper.accessor('created', {
        header: 'Invited on',
        cell: (cell) => (
          <TableCell onClick={() => handleCellClick(cell.row.original)}>
            <Text>{formatIsoDate(cell.getValue())?.formattedDateWithoutDay ?? ''}</Text>
          </TableCell>
        ),
        sortingFn: (rowA, rowB, columnId) => {
          // These values are ISO8601 strings
          const rowAValue = new Date(rowA.getValue(columnId)).getTime();
          const rowBValue = new Date(rowB.getValue(columnId)).getTime();
          return rowAValue - rowBValue;
        },
        sortDescFirst: true,
      }),
      columnHelper.accessor('count', {
        header: 'Projects',
        cell: (cell) => (
          <TableCell onClick={() => handleCellClick(cell.row.original)}>
            <Text>
              {cell.row.original.has_all_projects
                ? 'All'
                : maybePluralize('project', cell.getValue(), { includeCount: true })}
            </Text>
          </TableCell>
        ),
        sortingFn: (rowA, rowB, columnId) => {
          const hasAllComparison = Number(rowA.original.has_all_projects) - Number(rowB.original.has_all_projects);
          if (hasAllComparison !== 0) {
            return hasAllComparison;
          }

          const diff = rowA.original.count - rowB.original.count;

          if (diff !== 0) {
            return diff;
          }
          // Secondary sort by email. We want to sort email ascending regardless of project sort order
          const sortDirection = rowA
            .getAllCells()
            .find((cell) => cell.column.id === columnId)
            ?.column.getIsSorted();
          const comp = rowA.original.email.localeCompare(rowB.original.email);
          // Reverse the email order to keep it ascending if the project sort order is descending
          return sortDirection === 'asc' ? comp : -comp;
        },
        sortDescFirst: true,
      }),
      columnHelper.accessor('last_login', {
        header: 'Status',
        cell: (cell) => (
          <TableCell onClick={() => handleCellClick(cell.row.original)}>
            <StatusCellContainer
              accountId={accountId!}
              email={cell.row.getValue('email')}
              isReadOnly={!canInviteToAccount}
              userId={cell.row.original.id}
              status={cell.getValue() ? InviteStatus.Registered : InviteStatus.Pending}
            />
          </TableCell>
        ),
        sortingFn: (rowA, rowB, columnId) => {
          // These values are ISO8601 strings
          const rowAValue: string | null = rowA.getValue(columnId);
          const rowBValue: string | null = rowB.getValue(columnId);

          if (!rowAValue && rowBValue) {
            return 1;
          }
          if (rowAValue && !rowBValue) {
            return -1;
          }

          return 0;
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, columnHelper]
  );

  const handleAccountSelect: ChangeEventHandler<HTMLSelectElement> = (event) => {
    onAccountChange?.(Number(event.target.value));
  };

  const handleCellClick = (accountUser: AccountUser) => {
    setSelectedUser(accountUser);
    onUserProfileDrawerOpen();
  };

  const handleSearchInputChange = (searchText: string) => {
    // TODO: consider debouncing this
    setFilterText(searchText);
  };

  const handleShowInternalUsersChange = () => {
    onShowInternalUsersChange?.(!showInternalUsers);
  };

  return (
    <>
      <Flex direction="row" alignItems="center">
        <Heading size="lg" as="h1">
          Users in
        </Heading>
        <Select
          aria-label="Account"
          data-pendo-topic="user-management"
          data-pendo-label="Change account on account users page"
          maxWidth="13.625rem"
          marginLeft="0.625rem"
          placeholder={accountId && accounts.length > 0 ? undefined : 'Account'}
          onChange={handleAccountSelect}
          value={accountId ? String(accountId) : ''}
        >
          {accounts.map((option) => (
            <option value={option.id} key={option.id}>
              {option.name}
            </option>
          ))}
        </Select>
        <Spacer />
        {canInviteToAccount && (
          <Button
            data-pendo-label="Open invite modal from account users page"
            data-pendo-topic="user-management"
            isDisabled={!accountId || isError || isFetching}
            onClick={onOpenInviteUserModal}
            padding="0.5rem 1.25rem"
            size="md"
            variant="highEmphasisV2"
          >
            Invite users
          </Button>
        )}
      </Flex>
      {isFetching && <LoadingIndicator />}
      {!isFetching && !isError && !accountId && (
        <Text
          backgroundColor={theme.colors.white}
          borderRadius={theme.radii.card}
          color={theme.colors.gray[600]}
          fontSize="0.875rem"
          marginTop="1rem"
          padding="1.5rem"
          textAlign="center"
        >
          Select an account to view users.
        </Text>
      )}
      {!isFetching && isError && (
        <Text
          backgroundColor={theme.colors.white}
          borderRadius={theme.radii.card}
          color={theme.colors.gray[600]}
          fontSize="0.875rem"
          marginTop="1rem"
          padding="1.5rem"
          textAlign="center"
        >
          An error occurred while loading users. Please try again later. If this issue persists, contact{' '}
          <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
        </Text>
      )}
      {!isFetching && Boolean(accountId) && !isError && (
        <div className={classes.accountUsersPageContent}>
          <Flex margin="1rem 0 0.625rem">
            <SearchInput
              onChange={handleSearchInputChange}
              value={filterText}
              placeholder="Search users"
              filterList={
                canToggleInternalUsers ? (
                  <Stack direction="row" alignItems="center" spacing="1.25rem" margin="0 0.75rem 0 0.5rem">
                    <Divider orientation="vertical" height="1.5rem" />
                    <FormLabel htmlFor="show-oiq-users" className={classes.showOIQUsersLabel}>
                      Show OnsiteIQ users
                    </FormLabel>
                    <Switch
                      data-pendo-topic="user-management"
                      data-pendo-label="Toggle OnsiteIQ users from account users page"
                      id="show-oiq-users"
                      isChecked={showInternalUsers}
                      onChange={handleShowInternalUsersChange}
                    />
                  </Stack>
                ) : null
              }
            />
          </Flex>
          <DataTable
            columns={columns}
            defaultSort={{ id: 'email', desc: false }}
            data={accountUsers}
            getRowStyles={(row: Row<AccountUser>) => ({
              backgroundColor: row.original.is_internal ? theme.colors.brand.primary[100] : undefined,
            })}
            globalFilter={filterText}
            globalFilterFn={globalFilterFn}
          />
          {selectedUser && (
            <UserProfileDrawerContainer
              accountId={accountId}
              isOpen={isUserProfileDrawerOpen}
              onClose={onUserProfileDrawerClose}
              user={selectedUser}
            />
          )}
          <InviteUserModalContainer
            currentAccountId={accountId}
            isOpen={isInviteUserModalOpen}
            onClose={onCloseInviteUserModal}
            onSubmitForm={onInviteComplete}
            shouldCloseOnSuccess
          />
        </div>
      )}
    </>
  );
};

export default AccountUsersPage;
