import { animate } from 'framer-motion';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import {
  BufferGeometry,
  CylinderGeometry,
  Group,
  LinearSRGBColorSpace,
  MathUtils,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Raycaster,
  Scene,
  SphereGeometry,
  Spherical,
  Sprite,
  SpriteMaterial,
  Texture,
  Vector2,
  Vector3,
  WebGL1Renderer,
} from 'three';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Node } from '../../@types/api/v0/rest/Node';
import { Walkthrough } from '../../@types/api/v0/rest/Walkthrough';
import GeometryHelper from './GeometryHelper';
import TextureManager from './TextureManager';
import {
  CameraConstants,
  ControlConstants,
  GeometryConstants,
  GlobalTextureNames,
  NavigationDirection,
  PanDirection,
  PerformanceConstants,
  TextureLoadingStatus,
  TextureQuality,
  TimingConstants,
  ViewerEvents,
  ViewerUpdateSource,
} from './constants';
import { cullGroundNodes } from './groundNodes';

import classes from './PanographViewer.module.scss';

export type RendererInstance = InstanceType<typeof Renderer>;

export interface SceneParams {
  annotations: Annotation[];
  floorplan: Floorplan;
  node: Node;
  walkthrough: Walkthrough;
}

class Renderer {
  camera: PerspectiveCamera;
  raycaster: Raycaster;
  renderer: WebGL1Renderer;
  scene: Scene;

  geometries: Record<string, BufferGeometry>;
  materials: {
    basic: Record<string, MeshBasicMaterial>;
    sprites: Record<string, SpriteMaterial>;
  };
  meshes: Record<string, Mesh>;
  objectGroups: Record<string, Group>;
  textureManager: TextureManager;

  annotations?: Annotation[];
  currentNode?: Node;
  floorplan?: Floorplan;
  nodes?: Node[];
  walkthrough?: Walkthrough;

  /** Camera target represented in Cartesian coordinates. Represents a point (x, y, z) on the sphere. */
  cameraTarget: Vector3;
  /**
   * Camera target represented in spherical coordinates. Represents a point (r, θ, φ) on the sphere of radius r. The
   * angles θ (pan, or the angle from +z on the x-z plane) and φ (tilt, or the angle from +y) are in radians.
   */
  cameraTargetSpherical: Spherical;
  canvasSize: Vector2;
  currentHoverIntersection?: Mesh | Sprite;
  currentNodeIndex: number;
  handlers: Record<string, (...args: any[]) => void>;
  keyboardControls: {
    lastMoveTimestamp: DOMHighResTimeStamp;
    lastPanTimestamp: DOMHighResTimeStamp;
    lookDirection?: NavigationDirection;
    moveAligned: boolean;
    moveAlignmentTarget?: Spherical;
    moveDirection?: NavigationDirection;
    moving: boolean;
    panDirection?: PanDirection;
    panning: boolean;
  };
  /**
   * When navigating between nodes using the vertical arrow keys, we load low-resolution imagery until the user stops
   * moving between nodes. Afterwards, we load the imagery in higher resolution. A reference to the loaded texture is
   * retained until it is rendered, camera panning is complete, and the user has not moved to a different node.
   */
  pendingUpscale?: {
    /** Node ID for which high-resolution imagery has been loaded. */
    nodeId: string;
    /** Texture reference waiting to be swapped into the scene. */
    texture: Texture;
  };
  /** State of the viewer's current pointer controls, including via touch devices. */
  pointerControls: {
    dragging: boolean;
    /** Initial drag position in pixel coordinates within the application's viewport. */
    dragStartPosition: Vector2;
    /** Initial camera position during a drag. */
    dragStartCameraSpherical: Spherical;
    /** Whether or not the user is picking a point for a new markup/annotation. */
    marking: boolean;
    /** Position of the user's pointer in normalized coordinates, i.e. with x and y values in (-1, 1). */
    position: Vector2;
  };
  /** While moving between ground nodes, the camera and sphere remain at the scene origin but still have a position in
   * the world. Use this to calculate the relative position of surrounding ground nodes. */
  worldPosition: Vector3;
  worldScale: Matrix4;
  meta: {
    animationFrameId?: number;
    interactive: boolean;
    needSnapshot: boolean;
    timestamp: DOMHighResTimeStamp;
  };

  /**
   * Create a new Renderer instance given an HTML Canvas element to draw into and an optional texture manager. If a
   * texture manager is not provided, it will be created.
   * @remark Note that the renderer is sized based on the parent element in order to account for the sidebar.
   */
  constructor({ canvas, textureManager }: { canvas: HTMLCanvasElement; textureManager?: TextureManager }) {
    if (!canvas) {
      throw new Error('Canvas element is falsey');
    }
    if (canvas.constructor?.name !== 'HTMLCanvasElement') {
      throw new Error('Canvas passed is not a HTMLCanvasElement');
    }

    this.camera = new PerspectiveCamera(CameraConstants.INITIAL_FOV, window.innerWidth / window.innerHeight, 1, 1000);
    this.canvasSize = new Vector2(
      canvas.parentElement?.offsetWidth || window.innerWidth,
      canvas.parentElement?.offsetHeight || window.innerHeight
    );
    this.raycaster = new Raycaster();
    this.renderer = new WebGL1Renderer({ alpha: true, canvas });
    this.renderer.outputColorSpace = LinearSRGBColorSpace;
    this.renderer.setPixelRatio(Math.max(window.devicePixelRatio, 2));
    this.renderer.setSize(this.canvasSize.x, this.canvasSize.y);
    this.scene = new Scene();

    // Since meshes can share a geometry, create a single geometry all ground nodes. Annotations are three.js sprites
    // and do not need geometries.
    const invertMatrix = new Matrix4().makeScale(-1, 1, 1);
    this.geometries = {
      groundNode: new CylinderGeometry(1, 1, 0),
      seamCover: new CylinderGeometry(8, 8, 0, 128),
      sphere: new SphereGeometry(GeometryConstants.SPHERE_RADIUS, 128, 128).applyMatrix4(invertMatrix),
      transitionSphere: new SphereGeometry(GeometryConstants.SPHERE_RADIUS + 2, 128, 128).applyMatrix4(invertMatrix),
    };
    // Most of the materials below will have a `map` property set later when textures finish loading.
    this.materials = {
      basic: {
        groundNodeAnnotated: new MeshBasicMaterial({ transparent: true, opacity: 0.5 }),
        groundNodeAnnotatedHover: new MeshBasicMaterial({ transparent: true, opacity: 1 }),
        groundNodeHover: new MeshBasicMaterial({ transparent: true, opacity: 1 }),
        groundNode: new MeshBasicMaterial({ transparent: true, opacity: 0.5 }),
        seamCover: new MeshBasicMaterial({ color: 0xefefef, transparent: true, opacity: 0.95 }),
        sphere: new MeshBasicMaterial({ opacity: 0, transparent: true }),
        transitionSphere: new MeshBasicMaterial({ transparent: false }),
      },
      sprites: {
        annotation: new SpriteMaterial({ transparent: true, opacity: 0.5 }),
        annotationHover: new SpriteMaterial({ transparent: true, opacity: 1 }),
      },
    };
    this.meshes = {
      seamCover: new Mesh(this.geometries.seamCover, this.materials.basic.seamCover),
      sphere: new Mesh(this.geometries.sphere, this.materials.basic.sphere),
      transitionSphere: new Mesh(this.geometries.transitionSphere, this.materials.basic.transitionSphere),
    };
    this.objectGroups = {
      annotations: new Group(),
      groundNodes: new Group(),
    };
    this.textureManager = textureManager ?? new TextureManager();
    this.textureManager.setSceneTextureQuality(this.renderer.capabilities.maxTextureSize);

    this.cameraTargetSpherical = new Spherical(GeometryConstants.SPHERE_RADIUS, Math.PI * 0.5, 0);
    this.cameraTarget = new Vector3(GeometryConstants.SPHERE_RADIUS, 0, 0);
    this.currentNodeIndex = -1;
    this.handlers = {};
    this.keyboardControls = {
      lastMoveTimestamp: 0,
      lastPanTimestamp: 0,
      lookDirection: undefined,
      moveAligned: false,
      moveAlignmentTarget: undefined,
      moveDirection: undefined,
      moving: false,
      panDirection: undefined,
      panning: false,
    };
    this.pendingUpscale = undefined;
    this.pointerControls = {
      dragging: false,
      dragStartPosition: new Vector2(),
      dragStartCameraSpherical: new Spherical(),
      marking: false,
      position: new Vector2(),
    };
    this.worldPosition = new Vector3();
    this.worldScale = new Matrix4();
    this.meta = {
      animationFrameId: undefined,
      interactive: true,
      needSnapshot: false,
      timestamp: 0,
    };
  }

  /**
   * Before each annotation renders, determine if its material needs to change. The sprite should use a more opaque
   * texture if the user's pointer is currently hovering over it.
   */
  private beforeAnnotationRender = (sprite: Sprite) => {
    if (sprite === this.currentHoverIntersection && sprite.material !== this.materials.sprites.annotationHover) {
      sprite.material = this.materials.sprites.annotationHover;
    }
    if (sprite !== this.currentHoverIntersection && sprite.material !== this.materials.sprites.annotation) {
      sprite.material = this.materials.sprites.annotation;
    }
  };

  /**
   * Before each ground node renders, determine if its material needs to change. Ground nodes need materials with
   * different texture maps depending on whether or not they are annotated and/or are the current hover target.
   */
  private beforeGroundNodeRender = (mesh: Mesh) => {
    if (
      mesh === this.currentHoverIntersection &&
      mesh.userData.isAnnotated &&
      mesh.material !== this.materials.basic.groundNodeAnnotatedHover
    ) {
      mesh.material = this.materials.basic.groundNodeAnnotatedHover;
    }
    if (
      mesh === this.currentHoverIntersection &&
      !mesh.userData.isAnnotated &&
      mesh.material !== this.materials.basic.groundNodeHover
    ) {
      mesh.material = this.materials.basic.groundNodeHover;
    }

    if (
      mesh !== this.currentHoverIntersection &&
      mesh.userData.isAnnotated &&
      mesh.material !== this.materials.basic.groundNodeAnnotated
    ) {
      mesh.material = this.materials.basic.groundNodeAnnotated;
    }
    if (
      mesh !== this.currentHoverIntersection &&
      !mesh.userData.isAnnotated &&
      mesh.material !== this.materials.basic.groundNode
    ) {
      mesh.material = this.materials.basic.groundNode;
    }
  };

  /**
   * Clear the reference to the currently hovered mesh, if one exists. Set the pointer position to the far top-left
   * corner of the screen to prevent phantom intersections, for example when keyboard left/right arrow key pans end.
   */
  private clearHoverIntersection() {
    if (!this.currentHoverIntersection) {
      return;
    }

    this.currentHoverIntersection = undefined;
    this.pointerControls.position.x = -1;
    this.pointerControls.position.y = 1;
    this.renderer?.domElement.classList.remove(classes.cursorPointer);
  }

  /**
   * Dispose of all scene assets and remove any attached event listeners.
   */
  dispose = () => {
    this.stopRendering();

    window.removeEventListener('resize', this.onWindowResize);
    this.renderer.domElement.removeEventListener('keydown', this.onHorizontalArrowKeyDown);
    this.renderer.domElement.removeEventListener('keydown', this.onVerticalArrowKeyDown);
    this.renderer.domElement.removeEventListener('keyup', this.onHorizontalArrowKeyUp);
    this.renderer.domElement.removeEventListener('keyup', this.onVerticalArrowKeyUp);
    this.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
    this.renderer.domElement.removeEventListener('pointermove', this.onPointerMove);
    this.renderer.domElement.removeEventListener('wheel', this.onPointerWheelThrottled);
    this.handlers = {};

    this.clearHoverIntersection();

    for (const child of [...this.objectGroups.annotations.children, ...this.objectGroups.groundNodes.children]) {
      child.removeFromParent();
    }
    this.objectGroups.annotations.removeFromParent();
    this.objectGroups.groundNodes.removeFromParent();
    this.objectGroups = {};
    this.meshes.seamCover.removeFromParent();
    this.meshes.sphere.removeFromParent();
    this.meshes.transitionSphere.removeFromParent();
    this.meshes = {};

    for (const geometry of Object.values(this.geometries)) {
      geometry.dispose();
    }
    for (const material of [...Object.values(this.materials.basic), ...Object.values(this.materials.sprites)]) {
      material.dispose();
    }
    this.geometries = {};
    this.materials = { basic: {}, sprites: {} };

    this.textureManager.clearSceneBaseUrl();
    this.textureManager.clearSceneTextures();

    this.renderer.dispose();
  };

  /**
   * Retrieve the angle on the x-z plane between two parameter nodes.
   */
  private getAngleBetweenNodes = (startNode: Node, endNode: Node) => {
    let sourcePosition;
    if (startNode === this.currentNode) {
      sourcePosition = this.worldPosition;
    } else {
      sourcePosition = new Vector3(startNode.xpercent, 0, startNode.ypercent).applyMatrix4(this.worldScale);
    }

    const relativePosition = new Vector3(endNode.xpercent, 0, endNode.ypercent)
      .applyMatrix4(this.worldScale)
      .sub(sourcePosition);

    // Since theta is measured from +z, tan(theta) = x/z => theta = arctan(x/z).
    return Math.atan2(relativePosition.x, relativePosition.z);
  };

  /**
   * Given the direction pressed on the controls and the direction in which the camera is looking relative to the
   * walkthrough, determine the best movement direction.
   */
  private static getMoveDirection = (
    keyDirection?: NavigationDirection,
    lookDirection?: NavigationDirection
  ): NavigationDirection | undefined => {
    if (!keyDirection || !lookDirection) {
      return undefined;
    }

    if (keyDirection === NavigationDirection.FORWARD && lookDirection === NavigationDirection.FORWARD) {
      return NavigationDirection.FORWARD;
    } else if (keyDirection === NavigationDirection.FORWARD && lookDirection === NavigationDirection.BACKWARD) {
      return NavigationDirection.BACKWARD;
    } else if (keyDirection === NavigationDirection.BACKWARD && lookDirection === NavigationDirection.FORWARD) {
      return NavigationDirection.BACKWARD;
    } else {
      return NavigationDirection.FORWARD;
    }
  };

  /**
   * Get the pan angle required to face the next few nodes in the specified walkthrough direction (forward = towards
   * nodes captured later, backward = nodes captured earlier).
   * @remark We compute the *average* pan direction to reduce camera shake.
   */
  private getRequiredPanToNextNode = (direction?: NavigationDirection) => {
    if (!this.nodes) {
      throw new Error('Attempted to face walkthrough direction with incomplete scene data');
    }
    if (!direction || !this.currentNode) {
      return 0;
    }

    let startIndex;
    let endIndex;
    if (direction === NavigationDirection.BACKWARD) {
      startIndex = Math.max(0, this.currentNodeIndex - PerformanceConstants.AVERAGE_ANGLE_INCLUDE_FORWARD);
      endIndex = Math.min(
        this.nodes.length - 1,
        this.currentNodeIndex + PerformanceConstants.AVERAGE_ANGLE_INCLUDE_BACKWARD
      );
    } else {
      startIndex = Math.max(0, this.currentNodeIndex - PerformanceConstants.AVERAGE_ANGLE_INCLUDE_BACKWARD);
      endIndex = Math.min(
        this.nodes.length - 1,
        this.currentNodeIndex + PerformanceConstants.AVERAGE_ANGLE_INCLUDE_FORWARD
      );
    }
    if (startIndex === endIndex) {
      return 0;
    }

    let cumulativeAngleDiff = 0;
    for (let i = startIndex; i < endIndex; i++) {
      const node = this.nodes[i + 1];
      let angleDiff;
      if (direction === NavigationDirection.BACKWARD) {
        angleDiff =
          i >= this.currentNodeIndex
            ? this.getAngleBetweenNodes(node, this.currentNode)
            : this.getAngleBetweenNodes(this.currentNode, node);
      } else {
        angleDiff =
          i < this.currentNodeIndex
            ? this.getAngleBetweenNodes(node, this.currentNode)
            : this.getAngleBetweenNodes(this.currentNode, node);
      }
      const requiredPan = GeometryHelper.getPanAngle(this.cameraTargetSpherical.theta, angleDiff);
      cumulativeAngleDiff += requiredPan;
    }

    return cumulativeAngleDiff / (endIndex - startIndex);
  };

  /**
   * Walkthrough nodes are sorted by their `index` property indicating their order within the walkthrough. Retrieve the
   * direction of the camera with respect to the walkthrough; forward represents that the camera is facing closer to
   * node i+1, backward represents that the camera is facing closer to node i-1.
   */
  private getWalkthroughLookDirection = () => {
    if (!this.currentNode || !this.nodes) {
      throw new Error('Attempted to get camera direction relative to walkthrough with incomplete scene data');
    }

    const nextWalkthroughNode = this.nodes[this.currentNodeIndex + 1];
    const prevWalkthroughNode = this.nodes[this.currentNodeIndex - 1];

    if (!nextWalkthroughNode && !prevWalkthroughNode) {
      return undefined;
    }

    let panAngleToNext;
    let panAngleToPrev;
    if (!prevWalkthroughNode && nextWalkthroughNode) {
      panAngleToNext = GeometryHelper.getPanAngle(
        this.cameraTargetSpherical.theta,
        this.getAngleBetweenNodes(this.currentNode, nextWalkthroughNode)
      );
      return Math.abs(panAngleToNext) <= Math.PI * 0.5 ? NavigationDirection.FORWARD : NavigationDirection.BACKWARD;
    } else if (prevWalkthroughNode && !nextWalkthroughNode) {
      panAngleToPrev = GeometryHelper.getPanAngle(
        this.cameraTargetSpherical.theta,
        this.getAngleBetweenNodes(this.currentNode, prevWalkthroughNode)
      );
      return Math.abs(panAngleToPrev) <= Math.PI * 0.5 ? NavigationDirection.BACKWARD : NavigationDirection.FORWARD;
    }

    panAngleToNext = GeometryHelper.getPanAngle(
      this.cameraTargetSpherical.theta,
      this.getAngleBetweenNodes(this.currentNode, nextWalkthroughNode)
    );
    panAngleToPrev = GeometryHelper.getPanAngle(
      this.cameraTargetSpherical.theta,
      this.getAngleBetweenNodes(this.currentNode, prevWalkthroughNode)
    );
    return Math.abs(panAngleToNext) <= Math.abs(panAngleToPrev)
      ? NavigationDirection.FORWARD
      : NavigationDirection.BACKWARD;
  };

  /**
   * Load all required textures and build the scene.
   */
  initialize = async () => {
    const [annotationTexture, groundNodeAnnotatedTexture, groundNodeTexture, seamCoverTexture] = await Promise.all([
      this.textureManager.loadGlobalTexture(GlobalTextureNames.ANNOTATION, '/resources/texture-annotation.png'),
      this.textureManager.loadGlobalTexture(
        GlobalTextureNames.GROUND_NODE_ANNOTATED,
        '/resources/texture-ground-node-annotated.png'
      ),
      this.textureManager.loadGlobalTexture(GlobalTextureNames.GROUND_NODE, '/resources/texture-ground-node.png'),
      this.textureManager.loadGlobalTexture(GlobalTextureNames.SEAM_COVER, '/resources/texture-seam-cover.png'),
    ]);

    this.materials.basic.groundNodeAnnotatedHover.map = groundNodeAnnotatedTexture;
    this.materials.basic.groundNodeAnnotated.map = groundNodeAnnotatedTexture;
    this.materials.basic.groundNodeHover.map = groundNodeTexture;
    this.materials.basic.groundNode.map = groundNodeTexture;
    this.materials.basic.seamCover.map = seamCoverTexture;
    this.materials.sprites.annotation.map = annotationTexture;
    this.materials.sprites.annotationHover.map = annotationTexture;

    this.meshes.seamCover.position.set(0, -2.5 * GeometryConstants.CAMERA_HEIGHT + 2, 0);

    this.scene.add(this.objectGroups.annotations);
    this.scene.add(this.objectGroups.groundNodes);
    this.scene.add(this.meshes.seamCover);
    this.scene.add(this.meshes.sphere);

    window.addEventListener('resize', this.onWindowResize);
    this.renderer.domElement.addEventListener('keydown', this.onHorizontalArrowKeyDown);
    this.renderer.domElement.addEventListener('keydown', this.onVerticalArrowKeyDown);
    this.renderer.domElement.addEventListener('keyup', this.onHorizontalArrowKeyUp);
    this.renderer.domElement.addEventListener('keyup', this.onVerticalArrowKeyUp);
    this.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
    this.renderer.domElement.addEventListener('pointermove', this.onPointerMove);
    this.renderer.domElement.addEventListener('wheel', this.onPointerWheelThrottled);
  };

  /**
   * Load the preview (i.e. lowest-resolution) textures adjacent to the current node. We define "adjacent" to mean
   * within MAX_PREVIEW_TEXTURE_PRELOAD_COUNT nodes of the current within the walkthrough's node order.
   */
  private loadAdjacentPreviewTextures = () => {
    if (!this.nodes) {
      throw new Error('Scene data missing');
    }

    const preloadIndexMin = Math.max(0, this.currentNodeIndex - PerformanceConstants.MAX_PREVIEW_TEXTURE_PRELOAD_COUNT);
    const preloadIndexMax = Math.min(
      this.currentNodeIndex + PerformanceConstants.MAX_PREVIEW_TEXTURE_PRELOAD_COUNT,
      this.nodes.length - 1
    );

    let nodeIndex = preloadIndexMin;
    while (nodeIndex <= preloadIndexMax) {
      this.loadPreviewTexture(nodeIndex);
      nodeIndex++;
    }
  };

  /**
   * Load the preview (i.e. lowest-resolution) texture for the node given by the specified index. If the texture load
   * has already been initiated, stop.
   */
  private loadPreviewTexture = (nodeIndex: number) => {
    const node = this.nodes?.[nodeIndex];
    if (!node) {
      throw new Error('Cannot preload texture: missing node');
    }

    const loadingStatus = this.textureManager.getSceneTextureLoadingStatus(node.mosaic, TextureQuality.PREVIEW);
    if (loadingStatus === TextureLoadingStatus.NOT_STARTED) {
      (async () => {
        try {
          await this.textureManager.loadSceneTexture(node.mosaic, TextureQuality.PREVIEW);
        } catch (error) {
          console.error('[Renderer] Failed to load preview texture', error);
          this.handlers[ViewerEvents.ON_ERROR]?.();
        }
      })();
    }
  };

  /**
   * Upscale the current node's texture to high quality on the next available opportunity.
   */
  private loadHighResolutionTexture = async (nodeIndex: number) => {
    const node = this.nodes?.[nodeIndex];
    if (!node) {
      throw new Error('Node not defined');
    }

    try {
      const nextTexture = await this.textureManager.loadSceneTexture(node.mosaic, TextureQuality.MAX);

      // Defer the upscale until the viewer is ready.
      this.pendingUpscale = { nodeId: node.id, texture: nextTexture };
    } catch (error) {
      console.error('[Renderer] Failed to upscale texture', error);
      this.handlers[ViewerEvents.ON_ERROR]?.();
    }
  };

  /**
   * Load the walkthrough scene given a set of parameters including the walkthrough, current node, and floorplan
   * dimensions. If previous walkthrough assets have already been rendered, clear them from the scene and the texture
   * cache.
   */
  loadWalkthroughScene = async ({ annotations, floorplan, node, walkthrough }: SceneParams) => {
    this.handlers[ViewerEvents.ON_SCENE_UPDATE_START]?.(ViewerUpdateSource.SCENE_LOAD);

    this.annotations = annotations;
    this.currentNode = node;
    this.floorplan = floorplan;
    this.nodes = sortBy(walkthrough.nodes, 'mosaic');
    this.walkthrough = walkthrough;

    this.clearHoverIntersection();
    this.textureManager.clearSceneTextures();
    for (const child of [...this.objectGroups.annotations.children, ...this.objectGroups.groundNodes.children]) {
      child.removeFromParent();
    }

    try {
      this.textureManager.setSceneBaseUrl(walkthrough.texture_path);
      const currentNodeTexture = await this.textureManager.loadSceneTexture(node.mosaic, TextureQuality.MAX);

      this.materials.basic.sphere.map = currentNodeTexture;
      this.materials.basic.sphere.transparent = false;
      this.materials.basic.sphere.needsUpdate = true;

      this.worldScale.makeScale(
        floorplan.image_width * floorplan.scale_factor,
        1,
        floorplan.image_height * floorplan.scale_factor
      );
      this.currentNodeIndex = this.nodes.findIndex(({ id }) => id === node.id);
      this.worldPosition
        .set(node.xpercent, GeometryConstants.CAMERA_HEIGHT, node.ypercent)
        .applyMatrix4(this.worldScale);

      this.loadAdjacentPreviewTextures();
      this.updateAnnotations();
      this.updateGroundNodes();

      this.renderer.domElement.focus();
      this.handlers[ViewerEvents.ON_SCENE_UPDATE_COMPLETE]?.();
    } catch (error) {
      console.error('[Renderer] Failed to load texture while building scene', error);
      this.handlers[ViewerEvents.ON_ERROR]?.();
    }
  };

  /**
   * Look at some point (x, y, z) on the sphere which encloses the scene. Animate the transition.
   */
  lookAt = (x: number, y: number, z: number) => {
    const initialPhi = this.cameraTargetSpherical.phi;
    const targetPhi = Math.acos(y / GeometryConstants.SPHERE_RADIUS);
    const panAnglePhi = targetPhi - this.cameraTargetSpherical.phi;

    const initialTheta = this.cameraTargetSpherical.theta;
    const targetTheta = Math.atan2(x, z);
    const panAngleTheta = GeometryHelper.getPanAngle(this.cameraTargetSpherical.theta, targetTheta);

    animate(0, 100, {
      duration: TimingConstants.LOOK_TRANSITION_DURATION,
      easings: ['easeIn'],
      onUpdate: (increment) => {
        this.cameraTargetSpherical.phi = initialPhi + (panAnglePhi * increment) / 100;
        this.cameraTargetSpherical.theta = initialTheta + (panAngleTheta * increment) / 100;

        this.updateCameraOrientation();
        this.meta.interactive = false;
      },
      onComplete: () => {
        this.handlers[ViewerEvents.ON_LOOK_TRANSITION_COMPLETE]?.();
        this.meta.interactive = true;
      },
    });
  };

  /**
   * Move to the next node along the walkthrough's path, assuming some keyboard navigation direction has been set. If we
   * have reached the start (going backward) or end (going forward) of the path, reverse the direction. Next, check the
   * camera's orientation to see if we need to pan and/or tilt to face the next node then adjust as necessary. Once the
   * next texture is available at preview (i.e. lowest) quality, show it and face the next node.
   */
  private moveToNextNode = () => {
    if (!this.nodes) {
      throw new Error('Attempted to move with incomplete scene data');
    }
    if (
      !this.keyboardControls.moving ||
      !this.keyboardControls.lookDirection ||
      !this.keyboardControls.moveDirection ||
      this.meta.timestamp - this.keyboardControls.lastMoveTimestamp < TimingConstants.RATE_LIMIT_KEYBOARD_NAVIGATION
    ) {
      return;
    }

    let node = this.nodes[this.currentNodeIndex + this.keyboardControls.moveDirection];
    if (!node) {
      // Reverse navigation direction. Assuming the initial keydown event handler ruled out the single-node walkthrough
      // case, we're probably just at either the end or beginning of a walk.
      this.keyboardControls.lookDirection *= -1;
      this.keyboardControls.moveDirection *= -1;
      node = this.nodes[this.currentNodeIndex + this.keyboardControls.moveDirection];
    }

    if (!this.keyboardControls.moveAligned && !this.keyboardControls.moveAlignmentTarget) {
      const requiredPanPhi = GeometryHelper.getPanAngle(this.cameraTargetSpherical.phi, Math.PI * 0.5);
      const requiredPanTheta = this.getRequiredPanToNextNode(this.keyboardControls.lookDirection);

      this.keyboardControls.moveAligned =
        Math.abs(requiredPanPhi) < ControlConstants.MOVE_ANGLE_TOLERANCE &&
        Math.abs(requiredPanTheta) < ControlConstants.MOVE_ANGLE_TOLERANCE;
      this.keyboardControls.moveAlignmentTarget = new Spherical(
        GeometryConstants.SPHERE_RADIUS,
        requiredPanPhi,
        requiredPanTheta
      );
    }

    if (!this.keyboardControls.moveAligned && this.keyboardControls.moveAlignmentTarget) {
      const tiltIncrement =
        this.keyboardControls.moveAlignmentTarget.phi * ControlConstants.MOVE_ANGLE_CORRECTION_DECAY;
      const panIncrement =
        this.keyboardControls.moveAlignmentTarget.theta * ControlConstants.MOVE_ANGLE_CORRECTION_DECAY;

      this.cameraTargetSpherical.phi += tiltIncrement;
      this.cameraTargetSpherical.theta += panIncrement;
      this.updateCameraOrientation();

      this.keyboardControls.lastPanTimestamp = performance.now();
      this.keyboardControls.moveAligned =
        Math.abs(tiltIncrement) < ControlConstants.MOVE_ANGLE_TOLERANCE &&
        Math.abs(panIncrement) < ControlConstants.MOVE_ANGLE_TOLERANCE;
      this.keyboardControls.moveAlignmentTarget.phi -= tiltIncrement;
      this.keyboardControls.moveAlignmentTarget.theta -= panIncrement;
      return;
    }

    const loadingStatus = this.textureManager.getSceneTextureLoadingStatus(node.mosaic, TextureQuality.PREVIEW);
    switch (loadingStatus) {
      case TextureLoadingStatus.LOADED:
        break;
      case TextureLoadingStatus.NOT_STARTED:
        (async () => {
          try {
            await this.textureManager.loadSceneTexture(node.mosaic, TextureQuality.PREVIEW);
          } catch (error) {
            console.error('[Renderer] Failed to load preview texture during move to next node', error);
            this.handlers[ViewerEvents.ON_ERROR]?.();
          }
        })();
        return;
      default:
        return;
    }

    const nextTexture = this.textureManager.getSceneTexture(node.mosaic, TextureQuality.PREVIEW);
    this.materials.basic.sphere.map = nextTexture;

    this.currentNode = node;
    this.currentNodeIndex = this.currentNodeIndex + this.keyboardControls.moveDirection;
    this.worldPosition.set(node.xpercent, GeometryConstants.CAMERA_HEIGHT, node.ypercent).applyMatrix4(this.worldScale);

    this.keyboardControls.lastMoveTimestamp = performance.now();
    this.handlers[ViewerEvents.ON_CHANGE_NODE]?.(node);

    // Ensure images are loaded within a distance of MAX_PREVIEW_TEXTURE_PRELOAD_COUNT from the current.
    const preloadIndex =
      this.currentNodeIndex +
      this.keyboardControls.moveDirection * PerformanceConstants.MAX_PREVIEW_TEXTURE_PRELOAD_COUNT;
    if (preloadIndex >= 0 && preloadIndex < this.nodes.length) {
      this.loadPreviewTexture(preloadIndex);
    }

    this.cameraTargetSpherical.theta += this.getRequiredPanToNextNode(this.keyboardControls.lookDirection);
    this.updateCameraOrientation();
  };

  /**
   * Transition to a given walkthrough node. Assumes the scene is already loaded.
   * @remark Since keyboard arrow key navigation causes the URL to change, this function may be called by the parent in
   * response to the URL change. To mitigate redundant invocations, when keyboard navigation is active, do nothing.
   */
  moveToNode = async (node: Node, annotations: Annotation[]) => {
    if (this.keyboardControls.moving || node.id === this.currentNode?.id) {
      return;
    }
    if (!this.currentNode || !this.nodes) {
      throw new Error('Attempted to move to node with incomplete scene data');
    }

    this.handlers[ViewerEvents.ON_SCENE_UPDATE_START]?.(ViewerUpdateSource.GROUND_NODE_SELECT);

    this.materials.basic.transitionSphere.map = this.materials.basic.sphere.map;
    this.scene.add(this.meshes.transitionSphere);

    this.materials.basic.sphere.opacity = 0;
    this.materials.basic.sphere.transparent = true;
    this.materials.basic.sphere.needsUpdate = true;
    this.meta.interactive = false;

    try {
      const nextTexture = await this.textureManager.loadSceneTexture(node.mosaic, TextureQuality.MAX);
      this.materials.basic.sphere.map = nextTexture;
    } catch (error) {
      console.error('[Renderer] Failed to load texture on move to node', error);
      this.handlers[ViewerEvents.ON_ERROR]?.();
      return;
    }

    this.annotations = annotations;
    this.currentNode = node;
    this.currentNodeIndex = this.nodes.findIndex(({ id }) => id === node.id);
    this.worldPosition.set(node.xpercent, GeometryConstants.CAMERA_HEIGHT, node.ypercent).applyMatrix4(this.worldScale);

    this.loadAdjacentPreviewTextures();
    this.objectGroups.annotations.removeFromParent();
    this.objectGroups.groundNodes.removeFromParent();

    animate(0, 1, {
      duration: TimingConstants.NODE_TRANSITION_FADE_DURATION,
      easings: ['easeIn'],
      onUpdate: (nextOpacity) => {
        this.materials.basic.sphere.opacity = nextOpacity;
      },
      onComplete: () => {
        this.materials.basic.sphere.transparent = false;
        this.materials.basic.sphere.needsUpdate = true;
        this.meshes.transitionSphere.removeFromParent();

        this.updateAnnotations();
        this.updateGroundNodes();
        this.scene.add(this.objectGroups.annotations);
        this.scene.add(this.objectGroups.groundNodes);
        this.meta.interactive = true;

        this.handlers[ViewerEvents.ON_SCENE_UPDATE_COMPLETE]?.();
      },
    });
  };

  /**
   * When the user presses either the left or right arrow key, set the pan direction accordingly.
   */
  private onHorizontalArrowKeyDown = (event: KeyboardEvent) => {
    if (
      (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') ||
      this.keyboardControls.moving ||
      this.keyboardControls.panning ||
      !this.meta.interactive
    ) {
      return;
    }

    this.keyboardControls.panDirection = event.key === 'ArrowLeft' ? PanDirection.LEFT : PanDirection.RIGHT;
    this.keyboardControls.panning = true;
    this.meta.interactive = false;

    this.clearHoverIntersection();
  };

  /**
   * When the user releases either the left or right arrow key, clear the pan direction.
   */
  private onHorizontalArrowKeyUp = (event: KeyboardEvent) => {
    if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      return;
    }

    this.cameraTargetSpherical.theta = this.cameraTargetSpherical.theta % (2 * Math.PI);

    this.keyboardControls.panDirection = undefined;
    this.keyboardControls.panning = false;
    this.meta.interactive = true;
  };

  /**
   * When the user presses either the up or down arrow key, set the navigation direction accordingly.
   */
  private onVerticalArrowKeyDown = (event: KeyboardEvent) => {
    if (
      (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') ||
      this.keyboardControls.moving ||
      this.keyboardControls.panning ||
      !this.meta.interactive
    ) {
      return;
    }
    if ((this.nodes?.length ?? 0) <= 1) {
      return;
    }

    const keyDirection = event.key === 'ArrowUp' ? NavigationDirection.FORWARD : NavigationDirection.BACKWARD;
    const lookDirection = this.getWalkthroughLookDirection();
    const moveDirection = Renderer.getMoveDirection(keyDirection, lookDirection);
    if (!moveDirection) {
      return;
    }

    this.handlers[ViewerEvents.ON_SCENE_UPDATE_START]?.(ViewerUpdateSource.KEYBOARD_NAVIGATION);

    this.keyboardControls.moving = true;
    this.keyboardControls.lookDirection = lookDirection;
    this.keyboardControls.moveAligned = false;
    this.keyboardControls.moveAlignmentTarget = undefined;
    this.keyboardControls.moveDirection = moveDirection;
    this.meta.interactive = false;

    this.clearHoverIntersection();
    this.objectGroups.annotations.removeFromParent();
    this.objectGroups.groundNodes.removeFromParent();
  };

  /**
   * When the user releases either the up or down arrow key, clear the navigation direction. Add the ground nodes and
   * annotations back to the scene. Upscale the current texture if needed.
   */
  private onVerticalArrowKeyUp = (event: KeyboardEvent) => {
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
      return;
    }
    if (!this.keyboardControls.moving) {
      return;
    }

    this.keyboardControls.moving = false;
    this.meta.interactive = true;

    this.updateAnnotations();
    this.updateGroundNodes();
    this.scene.add(this.objectGroups.annotations);
    this.scene.add(this.objectGroups.groundNodes);
    this.handlers[ViewerEvents.ON_SCENE_UPDATE_COMPLETE]?.();

    this.loadHighResolutionTexture(this.currentNodeIndex);
  };

  /**
   * When the user begins a pointer click gesture, record the starting location in case the movement is a drag. Add
   * event listeners to respond to pointer movement during the drag as well as button release.
   * @remark If the pointer is a mouse, only left and middle button clicks start a drag.
   */
  private onPointerDown = (event: PointerEvent) => {
    if (!event.isPrimary || event.button > 1 || !this.meta.interactive) {
      return;
    }

    this.pointerControls.dragStartPosition.x = event.clientX;
    this.pointerControls.dragStartPosition.y = event.clientY;
    this.pointerControls.dragStartCameraSpherical.copy(this.cameraTargetSpherical);

    window.addEventListener('pointercancel', this.onPointerCancel, { once: true });
    window.addEventListener('pointermove', this.onPointerDragMove);
    window.addEventListener('pointerup', this.onPointerUp, { once: true });
  };

  /**
   * When the user clicks the left or middle pointer button then moves the pointer, check to see if the moved distance
   * has exceeded the threshold (comparing values without evaluating square roots for efficiency). If so, the movement
   * was a drag and not a click.
   * @remark Latitude and longitude are positions; phi and theta are angles in radians.
   * @see https://stackoverflow.com/questions/37239710/detecting-clicks-versus-drags-on-an-html-5-canvas
   */
  private onPointerDragMove = (event: PointerEvent) => {
    const dx = event.clientX - this.pointerControls.dragStartPosition.x;
    const dy = this.pointerControls.dragStartPosition.y - event.clientY;
    if (!this.pointerControls.dragging) {
      if (Math.pow(dx, 2) + Math.pow(dy, 2) > Math.pow(GeometryConstants.MAX_CLICK_DISTANCE, 2)) {
        this.pointerControls.dragging = true;
        this.clearHoverIntersection();
      }
    }

    // For every pixel moved, pan or tilt the camera ±0.1 degrees.
    this.cameraTargetSpherical.phi = MathUtils.clamp(
      dy * 0.1 * MathUtils.DEG2RAD + this.pointerControls.dragStartCameraSpherical.phi,
      CameraConstants.MIN_PHI,
      CameraConstants.MAX_PHI
    );
    this.cameraTargetSpherical.theta =
      (dx * 0.1 * MathUtils.DEG2RAD + this.pointerControls.dragStartCameraSpherical.theta) % (Math.PI * 2);
    this.updateCameraOrientation();
  };

  /**
   * If the pointer event is canceled, stop handling move and pointer up events. Stop any in-progress drags.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/pointercancel_event
   */
  private onPointerCancel = (_: PointerEvent) => {
    window.removeEventListener('pointermove', this.onPointerDragMove);
    window.removeEventListener('pointerup', this.onPointerUp);

    if (this.pointerControls.dragging) {
      this.pointerControls.dragging = false;
    }
  };

  /**
   * When the user releases their pointer, stop firing the drag move event. If the event was not raised during a drag,
   * calculate the click position in normalized device coordinates (i.e. values ranging from -1 to +1). Cast a ray to
   * determine if the click has intersected with any ground nodes. Inspecting only the first intersected object
   * (stopping if none), check the user data populated on the ground node's Object3D.
   * @see https://threejs.org/docs/#api/en/core/Raycaster
   */
  private onPointerUp = (event: PointerEvent) => {
    window.removeEventListener('pointercancel', this.onPointerCancel);
    window.removeEventListener('pointermove', this.onPointerDragMove);

    // If the click was raised while the renderer is not interactive, stop.
    if (!this.meta.interactive) {
      return;
    }
    // If this event was raised as the result of pointer button release during a drag, stop.
    if (this.pointerControls.dragging) {
      this.pointerControls.dragging = false;
      return;
    }

    // On touch devices, the pointer's position will not have been continuously updated on move, so set it now.
    if (event.pointerType === 'touch') {
      this.pointerControls.position.x = (event.offsetX / this.canvasSize.x) * 2 - 1;
      this.pointerControls.position.y = -1 * (event.offsetY / this.canvasSize.y) * 2 + 1;
    }

    this.raycaster.setFromCamera(this.pointerControls.position, this.camera);

    if (this.pointerControls.marking) {
      const [sphereIntersection] = this.raycaster.intersectObject(this.meshes.sphere, false);
      if (sphereIntersection) {
        this.handlers[ViewerEvents.ON_MARK_POINT]?.(sphereIntersection.point);
      }
      return;
    }

    // Check for ground node or annotation intersections.
    const [intersection] = this.raycaster.intersectObjects([
      ...this.objectGroups.annotations.children,
      ...this.objectGroups.groundNodes.children,
    ]);
    if (!intersection) {
      return;
    }

    if (intersection.object.userData.annotation) {
      this.handlers[ViewerEvents.ON_CLICK_ANNOTATION]?.(intersection.object.userData.annotation);
    } else if (intersection.object.userData.node) {
      this.handlers[ViewerEvents.ON_CHANGE_NODE]?.(intersection.object.userData.node);
    }
  };

  /**
   * When the user moves the pointer over the canvas, update its position. Since the canvas may actually be smaller than
   * the device width, use the pre-computed canvas dimensions.
   */
  private onPointerMove = (event: PointerEvent) => {
    this.pointerControls.position.x = (event.offsetX / this.canvasSize.x) * 2 - 1;
    this.pointerControls.position.y = -1 * (event.offsetY / this.canvasSize.y) * 2 + 1;
  };

  /**
   * When the user rotates the pointer wheel, zoom the camera in or out by modifying its field of view.
   */
  private onPointerWheel = (event: WheelEvent) => {
    const fieldOfViewDiff = event.deltaY * 0.2;
    this.handlers[ViewerEvents.ON_ZOOM]?.(fieldOfViewDiff);
  };

  private onPointerWheelThrottled = throttle(this.onPointerWheel, TimingConstants.RATE_LIMIT_POINTER_WHEEL);

  /**
   * When the window is resized, capture the new dimensions of the canvas and update the camera.
   */
  private onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.canvasSize.x = this.renderer.domElement.parentElement?.offsetWidth || window.innerWidth;
    this.canvasSize.y = this.renderer.domElement.parentElement?.offsetHeight || window.innerHeight;
    this.renderer.setSize(this.canvasSize.x, this.canvasSize.y);
  };

  /**
   * If the keyboard controls are currently set to be panning in a given direction, update the camera angle by a small
   * increment. When panning, this is called multiple times per second for smoothness.
   */
  private panCamera = () => {
    if (!this.keyboardControls.panning || !this.keyboardControls.panDirection) {
      return;
    }

    for (let i = 0; i < ControlConstants.KEYBOARD_PAN_STEPS_PER_FRAME; i++) {
      this.cameraTargetSpherical.theta += this.keyboardControls.panDirection * ControlConstants.KEYBOARD_PAN_INCREMENT;
      this.keyboardControls.lastPanTimestamp = performance.now();
      this.updateCameraOrientation();
    }
  };

  /**
   * Begin rendering the scene. Rendering is limited based on the availability of a new animation frame. This prevents
   * unnecessary renders when the user switches tabs or the system goes to sleep.
   * @see https://stackoverflow.com/a/17926651
   */
  private render = (timestamp: DOMHighResTimeStamp) => {
    this.meta.animationFrameId = requestAnimationFrame(this.render);

    const deltaTime = timestamp - this.meta.timestamp;
    if (deltaTime < PerformanceConstants.FPS_CAP_INTERVAL) {
      return;
    }
    this.meta.timestamp = timestamp;

    if (this.keyboardControls.moving) {
      this.moveToNextNode();
    }
    if (this.keyboardControls.panning) {
      this.panCamera();
    }
    if (this.meta.interactive && !this.pointerControls.dragging && !this.pointerControls.marking) {
      this.updateIntersection();
    }
    if (
      this.pendingUpscale &&
      !this.pointerControls.dragging &&
      !this.keyboardControls.moving &&
      !this.keyboardControls.panning
    ) {
      this.upscaleCurrentTexture();
    }

    this.renderer.render(this.scene, this.camera);
    if (this.meta.needSnapshot) {
      this.takeSnapshot();
    }
  };

  /**
   * Request that after the next render, the state of the canvas be captured as a data URL. This needs to be deferred
   * to the next frame request callback (i.e. argument passed to requestAnimationFrame) because the browser frequently
   * clears the canvas draw buffer for performance. This performs better than preserving the drawer buffer via
   * WebGLRenderer's preserveDrawBuffer setting. Remove some objects from the scene temporarily.
   */
  requestSnapshot = () => {
    this.meshes.seamCover.removeFromParent();
    this.objectGroups.annotations.removeFromParent();
    this.objectGroups.groundNodes.removeFromParent();
    this.meta.needSnapshot = true;
  };

  /**
   * Set the three.js PerspectiveCamera field of view to the parameter value. Higher values zoom the camera out, lower
   * values zoom the camera in.
   */
  setFieldOfView = (fieldOfView: number) => {
    this.camera.fov = MathUtils.clamp(fieldOfView, CameraConstants.MIN_FOV, CameraConstants.MAX_FOV);
    this.camera.updateProjectionMatrix();
  };

  /**
   * Set the handler which should be executed for events of the specified name. Note that this differs from the typical
   * addEventListener paradigm; only a single handler is allowed for this component at a time.
   */
  setHandler = (eventName: string, handler: (...args: any[]) => void) => {
    this.handlers[eventName] = handler;
  };

  /**
   * Set the renderer's "marking" mode equal to the parameter value. Supports annotation/markup functionality.
   *
   * The renderer normally responds to ground node clicks by navigating to the node; clicking points on the sphere
   * without an interactive object does nothing. However, when the renderer is in "marking" mode, ground nodes will
   * not be interactive and arbitrary clicks within the scene will dispatch an event indicating that a point has been
   * marked.
   */
  setMarking = (marking: boolean) => {
    this.pointerControls.marking = marking;

    if (marking) {
      this.clearHoverIntersection();
    }
  };

  /**
   * Start rendering the scene. As a precondition, `initialize` must have been called.
   */
  startRendering = () => {
    this.updateCameraOrientation();
    this.render(performance.now());
  };

  /**
   * Stop rendering the scene. This stops the render loop but does not dispose any objects.
   */
  stopRendering = () => {
    if (!this.meta.animationFrameId) {
      return;
    }
    cancelAnimationFrame(this.meta.animationFrameId);
    this.meta.animationFrameId = undefined;
  };

  /**
   * After a snapshot has been requested, convert the canvas draw buffer to a JPEG image at 100% quality. Restore the
   * scene's ground nodes and annotations.
   * @remark This method intentionally made private. If called outside the main render loop, it is not guaranteed to
   * produce an accurate image.
   */
  private takeSnapshot = () => {
    const snapshotDataUrl = this.renderer.domElement.toDataURL('image/jpeg', 1);
    this.handlers[ViewerEvents.ON_SNAPSHOT_AVAILABLE]?.(snapshotDataUrl);

    this.scene.add(this.meshes.seamCover);
    this.scene.add(this.objectGroups.annotations);
    this.scene.add(this.objectGroups.groundNodes);
    this.meta.needSnapshot = false;
  };

  /**
   * Given a list of annotations, render them into the scene. If a set of annotations has already been rendered,
   * reconcile the required changes.
   */
  updateAnnotations = (annotations?: Annotation[]) => {
    if (annotations) {
      this.annotations = annotations;
    }

    for (const child of [...this.objectGroups.annotations.children]) {
      child.removeFromParent();
    }

    const sceneAnnotations = this.annotations?.filter((annotation) => annotation.node === this.currentNode?.id) ?? [];
    for (const annotation of sceneAnnotations) {
      const annotationSprite = new Sprite(this.materials.sprites.annotation);
      annotationSprite.onBeforeRender = () => this.beforeAnnotationRender(annotationSprite);
      annotationSprite.position.set(
        ...new Vector3(annotation.x, annotation.y, annotation.z)
          .multiplyScalar(GeometryConstants.ANNOTATION_POSITION_SCALE)
          .toArray()
      );
      annotationSprite.scale.multiplyScalar(GeometryConstants.ANNOTATION_SCALE);
      annotationSprite.userData = { annotation };
      this.objectGroups.annotations.add(annotationSprite);
    }

    // This method may be called outside of this file (e.g. when an annotation is created or removed). This triggers
    // a texture change on the node's onBeforeRender hook.
    for (const groundNode of this.objectGroups.groundNodes.children) {
      groundNode.userData.isAnnotated =
        this.annotations?.some((annotation) => annotation.node === groundNode.userData.node.id) ?? false;
    }
  };

  /**
   * The camera is positioned inside of a sphere, so its orientation can either be understood via angles (theta and phi)
   * or as being aimed at a point (x, y, z) on the sphere. For angles theta (pan on the x-z plane) and phi (tilt on the
   * y-axis), compute the target point and aim the three.js camera. Update the 2D projection on the x-z axis.
   */
  private updateCameraOrientation = () => {
    this.cameraTarget.setFromSpherical(this.cameraTargetSpherical);
    this.camera.lookAt(this.cameraTarget);
    // console.debug('[Renderer] Looking at point on sphere', this.cameraTarget);

    const minimapDirection = new Vector2(this.cameraTarget.x, this.cameraTarget.z).normalize();
    this.handlers[ViewerEvents.ON_CHANGE_CAMERA_DIRECTION]?.(minimapDirection);
  };

  /**
   * Update the scene's ground nodes. Nodes are positioned with respect to the current world coordinates.
   */
  private updateGroundNodes = () => {
    if (!this.annotations || !this.currentNode || !this.floorplan || !this.nodes) {
      return;
    }

    // To prevent the ground nodes from artifacting when they clip into each other, they need slightly different heights.
    let currentHeightOffset = 0;
    const nodeWorldPosition = new Vector3();
    const relativePosition = new Vector3();

    const localGroundNodes = cullGroundNodes(
      this.nodes,
      this.currentNode,
      this.currentNodeIndex,
      this.annotations,
      this.floorplan
    );

    for (const child of [...this.objectGroups.groundNodes.children]) {
      child.removeFromParent();
    }

    for (const node of localGroundNodes) {
      nodeWorldPosition.set(node.xpercent, currentHeightOffset, node.ypercent).applyMatrix4(this.worldScale);
      relativePosition.subVectors(nodeWorldPosition, this.worldPosition);

      const isAnnotated = this.annotations.some((annotation) => annotation.node === node.id);
      const nodeMesh = new Mesh(this.geometries.groundNode, this.materials.basic.groundNode);
      nodeMesh.onBeforeRender = () => this.beforeGroundNodeRender(nodeMesh);
      nodeMesh.position.set(relativePosition.x, relativePosition.y, relativePosition.z);
      nodeMesh.userData = { isAnnotated, node };
      this.objectGroups.groundNodes.add(nodeMesh);

      currentHeightOffset += GeometryConstants.GROUND_NODE_OFFSET;
    }
  };

  /**
   * Cast a ray from the camera to the current pointer position. If any ground nodes or annotations are intersected,
   * store a reference to the object (to handle clicks) and change the pointer to a cursor icon via CSS.
   */
  private updateIntersection = () => {
    if (!this.raycaster || !this.objectGroups.annotations || !this.objectGroups.groundNodes) {
      return;
    }

    this.raycaster.setFromCamera(this.pointerControls.position, this.camera);
    const [intersection] = this.raycaster.intersectObjects<Mesh | Sprite>([
      ...this.objectGroups.annotations.children,
      ...this.objectGroups.groundNodes.children,
    ]);

    // Case: the pointer is not over a mesh and was not previously over one.
    if (!intersection && !this.currentHoverIntersection) {
      return;
    }

    // Case: the pointer is not over a mesh, but previously was over one.
    if (!intersection && this.currentHoverIntersection) {
      this.clearHoverIntersection();
      return;
    }

    // Now we can assume that the intersection is a mesh. All of its falsey cases are covered above.
    // Case: is the pointer already over the current hover target?
    if (intersection.object === this.currentHoverIntersection) {
      return;
    }

    // Case: did the pointer move to a different target? If it entered any object for the first time, change the cursor.
    if (!this.currentHoverIntersection) {
      this.renderer?.domElement.classList.add(classes.cursorPointer);
    }
    this.currentHoverIntersection = intersection.object;
  };

  /**
   * Upscale the current node's texture to high quality.
   */
  private upscaleCurrentTexture = () => {
    if (!this.pendingUpscale?.texture) {
      return;
    }
    if (this.pendingUpscale.nodeId !== this.currentNode?.id) {
      this.pendingUpscale = undefined;
      return;
    }

    this.materials.basic.sphere.map = this.pendingUpscale?.texture;
    this.pendingUpscale = undefined;
  };
}

export default Renderer;
