import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';

import { MoreVerticalIcon } from '../../components/Icon';
import { PendoTopic } from '../../constants/analytics';

import classes from './PortfolioDashboard.module.scss';

export interface ActionsColumnProps {
  onClickViewUsers: MouseEventHandler;
}

const ActionsColumn = (props: ActionsColumnProps) => {
  const { onClickViewUsers } = props;

  return (
    <Flex justifyContent="flex-end">
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Project menu"
          data-pendo-topic={PendoTopic.PORTFOLIO}
          data-pendo-label="Open project overflow menu"
          icon={<MoreVerticalIcon className={classes.projectMenuIcon} />}
          variant="ghost"
          size="sm"
        />
        <Portal>
          <MenuList minWidth={0}>
            <MenuItem
              data-pendo-topic={PendoTopic.USER_MANAGEMENT}
              data-pendo-label="Open project users drawer from portfolio page"
              onClick={onClickViewUsers}
            >
              View Users
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};

export default ActionsColumn;
