import { Text, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { add, format } from 'date-fns';
import { useState } from 'react';

import { PortfolioProject } from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { ProjectApi } from '../../api/v1/rest/ProjectApi';
import DatePicker from '../../components/DatePicker/DatePicker';
import Toast from '../../components/Toast';
import { PendoTopic } from '../../constants/analytics';
import { PortfolioDashboardQueryKeys, QueryTopics } from '../../constants/queries';

export interface BaselineCompletionContainerProps {
  /**
   * True if the user is authorized to change completion dates for this project's account
   */
  canEditDate: boolean;
  /**
   * The server provided baseline completion date. Used for the initial load before the user makes changes.
   */
  completionDate: string | null;
  /**
   * The ID of the project
   */
  projectId: number;
}

const BaselineCompletionContainer = (props: BaselineCompletionContainerProps) => {
  const { canEditDate, completionDate, projectId } = props;

  const queryClient = useQueryClient();
  const [selectedDateString, setSelectedDateString] = useState(completionDate);

  const toast = useToast();

  const updateProjectBaselineCompletionDate = useMutation({
    mutationKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.BASELINE_COMPLETION_DATE, projectId],
    mutationFn: ({ projectId, newDate }: { projectId: number; newDate: string }) =>
      ProjectApi.updateBaselineCompletionDate(projectId, newDate),
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          if (error instanceof AxiosError && error.response?.status === 403) {
            return (
              <Toast
                {...props}
                title="Error"
                description="You do not have permission to set this date. Contact Customer Success for assistance."
                status="error"
              />
            );
          }
          return <Toast {...props} title="Error" description="An error occurred. Try again later." status="error" />;
        },
      });
    },
    onSuccess: (_, variables) => {
      const projectsQueryKey = [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.PORTFOLIO_PROJECTS_LIST];
      const projectData = queryClient.getQueryData<PortfolioProject[]>(projectsQueryKey) ?? [];
      const indexOfThisProjectInQueryData = projectData.findIndex((project) => project.id === projectId);
      if (indexOfThisProjectInQueryData === -1) {
        console.error('[BaselineCompletionCellContainer] Project missing during baseline completion update??');
        return;
      }
      const updatedProjectData = [
        ...projectData.slice(0, indexOfThisProjectInQueryData),
        { ...projectData[indexOfThisProjectInQueryData], baseline_completion_date: variables.newDate },
        ...projectData.slice(indexOfThisProjectInQueryData + 1),
      ];
      queryClient.setQueryData(projectsQueryKey, updatedProjectData);

      setSelectedDateString(variables.newDate);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Date updated." status="success" />,
      });
    },
  });

  const handleDateChange = (date: Date) => {
    const formatted = format(date, 'yyyy-MM-dd');
    updateProjectBaselineCompletionDate.mutate({ projectId, newDate: formatted });
  };

  if (canEditDate) {
    // We set this to noon to minimize timezone hazards, like this being parsed as UTC
    const selectedDate = selectedDateString ? new Date(`${selectedDateString} 12:00:00`) : null;
    return (
      <DatePicker
        key="date-picker-field"
        buttonProps={{
          'aria-label': 'Baseline Completion Date',
          'data-pendo-label': 'Baseline Completion Date',
          'data-pendo-topic': PendoTopic.PORTFOLIO,
          width: { base: '100%', lg: '8.5rem' },
          minHeight: '2rem',
          size: 'sm',
          isLoading: updateProjectBaselineCompletionDate.isLoading,
        }}
        icon="CalendarIcon"
        maxDate={add(new Date(), { years: 10 })}
        onChange={handleDateChange}
        popoverPlacement="bottom-end"
        popoverVariant="searchInput"
        selected={selectedDate}
        value={selectedDate ? format(selectedDate, 'MMM d, yyyy') : 'Select a date'}
      />
    );
  }
  return (
    <Text fontSize="0.875rem">
      {completionDate ? format(new Date(`${completionDate} 12:00:00`), 'MMM d, yyyy') : '-'}
    </Text>
  );
};

export default BaselineCompletionContainer;
