import { ButtonProps, Icon, IconButton, Tooltip } from '@chakra-ui/react';

import { MarkupsIcon } from '../Icon';

export interface MarkPointButtonProps {
  /** Optional set of props to be passed to the rendered inner button. */
  buttonProps?: ButtonProps;
  /** Flag indicating whether or not the button is disabled. */
  isActive?: boolean;
  /** Flag indicating whether or not the button is disabled. */
  isDisabled?: boolean;
  /** Handler to call when the button is clicked. */
  onClick: () => void;
}

const MarkPointButton = (props: MarkPointButtonProps) => {
  const { buttonProps, isActive, isDisabled, onClick } = props;

  return (
    <Tooltip hasArrow label="Create a Markup" placement="left">
      <IconButton
        aria-label="Create a markup"
        data-pendo-label="Create markup"
        data-pendo-topic="view-360-action"
        height="2.5rem"
        icon={<Icon as={MarkupsIcon} height="1.5rem" width="1.5rem" />}
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={onClick}
        variant="view360"
        width="2.5rem"
        {...buttonProps}
      />
    </Tooltip>
  );
};

export default MarkPointButton;
