import { AspectRatio, Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import download from 'downloadjs';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import identity from 'lodash/identity';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ProjectUser } from '../../../@types/UserManagement';
import { ClearIndicator, Control, DropdownIndicator } from '../../../components/Select/ChakraReactSelectOverrides';
import { applyFilter } from '../../../utils/image';

import classes from './SnapshotModal.module.scss';

export interface SnapshotFormValues {
  body: string;
  recipients: { label: string; value: string }[];
  subject: string;
}

export interface SnapshotFormProps {
  /** Optional brightness factor to apply to the image. */
  brightness?: number;
  filename: string;
  /** Base 64 encoded image data to download or email. Generated by the user on the 360º viewer. */
  image: string;
  initialValues: SnapshotFormValues;
  onSubmit: (values: SnapshotFormValues) => void;
  users: ProjectUser[];
}

const validationSchema = Yup.object({
  recipients: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().email('All entries must be valid email addresses').required(),
      })
    )
    .min(1, 'Please select at least one recipient'),
  subject: Yup.string().required('Subject is required'),
  body: Yup.string().required('Message body is required'),
});

const SnapshotForm = forwardRef((props: SnapshotFormProps, ref: ForwardedRef<FormikProps<SnapshotFormValues>>) => {
  const { onSubmit, image, filename, users, brightness } = props;

  const [currentImage, setCurrentImage] = useState<string>(image);

  // If a brightness value was passed, transform the image and save the changes in state.
  useEffect(() => {
    if (brightness) {
      applyFilter(image, brightness, setCurrentImage);
    }
  }, [brightness, image]);

  const downloadImage = () => {
    download(currentImage, filename);
  };

  const options = users.map((user) => ({
    label: `${user.name} <${user.email}>`,
    value: user.email,
  }));

  return (
    <Formik innerRef={ref} initialValues={props.initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, handleBlur, handleChange, setFieldTouched, setFieldValue, touched, values }) => (
        <Form>
          <div className={classes.formHeader}>
            <AspectRatio ratio={4 / 3}>
              <img alt="Snapshot" src={currentImage} />
            </AspectRatio>
            <div className={classes.snapshotActions}>
              <Button
                data-pendo-label="Download"
                data-pendo-topic="snapshot"
                onClick={downloadImage}
                padding="0.5rem 1.25rem"
                size="md"
                type="button"
                variant="mediumEmphasisV2"
              >
                Download Image
              </Button>
            </div>
          </div>
          <FormControl
            className={classes.formControl}
            isInvalid={Boolean(errors.recipients) && Boolean(touched.recipients)}
          >
            <FormLabel>Recipients</FormLabel>
            <CreatableSelect
              chakraStyles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? 'var(--primary-100)' : 'white',
                  color: 'var(--gray-800)',
                }),
              }}
              classNamePrefix="chakra-react-select"
              colorScheme="purple"
              components={{ ClearIndicator, Control, DropdownIndicator }}
              formatCreateLabel={(inputValue) => `Add ${inputValue}`}
              isMulti
              name="recipients"
              onBlur={() => setFieldTouched('recipients', true)}
              onChange={(nextRecipients) => setFieldValue('recipients', nextRecipients)}
              options={options}
              placeholder="Select recipients..."
              selectedOptionStyle="color"
              selectedOptionColor="purple"
              useBasicStyles
              value={values.recipients}
            />
            <ErrorMessage
              component={FormErrorMessage}
              name="recipients"
              render={(errorMessage) => {
                if (Array.isArray(errorMessage)) {
                  // If the first recipient is valid but the second is invalid, the array will contain an empty first
                  // element. Filter out falsy values.
                  const validEntries = errorMessage.filter(identity);
                  return <FormErrorMessage>{validEntries[0]?.value ?? 'An unknown error occurred'}</FormErrorMessage>;
                }

                return <FormErrorMessage>{errorMessage}</FormErrorMessage>;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl} isInvalid={Boolean(errors.subject) && touched.subject}>
            <FormLabel>Subject</FormLabel>
            <Input name="subject" onBlur={handleBlur} onChange={handleChange} value={values.subject} />
            <ErrorMessage component={FormErrorMessage} name="subject" />
          </FormControl>
          <FormControl className={classes.formControl} isInvalid={Boolean(errors.body) && touched.body}>
            <FormLabel>Your message</FormLabel>
            <Textarea
              name="body"
              onBlur={handleBlur}
              onChange={handleChange}
              resize="vertical"
              size="lg"
              value={values.body}
            />
            <ErrorMessage component={(errorProps) => <FormErrorMessage {...errorProps} />} name="body" />
          </FormControl>
        </Form>
      )}
    </Formik>
  );
});

export default SnapshotForm;
