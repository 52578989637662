import { ButtonProps, Icon, IconButton } from '@chakra-ui/react';

import { CollapseIcon, ExpandIcon } from '../Icon';

export interface FullScreenToggleProps {
  /** Optional set of props to be passed to the rendered inner button. */
  buttonProps?: ButtonProps;
  /** Flag indicating whether or not the subject being toggled is currently "full-screen". */
  isFull?: boolean;
  /** Handler to call when the button is clicked. */
  onClick: () => void;
}

const FullScreenToggle = (props: FullScreenToggleProps) => {
  const { buttonProps, isFull, onClick } = props;

  return (
    <IconButton
      data-pendo-topic="minimap"
      height="2.5rem"
      icon={<Icon as={isFull ? CollapseIcon : ExpandIcon} height="1.5rem" width="1.5rem" />}
      onClick={onClick}
      transition="bottom 0.4s ease-in-out 0.1s, left 0.4s ease-in-out 0.1s"
      variant="view360"
      width="2.5rem"
      {...(isFull
        ? {
            'aria-label': 'Collapse',
            bottom: 'var(--gutter-360-control-vertical)',
            'data-pendo-label': 'Collapse',
            left: 'var(--gutter-360-control-horizontal)',
          }
        : {
            'aria-label': 'Expand',
            bottom: '0.25rem',
            'data-pendo-label': 'Expand',
            left: '0.25rem',
          })}
      {...buttonProps}
    />
  );
};

export default FullScreenToggle;
