import { Button } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';

import { InviteStatus } from '../AccountUsersContainer';

export interface StatusCellProps {
  isError?: boolean;
  isLoading?: boolean;
  /**
   * Whether or not the cell should be rendered as uninteractive text. Useful when, for example, the user does not have
   * adequate permissions to invite users within the current account.
   */
  isReadOnly: boolean;
  /** Function called when the 'Resend invite' button in a row is clicked */
  onResendInviteClick: () => void;
  status: InviteStatus;
}

const StatusCell = (props: StatusCellProps) => {
  const { status, onResendInviteClick, isLoading, isError, isReadOnly } = props;

  if (isReadOnly) {
    return status === InviteStatus.Pending ? <>Pending</> : <>Registered</>;
  }

  if (status === InviteStatus.Pending) {
    const handleResendInviteClick: MouseEventHandler = (event) => {
      // Prevent the click from triggering the row click
      event.stopPropagation();
      onResendInviteClick();
    };

    return (
      <Button
        data-pendo-label="Resend invite from account users page"
        data-pendo-topic="user-management"
        isDisabled={isError}
        isLoading={isLoading}
        onClick={handleResendInviteClick}
        size="sm"
        variant="lowEmphasisV2"
      >
        Resend Invite
      </Button>
    );
  }

  return <>Registered</>;
};

export default StatusCell;
