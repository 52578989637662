import { Box, Icon } from '@chakra-ui/react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { ReactNode } from 'react';
import { Link, generatePath, useHistory, useRouteMatch } from 'react-router-dom';

import { helpCenterUrl } from '../../constants/urls';
import Routes from '../../routes';
import { ChatIcon, HelpIcon, LogoIcon, ProgressTrackingIcon } from '../Icon';
import AccountDropdown from './AccountDropdown';

import classes from './Sidebar.module.scss';

export interface SidebarProps {
  children?: ReactNode;
}

const Sidebar = (props: SidebarProps) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const isUserOnHomePage = routeMatch.path === Routes.PROJECTS;
  const isUserOnProgressTracking = routeMatch.path === Routes.PROJECT_PROGRESS_TRACKING;
  const isProgressTrackingButtonVisible =
    isUserOnProgressTracking ||
    routeMatch.path === Routes.FLOORPLAN ||
    routeMatch.path === Routes.WALKTHROUGH ||
    routeMatch.path === Routes.VIEW_360;

  const goToProgressTracking = () => {
    const params = routeMatch.params as { projectId: string };
    history.push(generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: params.projectId }));
  };

  return (
    <Box className={classes.sidebar}>
      <nav>
        <Link className={classNames(classes.logo, { [classes.logoActive]: isUserOnHomePage })} to={Routes.PROJECTS}>
          <Icon aria-label="OnsiteIQ" as={LogoIcon} height="auto" width="2.5rem" />
        </Link>
        {isProgressTrackingButtonVisible && (
          <button
            className={classNames(classes.actionItem, isUserOnProgressTracking && classes.actionItemActive)}
            data-pendo-label="Progress"
            data-pendo-topic="sidebar-action"
            onClick={isUserOnProgressTracking ? noop : () => goToProgressTracking()}
          >
            <ProgressTrackingIcon aria-hidden />
            <span className={classes.actionItemLabel}>Progress</span>
          </button>
        )}
        {props.children}
      </nav>
      <Box className={classes.groupBottom}>
        <a
          className={classNames(classes.bottomActionItem, classes.iconButton, classes.helpCenterButton)}
          data-pendo-label="Open help center"
          data-pendo-topic="sidebar"
          href={helpCenterUrl}
          rel="noreferrer"
          target="_blank"
        >
          <Icon aria-label="Help Center" as={HelpIcon} paddingBlockEnd="0.125rem" />
        </a>
        <button
          id="help-chat-button"
          aria-label="Send us a message"
          className={classNames(classes.bottomActionItem, classes.iconButton, classes.intercomButton)}
          data-pendo-label="Toggle support"
          data-pendo-topic="sidebar"
        >
          <ChatIcon aria-hidden />
        </button>
        <AccountDropdown triggerClassName={classNames(classes.bottomActionItem, classes.menuButton)} />
      </Box>
    </Box>
  );
};

export default Sidebar;
