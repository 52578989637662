import {
  Box,
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { differenceInDays } from 'date-fns';
import { useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { MatchingFloorplan } from '../../@types/api/v0/rest/Node';
import { Project } from '../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../@types/api/v0/rest/Walkthrough';
import { ProjectHierarchyNode } from '../../@types/api/v1/bespoke/ProjectHierarchy';
import CollapsibleListItem from '../../components/CollapsibleList/CollapsibleListItem';
import CollapsibleListLeaf from '../../components/CollapsibleList/CollapsibleListLeaf';
import { DoubleRightArrowIcon } from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import theme from '../../theme';
import { naturalSort } from '../../utils';
import { formatIsoDate } from '../../utils/dateUtils';
import { formatAddress } from '../../utils/stringUtils';
import DateWarningModal from '../DateWarningModal/DateWarningModal';
import { getFloorplanTimestamp, getLocationParameters } from './locationsDrawerUtils';

export interface LocationsDrawerProps {
  /** The current floorplan. Will be used if the `selectionMode` is `"floorplan"`. */
  floorplan?: Floorplan;
  /** The current hierarchy node. Will be used if the `selectionMode` is `"hierarchyNode"`. */
  hierarchyNode?: ProjectHierarchyNode;
  /** Flag indicating if the component is loading or not. If true, render only a loading spinner in the drawer. */
  isLoading?: boolean;
  /** Flag indicating if the drawer is open or not. If false, nothing is rendered. */
  isOpen?: boolean;
  /** When the user clicks on a given floorplan, bring them to a comparably recent walkthrough of the selected floor. */
  matches?: MatchingFloorplan[];
  /** Handler to call when the drawer is closed. */
  onClose: () => void;
  /**
   * Handler to call when a floorplan is selected from this drawer. The `selectionMode` needs to be set to `"floorplan"`
   * in order for this handler to be callable.
   */
  onFloorplanSelect?: (location: {
    projectId: number;
    floorplanId: number;
    walkthroughId?: number;
    nodeId?: string;
    date?: string;
  }) => void;
  /**
   * Handler to call when a hierarchy node is selected from this drawer. The `selectionMode` needs to be set to
   * `"hierarchyNode"` in order for this handler to be callable. If the user clicks the "All floorplans" button, this
   * handler will be called with a value of `undefined`.
   */
  onHierarchyNodeSelect?: (hierarchyNode?: ProjectHierarchyNode) => void;
  /** The current project. */
  project: Project;
  /** The project's hierarchy representation to render within the drawer. */
  projectHierarchy: ProjectHierarchyNode[];
  /** The selection mode to use. */
  selectionMode?: 'floorplan' | 'hierarchyNode';
  /** The current walkthrough. */
  walkthrough?: Walkthrough;
}

const LocationsDrawer = (props: LocationsDrawerProps) => {
  const {
    floorplan,
    hierarchyNode,
    isLoading,
    isOpen,
    matches,
    onClose,
    onFloorplanSelect,
    onHierarchyNodeSelect,
    project,
    projectHierarchy,
    selectionMode = 'floorplan',
    walkthrough,
  } = props;

  const [pendingLocationParams, setPendingLocationParams] = useState<{
    projectId: number;
    floorplanId: number;
    walkthroughId?: number;
    nodeId?: string;
    date?: string;
  }>();

  const {
    isOpen: isDateWarningModalOpen,
    onOpen: openDateWarningModal,
    onClose: closeDateWarningModal,
  } = useDisclosure();

  const [cookies] = useCookies(['onsitevision_date_warning_acknowledged']);
  const wasDateWarningAcknowledged = Boolean(cookies.onsitevision_date_warning_acknowledged);

  /** List of `ProjectHierarchyNode` IDs to traverse in order to get to the current floorplan or hierarchy node. */
  const hierarchyPath = useMemo<number[]>(() => {
    if (projectHierarchy.length === 0) {
      return [];
    }

    const getMatchingHierarchyPath = (node: ProjectHierarchyNode, segments: number[] = []): number[] | undefined => {
      const isFloorplanMatch = selectionMode === 'floorplan' && node.floorplans.some(({ id }) => id === floorplan?.id);
      const isHierarchyNodeMatch =
        selectionMode === 'hierarchyNode' && node.children.some(({ id }) => id === hierarchyNode?.id);
      if (isFloorplanMatch || isHierarchyNodeMatch) {
        return [...segments, node.id];
      }

      for (const child of node.children) {
        const matchingHierarchyPath = getMatchingHierarchyPath(child, [...segments, node.id]);
        if (matchingHierarchyPath) {
          return matchingHierarchyPath;
        }
      }

      return undefined;
    };

    for (const hierarchyNode of projectHierarchy) {
      const hierarchyPath = getMatchingHierarchyPath(hierarchyNode);
      if (hierarchyPath) {
        return hierarchyPath;
      }
    }

    return [];
  }, [floorplan, hierarchyNode, projectHierarchy, selectionMode]);

  /**
   * When a floorplan is selected, determine where the user should be sent. If a selection has a "matching node", the
   * user will be sent to a node close to the current node's position on the destination floorplan.
   *
   * If the `walkthrough` prop is present (i.e. the user is currently viewing a walkthrough or walkthrough node in 360)
   * and the destination also has a target walkthrough date, we compute the difference between the two dates. If they
   * aren't on the same day, a warning modal may be shown to the user.
   */
  const handleFloorplanSelect = (nextFloorplan: Floorplan) => {
    const matchingNode = matches?.find((match) => match.floorplan === nextFloorplan.id);
    const destinationParameters = getLocationParameters(project, walkthrough?.when, nextFloorplan, matchingNode);

    const daysDifference =
      destinationParameters.date && walkthrough
        ? differenceInDays(new Date(destinationParameters.date), new Date(walkthrough.when))
        : 0;
    const showDateWarning = !wasDateWarningAcknowledged && Math.abs(daysDifference) > 0;

    if (showDateWarning) {
      setPendingLocationParams(destinationParameters);
      openDateWarningModal();
    } else {
      onFloorplanSelect?.(destinationParameters);
    }
  };

  /** When the user acknowledges the date warning, change locations, close the modal, and clear the pending changes. */
  const handleWarningAcknowledge = () => {
    onFloorplanSelect?.(pendingLocationParams!);
    closeDateWarningModal();
    setPendingLocationParams(undefined);
  };

  /** When the user acknowledges the date warning, change locations, close the modal, and clear the pending changes. */
  const handleWarningClose = () => {
    closeDateWarningModal();
    setPendingLocationParams(undefined);
  };

  /** When `projectHierarchy` is empty, simply list all floorplans. */
  const renderFloorplanList = () => {
    return naturalSort([...project.floorplans], 'name').map((floorplanOption) => {
      const captionTimestamp = getFloorplanTimestamp({
        currentFloorplan: floorplan,
        currentWalkthrough: walkthrough,
        // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
        floorplan: floorplanOption,
        matches,
      });
      return (
        <CollapsibleListLeaf
          key={`floorplan-${floorplanOption.id}`}
          isActive={floorplanOption.id === floorplan?.id}
          // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
          item={floorplanOption}
          onClick={selectionMode === 'floorplan' ? handleFloorplanSelect : undefined}
        >
          {captionTimestamp && (
            <Text
              as="span"
              color={theme.colors.brand.gray[600]}
              paddingBlockStart="0.25rem"
              textStyle="detail"
              whiteSpace="normal"
            >
              {formatIsoDate(captionTimestamp)?.formattedDate}
            </Text>
          )}
        </CollapsibleListLeaf>
      );
    });
  };

  /** When `projectHierarchy` is populated, render the floorplans in a tree representation. */
  const renderListWithHierarchy = (
    hierarchyNodes: ProjectHierarchyNode[],
    depth = 0,
    currentHierarchyPath = hierarchyPath
  ) => {
    const listElements = [];
    for (const node of hierarchyNodes) {
      if (node.children.length > 0) {
        listElements.push(
          <CollapsibleListItem
            key={`hierarchy-list-item-${node.id}`}
            depth={depth}
            isActive={hierarchyNode?.id === node.id}
            isInitiallyOpen={currentHierarchyPath[0] === node.id || (selectionMode === 'hierarchyNode' && depth === 0)}
            item={node}
            onClick={selectionMode === 'hierarchyNode' ? () => onHierarchyNodeSelect?.(node) : undefined}
          >
            {renderListWithHierarchy(node.children, depth + 1, currentHierarchyPath.slice(1))}
          </CollapsibleListItem>
        );
        continue;
      }

      listElements.push(
        <CollapsibleListItem
          key={`hierarchy-list-item-${node.id}`}
          depth={depth}
          isActive={hierarchyNode?.id === node.id}
          isInitiallyOpen={currentHierarchyPath[0] === node.id}
          item={node}
          onClick={selectionMode === 'hierarchyNode' ? () => onHierarchyNodeSelect?.(node) : undefined}
        >
          {node.floorplans.map((hierarchyNodeFloorplan) => {
            const captionTimestamp = getFloorplanTimestamp({
              currentFloorplan: floorplan,
              currentWalkthrough: walkthrough,
              floorplan: hierarchyNodeFloorplan,
              matches,
            });

            return (
              <CollapsibleListLeaf
                key={`floorplan-${hierarchyNodeFloorplan.id}`}
                isActive={hierarchyNodeFloorplan.id === floorplan?.id}
                item={hierarchyNodeFloorplan}
                onClick={selectionMode === 'floorplan' ? handleFloorplanSelect : undefined}
              >
                {captionTimestamp && (
                  <Text
                    as="span"
                    color={theme.colors.brand.gray[600]}
                    paddingBlockStart="0.25rem"
                    textStyle="detail"
                    whiteSpace="normal"
                  >
                    {formatIsoDate(captionTimestamp)?.formattedDate}
                  </Text>
                )}
              </CollapsibleListLeaf>
            );
          })}
        </CollapsibleListItem>
      );
    }

    return listElements;
  };

  return (
    <>
      <ChakraDrawer isOpen={Boolean(isOpen)} onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton>
            <Icon aria-hidden as={DoubleRightArrowIcon} height="1.5rem" width="1.5rem" />
          </DrawerCloseButton>
          <DrawerHeader>
            <Heading as="h1" size="lg">
              {project.name}
            </Heading>
            <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
              {formatAddress(project.address_line, project.city, project.state, project.zip_code)}
            </Text>
            {walkthrough && (
              <>
                <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem" paddingBlockStart="1rem">
                  Current walkthrough
                </Text>
                <Text fontSize="1rem">{formatIsoDate(walkthrough?.when)?.formattedDateTime}</Text>
              </>
            )}
            {!isLoading && selectionMode === 'hierarchyNode' && (
              <Button
                isActive={!hierarchyNode}
                margin="1rem 0 0"
                minHeight="unset"
                onClick={() => onHierarchyNodeSelect?.(undefined)}
                padding="calc(0.5rem + 1px) 0.5rem"
                size="md"
                variant="outline"
                width="100%"
                _active={{
                  backgroundColor: theme.colors.brand.primary[100],
                  border: `2px solid ${theme.colors.brand.primary[500]}`,
                  color: theme.colors.brand.gray[900],
                  padding: 'calc(0.5rem) 0.5rem',
                }}
              >
                All floorplans
              </Button>
            )}
          </DrawerHeader>
          <DrawerBody>
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Box
                backgroundColor={theme.colors.brand.gray[50]}
                borderBottom={`1px solid ${theme.colors.brand.gray[200]}`}
                borderTop={`1px solid ${theme.colors.brand.gray[200]}`}
                marginBlockStart="0.75rem"
                padding="0.5rem 0.25rem"
              >
                {projectHierarchy.length === 0 ? renderFloorplanList() : renderListWithHierarchy(projectHierarchy)}
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </ChakraDrawer>
      <DateWarningModal
        fromDate={walkthrough?.when}
        isOpen={isDateWarningModalOpen}
        onAcknowledge={handleWarningAcknowledge}
        onClose={handleWarningClose}
        toDate={pendingLocationParams?.date}
      />
    </>
  );
};

export default LocationsDrawer;
