import { ButtonProps, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { HTMLAttributes } from 'react';

import { MinusIcon, PlusIcon } from '../Icon';

interface PendoAttributes extends HTMLAttributes<HTMLDivElement> {
  'data-pendo-label'?: string;
  'data-pendo-topic'?: string;
}

export interface ZoomProps {
  /** Optional set of props to be passed to the rendered inner button. */
  buttonProps?: ButtonProps & PendoAttributes;
  /** Flag indicating whether or not the zoom control is disabled. */
  isDisabled?: boolean;
  /** Handler to call when the zoom control is clicked. */
  onClick: () => void;
}

export const ZoomIn = (props: ZoomProps) => {
  const { buttonProps, isDisabled, onClick } = props;

  return (
    <Tooltip hasArrow label="Zoom In" placement="left">
      <IconButton
        aria-label="Zoom in"
        height="2.5rem"
        icon={<Icon as={PlusIcon} height="1.5rem" width="1.5rem" />}
        isDisabled={isDisabled}
        onClick={() => onClick()}
        variant="view360"
        width="2.5rem"
        {...buttonProps}
      />
    </Tooltip>
  );
};

export const ZoomOut = (props: ZoomProps) => {
  const { buttonProps, isDisabled, onClick } = props;

  return (
    <Tooltip hasArrow label="Zoom Out" placement="left">
      <IconButton
        aria-label="Zoom out"
        height="2.5rem"
        icon={<Icon as={MinusIcon} height="1.5rem" width="1.5rem" />}
        isDisabled={isDisabled}
        onClick={() => onClick()}
        variant="view360"
        width="2.5rem"
        {...buttonProps}
      />
    </Tooltip>
  );
};
