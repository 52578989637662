import { ButtonProps, Icon, IconButton, Tooltip } from '@chakra-ui/react';

import { SnapshotIcon } from '../Icon';

export interface SnapshotButtonProps {
  /** Optional set of props to be passed to the rendered inner button. */
  buttonProps?: ButtonProps;
  /** Flag indicating whether or not the button is disabled. */
  isActive?: boolean;
  /** Flag indicating whether or not the button is disabled. */
  isDisabled?: boolean;
  /** Handler to call when the button is clicked. */
  onClick: () => void;
}

const SnapshotButton = (props: SnapshotButtonProps) => {
  const { buttonProps, isActive, isDisabled, onClick } = props;

  return (
    <Tooltip hasArrow label="Take a Screenshot" placement="left">
      <IconButton
        aria-label="Take a screenshot"
        data-pendo-label="Take screenshot"
        data-pendo-topic="view-360-action"
        height="2.5rem"
        icon={<Icon as={SnapshotIcon} height="1.5rem" width="1.5rem" />}
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={onClick}
        variant="view360"
        width="2.5rem"
        {...buttonProps}
      />
    </Tooltip>
  );
};

export default SnapshotButton;
