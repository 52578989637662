import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ResendInvitationRequest } from '../../../@types/UserManagement';
import { UserManagementApi } from '../../../api/next';
import Toast from '../../../components/Toast';
import { QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import StatusCell, { StatusCellProps } from './StatusCell';

export type StatusCellContainerProps = Omit<StatusCellProps, 'onResendInviteClick'> & {
  accountId: number;
  email: string;
  userId: number;
};

const StatusCellContainer = (props: StatusCellContainerProps) => {
  const { accountId, email, isReadOnly, userId, status } = props;

  const toast = useToast();

  const resendInvitationMutation = useMutation({
    mutationKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.RESEND_INVITE, accountId, userId],
    mutationFn: () => {
      const payload: ResendInvitationRequest = {};
      return UserManagementApi.resendInvitation(accountId, userId, payload);
    },
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          let description = 'An error occurred. Please try again later.';
          if (error instanceof AxiosError && error.response?.status === 429) {
            description = 'User has been invited recently. Please try again later.';
          }
          return <Toast {...props} title="Error" description={description} status="error" />;
        },
      });
    },
    onSuccess: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description={`A reminder has been sent to ${email}`} status="success" />
        ),
      });
    },
  });

  const handleResendInviteClick = () => {
    resendInvitationMutation.mutate();
  };

  const isError = resendInvitationMutation.isError;
  const isLoading = resendInvitationMutation.isLoading;

  return (
    <StatusCell
      isError={isError}
      isLoading={isLoading}
      isReadOnly={isReadOnly}
      onResendInviteClick={handleResendInviteClick}
      status={status}
    />
  );
};

export default StatusCellContainer;
