import { DrawerProps, Stack, Tag, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Project } from '../../../@types/api/v0/rest/Project';
import { PortfolioProject } from '../../../@types/api/v1/bespoke/PortfolioDashboard';
import { Store } from '../../../@types/redux/store';
import { UserManagementApi } from '../../../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import usePermissions from '../../../hooks/usePermissions';
import theme from '../../../theme';
import { formatAddress } from '../../../utils/stringUtils';
import { ListOption } from '../../List/List';
import { ViewUsersDrawer } from './ViewUsersDrawer';

export type ViewUsersDrawerContainerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  project: PortfolioProject | Project;
};

/** Container component for data fetching */
export const ViewUsersDrawerContainer = ({ isOpen, onClose, project }: ViewUsersDrawerContainerProps) => {
  const [shouldShowInternalUsers, setShouldShowInternalUsers] = useState(false);

  const isInternal = useSelector((state: Store) => state.auth.user?.is_internal);
  const { accountsWithInvitePermission, accountsWithOneOrMoreProject, meQuery } = usePermissions();

  const accountId = 'account_id' in project ? project.account_id : project.account.id;

  const projectUsersQuery = useQuery({
    enabled: Boolean(project?.id),
    queryKey: shouldShowInternalUsers
      ? [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.PROJECT_USERS_WITH_INTERNAL_USERS, project?.id]
      : [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.PROJECT_USERS, project?.id],
    queryFn: async ({ signal }) =>
      (await UserManagementApi.getProjectUsers(project?.id ?? 0, shouldShowInternalUsers, { signal })).data,
  });

  const isFetching = projectUsersQuery.isFetching || meQuery.isFetching;
  const isError = !accountId || projectUsersQuery.isError || meQuery.isError;

  const account = accountsWithOneOrMoreProject.find((account) => account.id === accountId);

  const canInviteToAccount = accountsWithInvitePermission.some(({ id }) => id === accountId);
  const users: ListOption[] = (projectUsersQuery.data?.users ?? [])?.map(
    ({ id, email, name, internal, manage_users: manageUsers }) => ({
      id,
      text: `${name} <${email}>`,
      render: () => (
        <Stack direction="row" alignItems="center">
          <Text flex={1}>{`${name} <${email}>`}</Text>
          {manageUsers ? (
            <Tag borderRadius="full" height="fit-content">
              Account owner
            </Tag>
          ) : null}
        </Stack>
      ),
      styles: internal ? { backgroundColor: theme.colors.brand.primary[100] } : undefined,
    })
  );

  const handleShowInternalUsersChange = (value: boolean) => {
    setShouldShowInternalUsers(value);
  };

  return (
    <ViewUsersDrawer
      account={account}
      isOpen={isOpen}
      onClose={onClose}
      // Some of these props (projectAddress, projectId, and projectName) are intentionally split out from `project` since this component supports two Project types
      projectAddress={formatAddress(project.address_line, project.city, project.state, project.zip_code)}
      projectId={project.id}
      projectName={project.name}
      canInviteUsers={canInviteToAccount}
      shouldShowInternalUsers={shouldShowInternalUsers}
      onShouldShowInternalUsersChange={handleShowInternalUsersChange}
      canToggleInternalUsers={isInternal}
      isFetching={isFetching}
      isError={isError}
      users={users}
    />
  );
};
