import { ChakraProvider } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// The following three polyfills now have baseline support. They can be safely removed in a future PR.
import 'core-js/es/map';
import 'core-js/es/promise';
import 'core-js/es/string/starts-with';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppContextProvider } from './AppContext';
import App from './containers/App';
// import reportWebVitals from './reportWebVitals';
import { store } from './store';
import theme from './theme';

import './App.scss';

function initializeTracking() {
  // Create React App includes environment variables as strings. Rather than parsing the string (since "false" is
  // truthy), just check if the string value is an exact match.
  if (process.env.REACT_APP_DD_RUM_ENABLED !== 'true') {
    console.debug('[index] Skipping Datadog RUM initialization...');
    return;
  }

  datadogRum.init({
    applicationId: '082d481d-d04a-41d5-b64e-df92b467924d',
    clientToken: 'pubd1fd21e6d691859b97fdaefd488f1c87',
    site: 'datadoghq.com',
    service: 'onsitevision',
    env: process.env.REACT_APP_ENVIRONMENT,
    version: process.env.REACT_APP_VERSION_NAME,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
  });
  datadogRum.startSessionReplayRecording();
}

function initializeRemoteLogging() {
  // See comment above: this string match intentional.
  if (process.env.REACT_APP_DD_LOGGING_ENABLED !== 'true') {
    console.debug('[index] Skipping Datadog logging initialization...');
    return;
  }

  datadogLogs.init({
    clientToken: 'pubd1fd21e6d691859b97fdaefd488f1c87',
    site: 'datadoghq.com',
    service: 'onsitevision',
    env: process.env.REACT_APP_ENVIRONMENT,
    version: process.env.REACT_APP_VERSION_NAME,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

initializeTracking();
initializeRemoteLogging();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found');
}

const root = createRoot(rootElement);

// Note: we specify a `portalZIndex` here to ensure popovers are visible when triggered from drawers/modals.
// @see https://github.com/chakra-ui/chakra-ui/issues/3269
root.render(
  <ChakraProvider portalZIndex={50} resetCSS theme={theme}>
    <CookiesProvider>
      <Provider store={store}>
        <AppContextProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <App />
            </Router>
            {/* Note: react-query's devtool is excluded from production builds and does not show mutations. */}
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AppContextProvider>
      </Provider>
    </CookiesProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
