import { Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import { FloorplanApi } from '../api/v1/rest/FloorplanApi';
import { Content, InternalLayout, LoadingIndicator } from '../components';
import { ErrorIcon } from '../components/Icon';
import { FloorplanQueryKeys, QueryTopics } from '../constants/queries';
import Routes from '../routes';
import theme from '../theme';
import { generateProjectPageUrl } from '../utils/navigationUtils';

import commonClasses from '../Common.module.scss';

/**
 * This component redirects legacy project URL patterns to newer, more consistent equivalents:
 *
 * - `/floorplan/<floorplan_id>/` to `/projects/<project_id>/floorplans/<floorplan_id>/`
 * - `/floorplan/<floorplan_id>?walkthrough=<walkthrough_id>` to
 *   `/projects/<project_id>/floorplans/<floorplan_id>/walkthroughs/<walkthrough_id>`.
 * - `/floorplan/<floorplan_id>/walkthrough/<walkthrough_id>` to
 *   `/projects/<project_id>/floorplans/<floorplan_id>/walkthroughs/<walkthrough_id>`.
 * - `/floorplan/<floorplan_id>/walkthrough/<walkthrough_id>/node/<node_id>` to
 *   `/projects/<project_id>/floorplans/<floorplan_id>/walkthroughs/<walkthrough_id>/nodes/<node_id>`.
 */
const LegacyProjectUrlRedirectContainer = () => {
  const location = useLocation<{ walkthrough?: string }>();
  const urlParameters = useParams<{ floorplanId: string; walkthroughId?: string; nodeId?: string }>();
  const floorplanId = Number(urlParameters.floorplanId);
  const nodeId = urlParameters.nodeId;

  const floorplanDetailsQuery = useQuery({
    queryKey: [QueryTopics.FLOORPLANS, FloorplanQueryKeys.FLOORPLAN_DETAILS, floorplanId],
    queryFn: async ({ signal }) => (await FloorplanApi.getById(floorplanId, { signal })).data,
  });

  // Walkthrough ID, if present, may be a path or query parameter.
  const targetWalkthroughId = useMemo(() => {
    const urlParamWalkthroughId = Number(urlParameters.walkthroughId);
    if (Number.isInteger(urlParamWalkthroughId)) {
      return urlParamWalkthroughId;
    }

    const searchParams = new URLSearchParams(location.search);
    const searchParamWalkthroughId = Number(searchParams.get('walkthrough'));
    if (Number.isInteger(searchParamWalkthroughId)) {
      return searchParamWalkthroughId;
    }

    return undefined;
  }, [location.search, urlParameters.walkthroughId]);

  if (floorplanDetailsQuery.isFetching) {
    return (
      <InternalLayout>
        <LoadingIndicator fullPage />
      </InternalLayout>
    );
  }

  const floorplan = floorplanDetailsQuery.data;

  if (floorplanDetailsQuery.isError || !floorplan) {
    const statusCode = (floorplanDetailsQuery.error as AxiosError)?.response?.status;
    switch (statusCode) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load project details. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  const destination = generateProjectPageUrl(floorplan.project_id, floorplanId, targetWalkthroughId, nodeId);

  return <Redirect to={destination} />;
};

export default LegacyProjectUrlRedirectContainer;
