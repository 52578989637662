import { Box, Button, ButtonGroup, Flex, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';
import { Vector3 } from 'three';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Detection, DetectionType, DetectionVoteCreateRequestBody } from '../../@types/api/v0/rest/Detection';
import { Node } from '../../@types/api/v0/rest/Node';
import { Reply } from '../../@types/api/v0/rest/Reply';
import { AuthApi } from '../../api/v0/rest/AuthApi';
import { DetectionApi } from '../../api/v0/rest/DetectionApi';
import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { WalkthroughApi } from '../../api/v0/rest/WalkthroughApi';
import { ProjectHierarchyApi } from '../../api/v1/bespoke/ProjectHierarchyApi';
import ControlCenter from '../../components/ControlCenter/ControlCenter';
import AnnotationsDrawerContainer from '../../components/Drawers/Annotations/AnnotationsDrawerContainer';
import SearchDrawer from '../../components/Drawers/Search/SearchDrawer';
import TimeTravelDrawer, { TimeTravelOption } from '../../components/Drawers/TimeTravel/TimeTravelDrawer';
import { ErrorIcon, FloorsIcon, MarkupsIcon, SearchIcon, ShareIcon, TimeIcon } from '../../components/Icon';
import { InternalLayout } from '../../components/Layout';
import Content from '../../components/Layout/Content';
import LoadingIndicator from '../../components/LoadingIndicator';
import { GeometryConstants } from '../../components/PanographViewer/constants';
import Toast from '../../components/Toast';
import MiniMap from '../../components/View360/MiniMap';
import { PendoTopic } from '../../constants/analytics';
import { AuthQueryKeys, ProjectQueryKeys, QueryTopics } from '../../constants/queries';
import LocationsDrawer from '../../dialogs/LocationsDrawer/LocationsDrawer';
import ShareProjectModalContainer from '../../dialogs/ShareProjectModal/ShareProjectModalContainer';
import Routes from '../../routes';
import theme from '../../theme';
import { formatIsoDate } from '../../utils/dateUtils';
import { generateProjectPageUrl } from '../../utils/navigationUtils';
import { denormalizeHierarchy } from '../../utils/treeUtils';

import commonClasses from '../../Common.module.scss';

// eslint-disable-next-line complexity
const WalkthroughContainer = () => {
  const history = useHistory();
  const urlParameters = useParams<{ projectId: string; floorplanId: string; walkthroughId: string }>();
  const projectId = Number(urlParameters.projectId);
  const floorplanId = Number(urlParameters.floorplanId);
  const walkthroughId = Number(urlParameters.walkthroughId);

  const [currentDetection, setCurrentDetection] = useState<Detection>();
  const [currentDetectionType, setCurrentDetectionType] = useState<DetectionType>();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [showNodes, setShowNodes] = useState<boolean>(false);

  const {
    isOpen: isAnnotationsDrawerOpen,
    onOpen: openAnnotationsDrawer,
    onClose: closeAnnotationsDrawer,
  } = useDisclosure();
  const { isOpen: isLocationsDrawerOpen, onOpen: openLocationsDrawer, onClose: closeLocationsDrawer } = useDisclosure();
  const { isOpen: isSearchDrawerOpen, onOpen: openSearchDrawer, onClose: closeSearchDrawer } = useDisclosure();
  const {
    isOpen: isShareProjectModalOpen,
    onOpen: openShareProjectModal,
    onClose: closeShareProjectModal,
  } = useDisclosure();
  const {
    isOpen: isTimeTravelDrawerOpen,
    onOpen: openTimeTravelDrawer,
    onClose: closeTimeTravelDrawer,
  } = useDisclosure();

  const toast = useToast();

  const queryClient = useQueryClient();

  const userProfileQuery = useQuery({
    queryKey: [QueryTopics.AUTH, AuthQueryKeys.USER_PROFILE],
    queryFn: async ({ signal }) => (await AuthApi.getUserProfile({ signal })).data,
  });
  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });
  const projectHierarchyQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_HIERARCHY, projectId],
    queryFn: async ({ signal }) => (await ProjectHierarchyApi.getHierarchyByProjectId(projectId, { signal })).data,
  });
  const walkthroughDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH, walkthroughId],
    queryFn: async ({ signal }) => (await WalkthroughApi.getById(walkthroughId, { signal })).data,
  });
  const walkthroughAnnotationsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId],
    queryFn: async ({ signal }) => (await WalkthroughApi.getAnnotationsByWalkthroughId(walkthroughId, { signal })).data,
  });
  const walkthroughDetectionTypesQuery = useQuery({
    enabled: isSearchDrawerOpen,
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_DETECTION_TYPES, walkthroughId],
    queryFn: async ({ signal }) => (await DetectionApi.getWalkthroughDetectionTypes(walkthroughId, { signal })).data,
  });
  const walkthroughDetectionsQuery = useQuery({
    enabled: isSearchDrawerOpen && Boolean(currentDetectionType),
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_DETECTIONS, walkthroughId, currentDetectionType?.id],
    queryFn: async ({ signal }) =>
      (await DetectionApi.getWalkthroughDetections(walkthroughId, currentDetectionType?.label ?? '', { signal })).data,
  });

  const createDetectionVoteMutation = useMutation({
    mutationKey: [
      QueryTopics.PROJECTS,
      ProjectQueryKeys.WALKTHROUGH_DETECTION_VOTE,
      walkthroughId,
      currentDetectionType?.id,
      currentDetection?.id,
    ],
    mutationFn: ({ requestBody }: { requestBody: DetectionVoteCreateRequestBody }) =>
      DetectionApi.createWalkthroughDetectionVote(requestBody),
    onError: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (toastProps) => (
          <Toast
            {...toastProps}
            title="An error occurred"
            description="Failed to submit detection vote. Please try again later."
            status="error"
          />
        ),
      });
    },
    onSuccess: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (toastProps) => (
          <Toast {...toastProps} title="Success" description="Detection vote submitted." status="success" />
        ),
      });
      walkthroughDetectionsQuery.refetch();
    },
  });

  const user = userProfileQuery.data;
  const project = projectDetailsQuery.data;
  const floorplan = projectDetailsQuery.data?.floorplans?.find(({ id }) => id === floorplanId);
  const walkthrough = walkthroughDetailsQuery.data;
  const annotations = walkthroughAnnotationsQuery.data;
  const detectionTypes = walkthroughDetectionTypesQuery.data ?? [];
  const detections = walkthroughDetectionsQuery.data ?? [];

  const projectHierarchy = useMemo(() => {
    if (!projectHierarchyQuery.data || !projectDetailsQuery.data) {
      return undefined;
    }

    // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
    return denormalizeHierarchy(projectHierarchyQuery.data, projectDetailsQuery.data.floorplans);
  }, [projectDetailsQuery, projectHierarchyQuery]);

  // Automatically select the first detection if there isn't an active one and if the list isn't empty.
  useEffect(() => {
    if (currentDetectionType && walkthroughDetectionsQuery.data?.[0] && !currentDetection) {
      setCurrentDetection(walkthroughDetectionsQuery.data[0]);
    }
  }, [currentDetection, currentDetectionType, walkthroughDetectionsQuery.data]);

  // When the user changes the floorplan or time travels, clear the current search data.
  useEffect(() => {
    setCurrentDetection(undefined);
    setCurrentDetectionType(undefined);
  }, [walkthroughId]);

  /**
   * Navigate to the 360º viewer on some ground node. Optionally, the viewer may be configured to face a set of target
   * coordinates.
   */
  const goTo360 = (node: Node, lookTarget?: { x: number; y: number; z: number }) => {
    if (!project || !floorplan || !walkthrough) {
      return;
    }

    const destination = generatePath(Routes.VIEW_360, {
      projectId: project.id,
      floorplanId: floorplan.id,
      walkthroughId: walkthrough.id,
      nodeId: node.id,
    });
    const queryParams = new URLSearchParams();
    if (lookTarget) {
      queryParams.set('lookX', String(lookTarget.x));
      queryParams.set('lookY', String(lookTarget.y));
      queryParams.set('lookZ', String(lookTarget.z));
    }

    history.push(`${destination}?${queryParams.toString()}`);
  };

  /** When the user deletes an annotation, remove it from the query data to prevent a loss of context. */
  const handleAnnotationDelete = (annotation: Annotation) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? []).filter(
      ({ id }) => id !== annotation.id
    );
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user updates an annotation, replace it in the query data to prevent a loss of context. */
  const handleAnnotationUpdate = (annotation: Annotation) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat(annotation);
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user clicks an annotation in the drawer, navigate to the 360º viewer facing the annotation. */
  const handleAnnotationView = (annotation: Annotation) => {
    const node = walkthrough?.nodes?.find((node: Node) => node.id === annotation.node);
    if (!node) {
      console.warn('[WalkthroughContainer] Tried to view annotation but node not found!');
      return;
    }

    goTo360(node, {
      x: annotation.x,
      y: annotation.y,
      z: annotation.z,
    });
  };

  /**
   * When the user selects a detection in the search drawer or on the minimap, store a reference to the detection. If
   * the detection was selected from the minimap, ensure the drawer is open.
   */
  const handleDetectionSelect = (detection: Detection) => {
    if (currentDetection?.id === detection.id) {
      // The node we've clicked on is already active, let's jump to it
      viewDetection(detection);
    } else {
      // The node we've clicked wasn't active before, let's make it active now
      setCurrentDetection(detection);
      openSearchDrawer();
    }
  };

  /**
   * When the user selects a detection type, set the state accordingly. If the user clears the selection, clear both the
   * current detection type and current detection
   */
  const handleDetectionTypeSelect = (detectionType: DetectionType | undefined) => {
    if (!detectionType) {
      setCurrentDetection(undefined);
      setCurrentDetectionType(undefined);
      return;
    }

    setCurrentDetectionType(detectionType);
    setShowNodes(false);
  };

  /** Handler called when the user votes on a detection. A vote value of `L` means "like", `D` means "dislike". */
  const handleDetectionVote = (detection: Detection, vote: 'L' | 'D') => {
    createDetectionVoteMutation.mutate({
      requestBody: {
        id: detection.votes.find((vote) => vote.user_id === userProfileQuery.data?.id)?.id ?? null,
        vote_type: vote,
        detection_id: detection.id,
      },
    });
  };

  /** Handler called when the user clicks a location within the "Locations" drawer. */
  const handleFloorplanSelect = (location: {
    projectId: number;
    floorplanId: number;
    walkthroughId?: number;
    date?: string;
  }) => {
    history.push(generateProjectPageUrl(location.projectId, location.floorplanId, location.walkthroughId));
  };

  /** When the user creates a reply, add it directly to the query data to prevent a loss of context. */
  const handleReplyCreate = (annotation: Annotation, reply: Reply) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat({ ...annotation, replies: [...annotation.replies, reply] });
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user deletes a reply, remove it from the query data to prevent a loss of context. */
  const handleReplyDelete = (annotation: Annotation, reply: Reply) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat({ ...annotation, replies: [...annotation.replies.filter(({ id }) => id !== reply.id)] });
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user updates a reply, replace it in the query data to prevent a loss of context. */
  const handleReplyUpdate = (annotation: Annotation, reply: Reply) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const currentReplyIndex = annotation.replies.findIndex(({ id }) => id === reply.id);

    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat({
        ...annotation,
        // Ensure the reply is inserted into the correct index.
        replies: [
          ...annotation.replies.slice(0, currentReplyIndex),
          reply,
          ...annotation.replies.slice(currentReplyIndex + 1),
        ],
      });
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /**
   * When the user selects a time travel option, verify that the selected option is the correct type, since time travel
   * options may be either `Walkthrough` or `TimeTravelPair` instances. If the option is a `TimeTravelPair` instance,
   * navigate to the walkthrough and node.
   */
  const handleTimeTravel = (timeTravelOption: TimeTravelOption) => {
    if ('walkthrough' in timeTravelOption) {
      console.warn('[View360Page] Selected 360 time travel option was not a time travel pair!');
    } else {
      history.push(
        generatePath(Routes.WALKTHROUGH, {
          projectId,
          floorplanId,
          walkthroughId: timeTravelOption.id,
        })
      );
    }
  };

  /**
   * When the user clicks on a node in the map, navigate to the 360º viewer. If the node has at least one annotation,
   * face the annotation on load.
   */
  const view360Node = (node: Node) => {
    const annotation = annotations?.find((a) => a.node === node.id);
    if (annotation) {
      // TODO: open drawer on next page?
      goTo360(node, {
        x: annotation.x,
        y: annotation.y,
        z: annotation.z,
      });
    } else {
      goTo360(node);
    }
  };

  /**
   * When the user clicks a search detection in the drawer, navigate to the 360º viewer facing the detected object.
   *
   * Note: detection coordinates come down from Core API as a normalized vector (i.e. a vector in the direction of the
   * detection, but with magnitude 1) in R^3. Now, when we set a look target for the 360º viewer, the target must be a
   * point (x, y, z) on the imagery sphere. To accomplish this, we scale the vector up to the length of the sphere
   * radius.
   *
   * In the future, we could potentially avoid this by having annotations, detections, and general camera orientation
   * solely use angle measure -- theta (pan) and phi (tilt).
   */
  const viewDetection = (detection: Detection) => {
    const node = walkthrough?.nodes?.find((node: Node) => node.id === detection.node_id);
    if (!node) {
      console.warn('[WalkthroughContainer] Tried to view search detection but node not found!');
      return;
    }

    const denormalizedVector = new Vector3(detection.x, detection.y, detection.z).multiplyScalar(
      GeometryConstants.SPHERE_RADIUS
    );
    goTo360(node, { x: denormalizedVector.x, y: denormalizedVector.y, z: denormalizedVector.z });
  };

  const criticalQueries = [projectDetailsQuery, projectHierarchyQuery, userProfileQuery];
  const lowPriorityQueries = [
    walkthroughDetailsQuery,
    walkthroughAnnotationsQuery,
    walkthroughDetectionTypesQuery,
    walkthroughDetectionsQuery,
  ];

  const isAnyCriticalQueryFetching = criticalQueries.some((query) => query.isFetching);
  if (isAnyCriticalQueryFetching) {
    return (
      <InternalLayout>
        <Content>
          <LoadingIndicator fullPage />
        </Content>
      </InternalLayout>
    );
  }

  const errorQueries = [...criticalQueries, ...lowPriorityQueries].filter((query) => query.isError);
  if (errorQueries.length > 0 || !user || !project || !projectHierarchy || !floorplan) {
    switch ((errorQueries[0]?.error as AxiosError | undefined)?.response?.status) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load data for this walkthrough. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  return (
    <InternalLayout>
      <Content constrainToPageHeight>
        <ControlCenter
          cardProps={{
            margin: `var(--gutter-page-vertical) var(--gutter-page-horizontal)`,
            position: 'absolute',
            width: 'calc(100% - 2 * var(--gutter-page-horizontal))',
            zIndex: 1,
          }}
          fields={[
            <ButtonGroup isAttached key="annotations-and-search-drawer-toggles" variant="outline">
              <Button
                data-pendo-label="View annotations"
                data-pendo-topic={PendoTopic.CONTROL_CENTER}
                flex={1}
                leftIcon={<Icon as={MarkupsIcon} height="1.25rem" width="1.25rem" />}
                onClick={openAnnotationsDrawer}
              >
                Markups
              </Button>
              <Button
                data-pendo-label="View search detections"
                data-pendo-topic={PendoTopic.CONTROL_CENTER}
                flex={1}
                leftIcon={<Icon as={SearchIcon} height="1.25rem" width="1.25rem" />}
                onClick={openSearchDrawer}
              >
                AI Search
              </Button>
            </ButtonGroup>,
            <Button
              data-pendo-label="Change location"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              key="change-locations-drawer-toggle"
              leftIcon={<Icon as={FloorsIcon} height="1.25rem" width="1.25rem" />}
              onClick={openLocationsDrawer}
              variant="outline"
            >
              Locations
            </Button>,
            <Button
              data-pendo-label="Time Travel"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              key="time-travel-drawer-toggle"
              leftIcon={<Icon as={TimeIcon} height="1.25rem" width="1.25rem" />}
              onClick={openTimeTravelDrawer}
              variant="outline"
            >
              {walkthrough ? formatIsoDate(walkthrough.when)?.formattedDate : 'Loading...'}
            </Button>,
            <Button
              data-pendo-label="Share project"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              key="share-project-button"
              leftIcon={<Icon as={ShareIcon} fontSize="1.125rem" />}
              onClick={openShareProjectModal}
              variant="primaryExternal"
            >
              Share
            </Button>,
          ]}
          // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
          floorplan={floorplan}
          project={project}
        />
        {!isImageLoaded && <LoadingIndicator />}
        <Box style={{ display: isImageLoaded ? 'block' : 'hidden' }}>
          <MiniMap
            activeDetectionId={currentDetection?.id}
            annotations={annotations}
            detectionList={detections ?? []}
            // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
            floorplan={floorplan}
            initiallyShowZoom
            mini={false}
            nodes={walkthrough?.nodes ?? []}
            onClickNode={view360Node}
            onClickDetection={handleDetectionSelect}
            onImageLoaded={() => setIsImageLoaded(true)}
            onToggleShowNodes={setShowNodes}
            showNodes={showNodes || detections.length === 0}
          />
        </Box>
      </Content>
      <LocationsDrawer
        // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
        floorplan={floorplan}
        isLoading={walkthroughDetailsQuery.isFetching}
        isOpen={isLocationsDrawerOpen}
        onClose={closeLocationsDrawer}
        onFloorplanSelect={handleFloorplanSelect}
        project={project}
        projectHierarchy={projectHierarchy}
        walkthrough={walkthrough}
      />
      <TimeTravelDrawer
        isLoading={walkthroughDetailsQuery.isFetching}
        isOpen={isTimeTravelDrawerOpen}
        onClose={closeTimeTravelDrawer}
        onSelectOption={handleTimeTravel}
        options={floorplan.dated_walkthroughs}
        project={project}
        walkthrough={walkthrough}
      />
      <SearchDrawer
        currentDetection={currentDetection}
        currentDetectionType={currentDetectionType}
        detections={detections}
        detectionTypes={detectionTypes}
        isLoading={
          walkthroughDetectionsQuery.isFetching ||
          walkthroughDetectionTypesQuery.isFetching ||
          createDetectionVoteMutation.isLoading
        }
        isOpen={isSearchDrawerOpen}
        on360Click={viewDetection}
        onDetectionSelect={handleDetectionSelect}
        onDetectionTypeSelect={handleDetectionTypeSelect}
        onDetectionVote={handleDetectionVote}
        onClose={closeSearchDrawer}
        project={project}
        user={user}
        walkthrough={walkthrough}
      />
      <ShareProjectModalContainer isOpen={isShareProjectModalOpen} onClose={closeShareProjectModal} project={project} />
      <AnnotationsDrawerContainer
        annotations={annotations}
        isLoading={walkthroughDetailsQuery.isFetching || walkthroughAnnotationsQuery.isFetching}
        isOpen={isAnnotationsDrawerOpen}
        onAnnotationDelete={handleAnnotationDelete}
        onAnnotationUpdate={handleAnnotationUpdate}
        onAnnotationView={handleAnnotationView}
        onClose={closeAnnotationsDrawer}
        onReplyCreate={handleReplyCreate}
        onReplyDelete={handleReplyDelete}
        onReplyUpdate={handleReplyUpdate}
        project={project}
        walkthrough={walkthrough}
      />
    </InternalLayout>
  );
};

export default WalkthroughContainer;
