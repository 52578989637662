import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { ProgressTrackingMilestoneProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { ProjectHierarchyNode } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import { UserManagementApi } from '../../../api/next';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { HierarchyNodeApi } from '../../../api/v1/rest/HierarchyNodeApi';
import { ProjectApi } from '../../../api/v1/rest/ProjectApi';
import Toast from '../../../components/Toast';
import {
  ProgressTrackingQueryKeys,
  ProjectQueryKeys,
  QueryTopics,
  UserManagementQueryKeys,
} from '../../../constants/queries';
import { ProgressTrackingRowType, ProgressTrackingTableRow } from '../ProgressTrackingPage';
import MilestoneProgressDrawer from './MilestoneProgressDrawer';

export interface MilestoneProgressDrawerContainerProps {
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** The current milestone. May be `undefined` when the drawer is closed. */
  milestone?: Milestone;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: Project;
  /** The current Progress Tracking table cell's parent row. May be `undefined` when the drawer is closed. */
  row?: ProgressTrackingTableRow;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
}

const MilestoneProgressDrawerContainer = (props: MilestoneProgressDrawerContainerProps) => {
  const { isOpen, isProgressTrackingEnabled, milestone, onClose, project, row, selectedDate } = props;

  const milestoneId = milestone && Number.isFinite(milestone.id) ? milestone.id : 0;

  /** If the row is not the summary row in "All floorplans" mode, this will either be a floorplan or hierarchy node. */
  let location: Floorplan | ProjectHierarchyNode | undefined;
  /**
   * If the row is not the summary row in "All floorplans" mode, this variable may be used to qualify the `location`.
   * Mainly useful for ensuring query key uniqueness.
   */
  let locationType: ProgressTrackingRowType.FLOORPLAN | ProgressTrackingRowType.HIERARCHY_NODE | undefined;
  let hasMomentum: boolean | null | undefined = undefined;
  let value: number | undefined;
  let walkthroughId: number | undefined = undefined;

  if (row?.rowType === ProgressTrackingRowType.FLOORPLAN) {
    location = row.floorplan;
    locationType = row.rowType;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
    walkthroughId = row.cells[milestoneId]?.walkthroughId;
  }
  if (row?.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
    location = row.hierarchyNode;
    locationType = row.rowType;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
  }
  if (row?.rowType === ProgressTrackingRowType.SUMMARY) {
    location = row.hierarchyNode;
    locationType = row.hierarchyNode ? ProgressTrackingRowType.HIERARCHY_NODE : undefined;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
  }

  const toast = useToast();

  const queryClient = useQueryClient();

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const queryKey = [
    QueryTopics.PROGRESS_TRACKING,
    ProgressTrackingQueryKeys.PROGRESS_HISTORY,
    project.id,
    milestoneId,
    locationType,
    location?.id,
  ];
  const progressHistoryQuery = useQuery({
    enabled: Boolean(isOpen) && milestoneId > 0,
    queryKey,
    queryFn: async ({ signal }) => {
      let promise: Promise<AxiosResponse<ProgressTrackingMilestoneProgressHistory>>;

      if (row?.rowType === ProgressTrackingRowType.FLOORPLAN) {
        promise = ProgressTrackingApi.getFloorMilestoneProgressHistory(project.id, milestoneId, row.floorplan.id, {
          signal,
        });
      } else if (row?.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
        promise = ProgressTrackingApi.getHierarchyNodeMilestoneProgressHistory(row.hierarchyNode.id, milestoneId, {
          signal,
        });
      } else if (row?.rowType === ProgressTrackingRowType.SUMMARY && row.hierarchyNode) {
        // Summary rows correspond to hierarchy nodes in hierarchy mode (i.e. not "All floorplans" mode).
        // This branch could have alternatively been an OR condition in the previous block, but this takes full
        // advantage of type narrowing/doesn't require a non-null assertion.
        promise = ProgressTrackingApi.getHierarchyNodeMilestoneProgressHistory(row.hierarchyNode.id, milestoneId, {
          signal,
        });
      } else {
        promise = ProgressTrackingApi.getTotalMilestoneProgressHistory(project.id, milestoneId, { signal });
      }

      return (await promise).data;
    },
  });

  const updateMilestoneDateMutation = useMutation({
    mutationKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_MILESTONE_DATE_UPDATE, project.id, milestoneId],
    mutationFn: ({ newDate }: { newDate: string }) => {
      if (locationType === ProgressTrackingRowType.HIERARCHY_NODE && location) {
        return HierarchyNodeApi.updateMilestoneDate(location.id, { milestone_id: milestoneId, end_date: newDate });
      }

      return ProjectApi.updateMilestoneDate(project.id, { milestone_id: milestoneId, end_date: newDate });
    },
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          if (error instanceof AxiosError && error.response?.status === 403) {
            return (
              <Toast
                {...props}
                title="Error"
                description="You do not have permission to set this date. Contact Customer Success for assistance."
                status="error"
              />
            );
          }
          return <Toast {...props} title="Error" description="An error occurred. Try again later." status="error" />;
        },
      });
    },
    onSuccess: () => {
      // Changes to a given baseline completion date (BCD) for the root hierarchy node impact the BCDs in the "All
      // floorplans" view and vice-versa. For this reason, we need to reset all progress history for the milestone. This
      // causes the current query to refetch.
      queryClient.resetQueries({
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_HISTORY,
          project.id,
          milestoneId,
          // Intentionally omitted:
          // locationType,
          // location?.id,
        ],
        exact: false,
      });

      // Clear all Progress Tracking data for the project as well, just to ensure nothing is stale. This has to happen
      // because the BCDs for the root node and "All floorplans" mode are the synchronized, but also because data for
      // other Time Travel dates cannot be assumed to be valid anymore either, since the BCDs are used to calculate
      // momentum.
      queryClient.resetQueries({
        exact: false,
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_TABLE_DATA,
          project.id,
          // Intentionally omitted:
          // hierarchyNode?.id,
          // selectedDate,
        ],
      });

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description="Baseline completion date updated." status="success" />
        ),
      });

      onClose();
    },
  });

  const canEditMilestoneCompletionDate = (meQuery.data?.set_trade_dates ?? []).some(
    (account) => account.id === project.account.id
  );

  const isMilestoneCompletionDateVisible =
    Boolean(isOpen) && milestoneId > 0 && row?.rowType !== ProgressTrackingRowType.FLOORPLAN;

  return (
    <MilestoneProgressDrawer
      canEditMilestoneCompletionDate={canEditMilestoneCompletionDate}
      hasMomentum={hasMomentum}
      isError={progressHistoryQuery.isError}
      isFetching={progressHistoryQuery.isFetching}
      isOpen={Boolean(isOpen)}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      isMilestoneCompletionDateUpdating={updateMilestoneDateMutation.isLoading}
      isMilestoneCompletionDateVisible={isMilestoneCompletionDateVisible}
      location={location}
      milestone={milestone}
      milestoneProgressHistory={progressHistoryQuery.data}
      onClose={onClose}
      onMilestoneCompletionDateChange={(newDate: string) => updateMilestoneDateMutation.mutate({ newDate })}
      projectId={project.id}
      selectedDate={selectedDate}
      value={value}
      walkthroughId={walkthroughId}
    />
  );
};

export default MilestoneProgressDrawerContainer;
