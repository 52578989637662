import {
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import classNames from 'classnames';
import { HTMLAttributes, MouseEvent, ReactElement, ReactNode, useMemo, useRef } from 'react';

import theme from '../../theme';
import { FilterIcon, SearchIcon } from '../Icon';
import { Tooltip } from '../Tooltip/Tooltip';

import classes from './SearchInput.module.scss';

interface PendoAttributes extends HTMLAttributes<HTMLDivElement> {
  'data-pendo-label'?: string;
  'data-pendo-topic'?: string;
}

// TODO: this input should be rewritten to properly debounce
export interface SearchInputProps {
  /** Additional HTML classname to be added to the input group. */
  className?: string;
  /** Flag to control whether or not the control is interactive. If this value is `true` and "Clear" button is visible,
   * it will also be disabled. */
  disabled?: boolean;
  /** Flag to control whether or not the filter button is visible. */
  filterable?: boolean;
  /** When the filter popover is open, this text is used as its header. */
  filterHeading?: string;
  /** Render arbitrary elements between the "Clear" button (if visible) and the filter popover trigger. Intended to be
   * used for the list of active filters. */
  filterList?: ReactNode;
  /** If a filterIconTooltip is passed in, render a tooltip with filterIconTooltip as the label. */
  filterIconTooltip?: ReactNode;
  /** Render the body of the filter popover. Intende to be used for any filter controls, e.g. a checkbox group. */
  filterPopoverBody?: ReactNode;
  /** Props to be forwarded to the inner Chakra input control. See Chakra `InputProps` for more information. */
  inputProps?: InputProps & PendoAttributes;
  /** Handler function fired when the input text changes. */
  onChange: (searchText: string) => void;
  /** When the user clicks the "Clear" button, this handler is fired if provided. The handler is responsible for
   * clearing any filters. See `filterList`. */
  onClearFilters?: () => void;
  /** Placeholder text for the input. Visible before text is entered. */
  placeholder?: string;
  /** Flag to control visibility of the "Clear" button. By default, it will be visible if any text has been entered,
   * i.e. if `value` is truthy. */
  showClearButton?: boolean;
  /** This component is essentially just a controlled input. Parent components must keep the input text in state. */
  value: string;
  /** Visual appearance of the search input, primarily impacting background and border colors. Changes slightly on
   * hover. */
  variant?: 'white' | 'light' | 'medium';
}

const SearchInput = (props: SearchInputProps): ReactElement => {
  const {
    className,
    disabled,
    filterable,
    filterHeading,
    filterList,
    filterPopoverBody,
    filterIconTooltip,
    inputProps,
    onChange,
    onClearFilters,
    placeholder = 'Search',
    value,
    variant = 'white',
  } = props;

  const { isOpen: filterPopoverOpen, onToggle: onToggleFilterPopover, onClose: onCloseFilterPopover } = useDisclosure();

  const extrasRef = useRef<HTMLDivElement>(null);

  const inputPaddingRight = useMemo(() => extrasRef?.current?.offsetWidth, [extrasRef]);

  // By default, show the "Clear" button if a value is present. If the parent passes the prop, use it instead.
  const showClearButton = props.showClearButton ?? Boolean(value);

  const onClear = (event: MouseEvent) => {
    event.stopPropagation();
    onChange('');

    if (filterable) {
      onClearFilters?.();
    }
  };

  const FilterButtonWrapper = ({ children }: { children: ReactNode }) => {
    if (filterIconTooltip) {
      return (
        <Tooltip label={filterIconTooltip} isDisabled={filterPopoverOpen}>
          {children}
        </Tooltip>
      );
    }
    return <>{children}</>;
  };

  return (
    <InputGroup className={classNames(classes.inputSearch, className)}>
      <InputLeftElement
        aria-hidden="true"
        className={classNames(classes.leftControls, classes.iconContainer, {
          [classes.iconContainerDisabled]: disabled,
        })}
        pointerEvents="none"
      >
        <SearchIcon />
      </InputLeftElement>
      <Input
        aria-label={placeholder}
        className={classNames(classes.input, {
          [classes.variantWhite]: variant === 'white',
          [classes.variantLight]: variant === 'light',
          [classes.variantMedium]: variant === 'medium',
        })}
        isDisabled={disabled}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        placeholder={placeholder}
        style={{ paddingRight: inputPaddingRight }}
        type="text"
        value={value}
        {...inputProps}
      />
      <InputRightElement className={classes.rightControls} ref={extrasRef}>
        <>
          {showClearButton && (
            <Button
              color={theme.colors.brand.primary[600]}
              fontWeight="normal"
              height="auto"
              isDisabled={disabled}
              onClick={onClear}
              marginRight="0.5rem"
              padding="0.625rem 0.5rem"
              size="xs"
              textTransform="uppercase"
              variant="ghost"
            >
              Clear
            </Button>
          )}
          {filterList}
          {filterable && (
            <Popover
              isOpen={filterPopoverOpen}
              onClose={onCloseFilterPopover}
              placement="bottom-end"
              variant="searchInput"
            >
              <PopoverTrigger>
                {/* PopoverTrigger needs to attach to a DOM node that doesn't use a portal, so I'm using a div to wrap FilterButtonWrapper */}
                <div>
                  <FilterButtonWrapper>
                    <IconButton
                      aria-label={`${filterPopoverOpen ? 'Close' : 'Open'} filter list`}
                      color={theme.colors.brand.gray[800]}
                      icon={<Icon as={FilterIcon} height="1.5rem" width="1.5rem" />}
                      isDisabled={disabled}
                      marginRight="0.5rem"
                      onClick={onToggleFilterPopover}
                      size="sm"
                      variant="ghost"
                      _active={{
                        backgroundColor: theme.colors.brand.gray[100],
                        border: `1px solid ${theme.colors.brand.primary[400]}`,
                      }}
                    />
                  </FilterButtonWrapper>
                </div>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  {filterHeading && <PopoverHeader>{filterHeading}</PopoverHeader>}
                  <PopoverArrow />
                  <PopoverBody>{filterPopoverBody}</PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          )}
        </>
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchInput;
