import { Box, Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { ProjectHierarchyApi } from '../../api/v1/bespoke/ProjectHierarchyApi';
import ControlCenter from '../../components/ControlCenter/ControlCenter';
import { ErrorIcon, FloorsIcon, ShareIcon } from '../../components/Icon';
import { InternalLayout } from '../../components/Layout';
import Content from '../../components/Layout/Content';
import LoadingIndicator from '../../components/LoadingIndicator';
import MiniMap from '../../components/View360/MiniMap';
import { PendoTopic } from '../../constants/analytics';
import { ProjectQueryKeys, QueryTopics } from '../../constants/queries';
import LocationsDrawer from '../../dialogs/LocationsDrawer/LocationsDrawer';
import ShareProjectModalContainer from '../../dialogs/ShareProjectModal/ShareProjectModalContainer';
import Routes from '../../routes';
import theme from '../../theme';
import { generateProjectPageUrl } from '../../utils/navigationUtils';
import { denormalizeHierarchy } from '../../utils/treeUtils';

import commonClasses from '../../Common.module.scss';

const FloorplanContainer = () => {
  const history = useHistory();
  const urlParameters = useParams<{ projectId: string; floorplanId: string }>();
  const projectId = Number(urlParameters.projectId);
  const floorplanId = Number(urlParameters.floorplanId);

  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const { isOpen: isLocationsDrawerOpen, onOpen: openLocationsDrawer, onClose: closeLocationsDrawer } = useDisclosure();
  const {
    isOpen: isShareProjectModalOpen,
    onOpen: openShareProjectModal,
    onClose: closeShareProjectModal,
  } = useDisclosure();

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });
  const projectHierarchyQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_HIERARCHY, projectId],
    queryFn: async ({ signal }) => (await ProjectHierarchyApi.getHierarchyByProjectId(projectId, { signal })).data,
  });

  const projectHierarchy = useMemo(() => {
    if (!projectHierarchyQuery.data || !projectDetailsQuery.data) {
      return undefined;
    }

    // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
    return denormalizeHierarchy(projectHierarchyQuery.data, projectDetailsQuery.data.floorplans);
  }, [projectDetailsQuery, projectHierarchyQuery]);

  const project = projectDetailsQuery.data;
  const floorplan = projectDetailsQuery.data?.floorplans?.find(({ id }) => id === floorplanId);

  if (projectDetailsQuery.isFetching || projectHierarchyQuery.isFetching) {
    return (
      <InternalLayout>
        <Content>
          <LoadingIndicator fullPage />
        </Content>
      </InternalLayout>
    );
  }

  const errorQueries = [projectDetailsQuery, projectHierarchyQuery].filter((query) => query.isError);
  if (projectDetailsQuery.isError || projectHierarchyQuery.isError || !project || !floorplan || !projectHierarchy) {
    switch ((errorQueries[0]?.error as AxiosError | undefined)?.response?.status) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load data for this floor plan. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  const handleFloorplanSelect = (location: { projectId: number; floorplanId: number; walkthroughId?: number }) => {
    history.push(generateProjectPageUrl(location.projectId, location.floorplanId, location.walkthroughId));
  };

  return (
    <InternalLayout>
      <Content constrainToPageHeight>
        <ControlCenter
          cardProps={{
            margin: `var(--gutter-page-vertical) var(--gutter-page-horizontal)`,
            position: 'absolute',
            width: 'calc(100% - 2 * var(--gutter-page-horizontal))',
            zIndex: 1,
          }}
          fields={[
            <Button
              data-pendo-label="Change location"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              key="change-locations-drawer-toggle"
              leftIcon={<Icon as={FloorsIcon} height="1.25rem" width="1.25rem" />}
              onClick={openLocationsDrawer}
              variant="outline"
            >
              Locations
            </Button>,
            <Button
              data-pendo-label="Share project"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              key="share-project-button"
              leftIcon={<Icon as={ShareIcon} fontSize="1.125rem" />}
              onClick={openShareProjectModal}
              variant="primaryExternal"
            >
              Share
            </Button>,
          ]}
          // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
          floorplan={floorplan}
          project={project}
        />
        {!isImageLoaded && <LoadingIndicator />}
        <Box style={{ display: isImageLoaded ? 'block' : 'hidden' }}>
          <MiniMap
            annotations={[]}
            detectionList={[]}
            // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
            floorplan={floorplan}
            initiallyShowZoom
            mini={false}
            nodes={[]}
            onImageLoaded={() => setIsImageLoaded(true)}
          />
        </Box>
      </Content>
      <LocationsDrawer
        // @ts-expect-error Mismatch between V0 `Floorplan` and V0 `Project['floorplan']`.
        floorplan={floorplan}
        isOpen={isLocationsDrawerOpen}
        onClose={closeLocationsDrawer}
        onFloorplanSelect={handleFloorplanSelect}
        project={project}
        projectHierarchy={projectHierarchy}
      />
      <ShareProjectModalContainer isOpen={isShareProjectModalOpen} onClose={closeShareProjectModal} project={project} />
    </InternalLayout>
  );
};

export default FloorplanContainer;
