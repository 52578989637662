import { differenceInMilliseconds } from 'date-fns';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { MatchingFloorplan } from '../../@types/api/v0/rest/Node';
import { Project } from '../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../@types/api/v0/rest/Walkthrough';

/**
 * Retrieve a timestamp to show for a given floorplan on the "Locations" drawer. When the user scrolls through the list
 * of floorplans associated with a project (with or without hierarchy), each a date/time should be also shown on the
 * floorplan's button. The exact date/time shown depends on the current context.
 */
export const getFloorplanTimestamp = ({
  currentFloorplan,
  currentWalkthrough,
  floorplan,
  matches,
}: {
  currentFloorplan?: Floorplan;
  currentWalkthrough?: Walkthrough;
  floorplan: Floorplan;
  matches?: MatchingFloorplan[];
}): string | undefined => {
  // If we're not currently on a walkthrough and the floorplan has no walkthroughs, the destination floorplan has no
  // associated timestamp. If it has walkthroughs, the floorplan's timestamp should be that of its most recent
  // walkthrough.
  if (!currentFloorplan || !currentWalkthrough) {
    return floorplan.dated_walkthroughs.length === 0 ? undefined : maxBy(floorplan.dated_walkthroughs, 'when')?.when;
  }

  // If we're currently on a walkthrough and need to show the timestamp for its floorplan, show the walkthrough's
  // timestamp.
  if (currentFloorplan.id === floorplan.id) {
    return currentWalkthrough.when;
  }

  // If we're currently on a walkthrough and this floorplan has a node match close to the current node, show its
  // walkthrough timestmap.
  const bestNodeMatch = matches?.find((match) => match.floorplan === currentFloorplan.id);
  if (bestNodeMatch) {
    return bestNodeMatch.when;
  }

  // Otherwise, we need to compute the closest walkthrough.
  const diffs = floorplan.dated_walkthroughs.map(({ id, when }) => ({
    diff: Math.abs(differenceInMilliseconds(new Date(when), new Date(currentWalkthrough.when))),
    id,
    when,
  }));

  const bestMatch = minBy(diffs, 'diff');
  return bestMatch?.when;
};

export const getLocationParameters = (
  project: Project,
  currentWalkthroughDate: string | undefined,
  floorplan: Floorplan,
  match?: MatchingFloorplan
): { projectId: number; floorplanId: number; walkthroughId?: number; nodeId?: string; date?: string } => {
  // If a matching walkthrough node on the floorplan was provided, our work is already done.
  if (match) {
    return {
      projectId: project.id,
      floorplanId: floorplan.id,
      walkthroughId: match.walkthrough,
      nodeId: match.id,
      date: match.when,
    };
  }

  // If this floorplan doesn't have walkthroughs, send the user to the Floorplans page.
  if (floorplan.dated_walkthroughs.length === 0) {
    return {
      projectId: project.id,
      floorplanId: floorplan.id,
    };
  }

  // If we don't have a date for the current walkthrough (e.g. because we're on the Floorplan page), go to the latest
  // walkthrough.
  if (!currentWalkthroughDate) {
    const latestWalkthrough = maxBy(floorplan.dated_walkthroughs, 'when');
    return {
      projectId: project.id,
      floorplanId: floorplan.id,
      walkthroughId: latestWalkthrough?.id,
    };
  }

  // Get the absolute difference (in ms) between our current walkthrough and all other floorplan walkthroughs.
  const diffs = floorplan.dated_walkthroughs.map(({ id, when }) => ({
    diff: Math.abs(differenceInMilliseconds(new Date(when), new Date(currentWalkthroughDate))),
    id,
    when,
  }));

  const bestMatch = minBy(diffs, 'diff');
  return {
    projectId: project.id,
    floorplanId: floorplan.id,
    walkthroughId: bestMatch?.id,
    date: bestMatch?.when,
  };
};
