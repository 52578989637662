import { Box, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';

import { BrighteningIconFull, BrighteningIconPartial } from '../Icon';

import classes from './Brightening.module.scss';

export interface BrighteningSliderProps {
  /** Handler to call when the user changes the brightness level. */
  onChange: (brightness: 1 | 2 | 3 | 4) => void;
}

const BrighteningSlider = (props: BrighteningSliderProps) => {
  const { onChange } = props;

  return (
    <Box className={classes.sliderContainer}>
      <BrighteningIconFull aria-hidden />
      <Slider
        aria-label="Brightness"
        defaultValue={3}
        max={4}
        min={1}
        onChange={onChange}
        orientation="vertical"
        step={1}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <BrighteningIconPartial aria-hidden />
    </Box>
  );
};

export default BrighteningSlider;
