import { Box, Button, DrawerProps, Flex, FormControl, FormLabel, Heading, Stack, Switch, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { Account } from '../../../@types/OnSiteIQ';
import { DrawerType, PendoTopic } from '../../../constants/analytics';
import theme from '../../../theme';
import { maybePluralize } from '../../../utils/maybePluralize';
import { Drawer } from '../../Drawer/Drawer';
import { List, ListOption } from '../../List/List';
import InviteUserModalContainer from '../../UserManagement/InviteUserModal/InviteUserModalContainer';

export type ViewUsersDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  account?: Account;
  /** Whether or not the current user can invite users. */
  canInviteUsers?: boolean;
  /** Whether or not the accountUsersQuery is currently fetching data. */
  isFetching?: boolean;
  /** True if the project has no account, or there is an error returned by the accountUsersQuery, or there is an error returned by the meQuery. */
  isError?: boolean;
  /** Users to display in the List. */
  users: ListOption[];
  projectId?: number;
  projectName: string;
  projectAddress?: string;
  /** Whether or not the user can toggle the visibility of internal (i.e. OnsiteIQ) users in the users list. */
  canToggleInternalUsers?: boolean;
  /** Whether or not to show internal (i.e. OnsiteIQ) users in the users list. */
  shouldShowInternalUsers?: boolean;
  /** Function to call when the user toggles the visiblity of internal (i.e. OnsiteIQ) users. */
  onShouldShowInternalUsersChange?: (shouldShowInternalUsers: boolean) => void;
};

export const ViewUsersDrawer = ({
  account,
  canInviteUsers = false,
  isOpen,
  isFetching,
  isError,
  onClose,
  projectId,
  projectName,
  projectAddress,
  users = [],
  canToggleInternalUsers,
  shouldShowInternalUsers,
  onShouldShowInternalUsersChange,
}: ViewUsersDrawerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowInternalUsersChange = () => {
    onShouldShowInternalUsersChange?.(!shouldShowInternalUsers);
  };

  const AccountInfo = (
    <Stack marginTop="1.5rem" spacing={0}>
      <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
        Account
      </Text>
      <Text color={theme.colors.brand.gray[900]} fontWeight={400} fontSize="1rem">
        {account?.name}
      </Text>
    </Stack>
  );

  const errorContent = (
    <Text>
      Failed to load project users. Please contact our Customer Success team at{' '}
      <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
    </Text>
  );

  const bodyContent = (
    <Box>
      <Flex direction="row" alignItems="center" justifyContent="space-between" marginBottom="1rem">
        <Heading size="lg" as="h2">
          {maybePluralize('User', users.length, { includeCount: true })}
        </Heading>
        {canInviteUsers && (
          <Button
            data-pendo-label="Open invite modal from project users drawer"
            data-pendo-topic="user-management"
            onClick={() => setIsModalOpen(true)}
            padding="0.5rem 1.25rem"
            size="md"
            variant="highEmphasisV2"
          >
            Invite users
          </Button>
        )}
      </Flex>
      {canToggleInternalUsers && (
        <FormControl display="flex" alignItems="center" marginBottom="1rem">
          <FormLabel htmlFor="show-oiq-users" marginBottom={0}>
            Show OnsiteIQ users
          </FormLabel>
          <Switch
            data-pendo-topic="user-management"
            data-pendo-label="Toggle OnsiteIQ users from View Users Drawer"
            id="show-oiq-users"
            isChecked={shouldShowInternalUsers}
            onChange={handleShowInternalUsersChange}
          />
        </FormControl>
      )}
      <List
        options={users}
        onChange={(selectedValues) => console.debug(selectedValues)}
        isSearchable
        searchPlaceholder="Search people in this project"
        emptyPlaceholder="There are no users for this account."
        // Not an interactive list (user can't select options) so setting value to an empty list is fine here
        value={[]}
      />
    </Box>
  );

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        pendoTopic={PendoTopic.PORTFOLIO}
        drawerType={DrawerType.VIEW_USERS}
        primaryText={projectName}
        secondaryText={projectAddress}
        additionalHeaderContent={AccountInfo}
        isFetching={isFetching}
        isError={isError}
        errorContent={errorContent}
        bodyContent={bodyContent}
      />
      <InviteUserModalContainer
        currentAccountId={account?.id}
        initialProjectValue={projectId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
