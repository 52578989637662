export const SET_LOGIN_REQUIRED = 'auth/load';
export const LOGIN = 'auth/login';
export const SET_USER_PROFILE = 'auth/set_user_profile';
export const LOGOUT = 'auth/logout';
export const SET_PROCORE_TOKEN = 'auth/set_procore_token';
export const SET_PROCORE_ME = 'auth/set_procore_me';
export const CLEAR_PROCORE = 'auth/clear_procore';

export const SET_ACTIVE_DRAWER_ITEM = 'onsiteiq/drawer/setactiveitem';
export const SAVE_FROM_LOCATION = 'onsiteiq/fromlocation/save';
