import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
} from '@chakra-ui/react';
import {
  ChangeEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useState,
} from 'react';
import { NumberSchema, StringSchema, string } from 'yup';

import theme from '../../theme';

// 'MultipleInputField' name is tentative
interface MultipleInputFieldProps extends InputProps {
  /** Function called any time the value of the field changes */
  onPillsChange: (pills: string[]) => void;
  /** Yup schema to use for validating the pill values. Defaults to string().email() */
  pillValidationSchema?: StringSchema | NumberSchema;
  /** Value of the field (displayed as pills) */
  value: string[];
}

export const MultipleInputField = ({
  onPillsChange,
  pillValidationSchema = string().email(),
  placeholder,
  value: pills = [],
}: MultipleInputFieldProps) => {
  const [inputText, setInputText] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputText !== '') {
        const parsedText = inputText.split(/[,\s(\r\n|\n)]+/).filter((value) => value.length > 0);

        const updatedPills = Array.from(new Set([...pills, ...parsedText]));

        onPillsChange(updatedPills);

        setInputText('');
      }
    }
  };

  const handlePaste: ClipboardEventHandler = (event) => {
    event.preventDefault();
    const clipboardText = event.clipboardData.getData('text');

    if (clipboardText.length > 0) {
      // This regular expression matches commas, spaces, and both \r\n and \n as separators

      const parsedText = clipboardText.split(/[,\s(\r\n|\n)]+/).filter((value) => value.length > 0);

      const updatedPills = Array.from(new Set([...pills, ...parsedText]));

      onPillsChange(updatedPills);
    }
  };

  const handleBlur: FocusEventHandler = () => {
    let updatedPills = [...pills];
    if (inputText !== '' && !pills.includes(inputText)) {
      updatedPills = [...pills, inputText];
    }
    onPillsChange(updatedPills);
    setInputText('');
    setIsFocused(false);
  };

  const handleFocus: FocusEventHandler = () => {
    setIsFocused(true);
  };

  const handleRemovePill = (index: number) => {
    const updatedPills = [...pills];
    updatedPills.splice(index, 1);
    onPillsChange(updatedPills);
  };

  const handleClear: MouseEventHandler = () => {
    onPillsChange([]);
  };

  return (
    <>
      <Wrap direction="row" marginBottom="0.25rem">
        {pills.map((pill, index) => {
          const isValid = pillValidationSchema.isValidSync(pill);
          return (
            <Tag key={`pill-${index}`} data-testid="pill-tag" variant={isValid ? 'valid' : 'invalid'}>
              <TagLabel>{pill}</TagLabel>
              <TagCloseButton onClick={() => handleRemovePill(index)} />
            </Tag>
          );
        })}
      </Wrap>
      <InputGroup>
        <Input
          autoComplete="off"
          data-testid="multiple-input-field"
          onChange={handleInputChange}
          onPaste={handlePaste}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          value={inputText}
          onKeyDown={handleKeyDown}
        />
        <InputRightElement top="0.1875rem" marginRight="0.5rem" width="auto">
          <Button
            color={theme.colors.brand.primary[600]}
            fontWeight="normal"
            height="auto"
            onClick={handleClear}
            padding="0.625rem 0.5rem"
            size="xs"
            textTransform="uppercase"
            variant="ghost"
          >
            Clear
          </Button>
        </InputRightElement>
      </InputGroup>
      {isFocused && inputText.length > 0 && (
        <List
          borderColor={theme.colors.brand.gray[200]}
          borderWidth="1px"
          marginTop="0.5rem"
          position="absolute"
          backgroundColor={theme.colors.white}
          borderRadius="0.375rem"
          zIndex={1}
          width="100%"
        >
          <ListItem
            padding="0.25rem 1rem"
            cursor="pointer"
            _hover={{ backgroundColor: theme.colors.brand.primary[100] }}
          >
            {`Add ${inputText}`}
          </ListItem>
        </List>
      )}
    </>
  );
};
