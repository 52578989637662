import { statAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(statAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    color: 'var(--gray-600)',
  },
  helpText: {},
  icon: {},
  number: {
    fontWeight: 400,
  },
});

export const statTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    sm: {
      number: { fontSize: '1.125rem', lineHeight: 1.445 },
    },
    md: {
      number: { fontSize: '1.25rem', lineHeight: 1.6 },
    },
  },
});
