import { Icon, IconButton } from '@chakra-ui/react';
import classNames from 'classnames';
import { useEffect } from 'react';

import theme from '../../theme';
import { CheckIcon, CloseIcon, ErrorIcon } from '../Icon';

import classes from './Toast.module.scss';

export interface ToastProps {
  description?: string;
  onClose: () => void;
  onCloseComplete?: () => void;
  status: 'success' | 'warning' | 'error';
  title: string;
}

const Toast = (props: ToastProps) => {
  const { description, onClose, onCloseComplete, status, title } = props;

  useEffect(() => {
    // When the component will unmount, call onCloseComplete if provided.
    return () => onCloseComplete?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span
          className={classNames(classes.title, {
            [classes.titleSuccess]: status === 'success',
            [classes.titleWarning]: status === 'warning',
            [classes.titleError]: status === 'error',
          })}
        >
          {status === 'success' && <CheckIcon />}
          {status === 'warning' && <ErrorIcon />}
          {status === 'error' && <ErrorIcon />}
          {title}
        </span>
        <IconButton
          aria-label="Close"
          color={theme.colors.brand.gray[800]}
          icon={<Icon as={CloseIcon} height="1.625rem" width="1.625rem" />}
          onClick={onClose}
          size="sm"
          variant="ghost"
          _active={{
            backgroundColor: theme.colors.brand.gray[100],
            border: `1px solid ${theme.colors.brand.primary[400]}`,
          }}
        />
      </div>
      <p className={classes.body}>{description}</p>
    </div>
  );
};

export default Toast;
