import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import { useRef } from 'react';

import { InviteMembersRequest } from '../../@types/UserManagement';
import { Project } from '../../@types/api/v0/rest/Project';
import { UserManagementApi } from '../../api/next';
import { CheckIcon, LinkIcon, WarningIcon } from '../../components/Icon';
import Toast from '../../components/Toast';
import { PendoTopic } from '../../constants/analytics';
import { QueryTopics, UserManagementQueryKeys } from '../../constants/queries';
import theme from '../../theme';
import ShareProjectForm, { ShareProjectFormValues } from './ShareProjectForm';

export interface ShareProjectModalContainerProps {
  /** Whether or not the modal is visible. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the modal. */
  onClose: () => void;
  /** The project being shared. */
  project?: Project;
}

const ShareProjectModalContainer = (props: ShareProjectModalContainerProps) => {
  const { isOpen, onClose, project } = props;

  const shareProjectFormRef = useRef<FormikProps<ShareProjectFormValues>>(null);

  const { hasCopied, onCopy: onCopyUrl } = useClipboard(window.location.toString());
  const toast = useToast();

  const queryClient = useQueryClient();

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const shareProjectMutation = useMutation({
    mutationFn: (values: { users: string[] }) => {
      const payload: InviteMembersRequest = {
        grant_access_account_projects: false,
        project_subset: [project!.id],
        users: values.users.map((email: string) => ({
          email,
          grant_invite_to_account_projects: true,
        })),
      };
      return UserManagementApi.inviteMembers(project!.account.id, payload);
    },
    onError: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          return (
            <Toast {...props} title="Error" description="An error occurred. Please try again later." status="error" />
          );
        },
      });
    },
    onSuccess: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description="Project shared successfully." status="success" />
        ),
      });

      queryClient.invalidateQueries({
        queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_MEMBERS, project!.account.id],
      });
      onClose();
    },
  });

  const accountsWithInvitePermission = meQuery.data?.invite_to_account_projects ?? [];
  const hasInvitePermissions = accountsWithInvitePermission.some((account) => account.id === project?.account?.id);

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={Boolean(isOpen)} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Share ${project?.name ?? 'project'}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {hasInvitePermissions ? (
            <ShareProjectForm onSubmit={(values) => shareProjectMutation.mutate(values)} ref={shareProjectFormRef} />
          ) : (
            <Text textStyle="bodySmall">
              You do not have permissions to invite users within this account. Contact the account owner or{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> to request access.
            </Text>
          )}
          <Flex alignItems="center" marginBlock="1rem 0.5rem">
            <Icon
              aria-hidden
              as={WarningIcon}
              color={theme.colors.brand.secondary[500]}
              marginInlineEnd="0.5rem"
              height="1rem"
              width="1rem"
            />
            <Text color={theme.colors.brand.gray[600]} textStyle="bodySmall">
              If you share the project via link, the recipient(s) must have an OnsiteIQ account and have access to this
              project.
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            data-pendo-label="Copy currently URL to clipboard"
            data-pendo-topic={PendoTopic.SHARE}
            isDisabled={hasCopied}
            leftIcon={<Icon as={hasCopied ? CheckIcon : LinkIcon} fontSize="1.125rem" />}
            onClick={onCopyUrl}
            size="md"
            variant="outline"
          >
            {hasCopied ? 'Link copied' : 'Copy link'}
          </Button>
          {hasInvitePermissions && (
            <Button
              data-pendo-label="Submit and close share project modal"
              data-pendo-topic={PendoTopic.SHARE}
              isLoading={shareProjectMutation.isLoading}
              onClick={() => shareProjectFormRef.current?.submitForm()}
              size="md"
              variant="primaryExternal"
            >
              Send
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareProjectModalContainer;
