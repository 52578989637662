import {
  Box,
  Button,
  ButtonProps,
  Icon,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { HTMLAttributes, ReactNode } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

import { CalendarIcon, TimeIcon } from '../Icon';
import { Tooltip } from '../Tooltip/Tooltip';
import DatePickerInline from './DatePickerInline';

interface PendoAttributes extends HTMLAttributes<HTMLDivElement> {
  'data-pendo-label'?: string;
  'data-pendo-topic'?: string;
}

interface TooltipProps {
  label: ReactNode;
  placement?: PlacementWithLogical;
}

export interface DatePickerProps extends ReactDatePickerProps {
  buttonProps?: ButtonProps & PendoAttributes;
  popoverPlacement?: PlacementWithLogical;
  popoverVariant?: string;
  /** If provided, a tooltip will be rendered around the date picker's popover trigger with these props. */
  tooltipProps?: TooltipProps;
  /** Which icon, if any, to show in the button. */
  icon?: 'TimeIcon' | 'CalendarIcon';
}

/**
 * Render either a tooltip wrapping some `children`, or just the provided `children` depending on the presence of a
 * `tooltipProps` prop.
 */
const TooltipWrapper = ({
  children,
  isDatePickerOpen,
  tooltipProps,
}: {
  children: ReactNode;
  isDatePickerOpen: boolean;
  tooltipProps?: TooltipProps;
}) =>
  tooltipProps ? (
    <Tooltip isDisabled={isDatePickerOpen} label={tooltipProps.label} placement={tooltipProps.placement || 'auto'}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );

/**
 * General-use date picker. Note that the inner `Box` component is required due to a known limitation of Popper.
 * @see https://github.com/chakra-ui/chakra-ui/issues/2843
 */
const DatePicker = (props: DatePickerProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  let buttonIcon;
  if (props.icon === 'TimeIcon') {
    buttonIcon = <Icon aria-hidden as={TimeIcon} height="1.125rem" width="1.125rem" />;
  } else if (props.icon === 'CalendarIcon') {
    buttonIcon = <Icon aria-hidden as={CalendarIcon} height="1.125rem" width="1.125rem" />;
  }
  return (
    <Popover
      closeOnBlur
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement={props.popoverPlacement}
      variant={props.popoverVariant ?? ''}
    >
      <PopoverTrigger>
        <Box>
          <TooltipWrapper isDatePickerOpen={isOpen} tooltipProps={props.tooltipProps}>
            <Button {...props.buttonProps} leftIcon={buttonIcon} type="button" variant="outline">
              {props.value}
            </Button>
          </TooltipWrapper>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <DatePickerInline {...props} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default DatePicker;
